import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'; 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; 
import ErrorIcon from '@mui/icons-material/Error';
dayjs.extend(utc); 

interface MyDatePickerProps {
  onChange: any;
  disableFutureDates?: boolean;
  disablePastDates?: boolean;
  required?: boolean;
  defaultValue?: Date | null;
  className?: string;
  toShowDatePickerErrorSpan?: boolean;
}

const MyDatePicker: React.FC<MyDatePickerProps> = ({
  onChange,
  disableFutureDates = false,
  disablePastDates = false,
  required = false,
  defaultValue = null,
  className = "",
  toShowDatePickerErrorSpan = false
}) => {
    
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const currentDate = dayjs(new Date());
    const maxDate = disableFutureDates ? currentDate : dayjs(new Date(2099, 11, 31));
    const minDate = disablePastDates ? currentDate : dayjs(new Date(1900, 0, 1));

  useEffect(() => {
    console.log('Selected Date:', selectedDate);
  }, [selectedDate]);
  
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
   
  
    if (defaultValue !== null) {
      const initialDate = dayjs(defaultValue).utc().toDate();
      setSelectedDate(initialDate);
    } else {
      // Handle the case when defaultValue is null
      setSelectedDate(null);
    }
  }, [defaultValue,showError]);

  const handleDateChange = (date: any) => {
    setShowError(false);

    if (date) {
      if (isNaN(date)) {
        setShowError(true);
        return;
      }

      const dateInUtc = date;

      setSelectedDate(dateInUtc);

      if (
      minDate &&
      dateInUtc.isBefore(minDate.startOf('day')) // Compare only the date part, ignoring time
    ) {
      setShowError(true);
    } else if (
      maxDate &&
      dateInUtc.isAfter(maxDate.endOf('day')) // Compare only the date part, ignoring time
    ) {
      setShowError(true);
    }
      
      if (onChange) {

        const currentDate = dayjs(new Date());
        const selectedDate = dayjs(date);
    
        // Check if the selected date is the same as the current date
        if (currentDate.format('YYYY-MM-DD') === selectedDate.format('YYYY-MM-DD')) {
          // If it's the same date, set the current date and time
          date = currentDate;
        }

        onChange(date);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
         label=""                                    
         onChange={(value, context) => handleDateChange(value)} 
         format="MM/DD/YYYY"
         minDate={minDate}
         maxDate={maxDate}
         className={`${className} date-control ${showError ? 'date-border' : 'hide-date-border'}`}                        
         defaultValue={dayjs(defaultValue).utc()}
         value={dayjs(selectedDate).utc()} 
         slotProps={{ field: { shouldRespectLeadingZeros: true } }}
      />
       {showError && toShowDatePickerErrorSpan && <div className='error-message-cme-hours'>
                                <span className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required and past dates not allowed'}
                                </span>
                            </div> 
}
    </LocalizationProvider>
  );
};

export default MyDatePicker;
