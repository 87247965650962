import React from 'react';
import ReactDOM from 'react-dom';
import { Form } from '../CMEProfileManagement/ModalForm';
import FocusTrap from 'focus-trap-react';
import '../../styles/dashboard.css';

export const Modal = ({
  onClickOutside,
  onKeyDown,
  modalRef,
  buttonRef,
  closeModal,
  onSubmit,
  onProfileCategoryChanges,
  onRequirerBoardChanges,
  stateOrSpecialtyBoard,
  profileCategory,
  filterProps,
  modalHeaderText,
  modalGeneralRequirementText,
  modalTopicRequirementText,
  modalFor,
  onKeyPress,
  handleChangeLicRenDate,
  inputProperties,
  topicList,
  topicLoaded,
  statuteLoaded,
  disableRequirement,
  closeModalCustomClick,
  cancelBtnRef,
  onChangeDate
}) => {
  return ReactDOM.createPortal(
     
      <aside
        tag="aside"
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        className="modal-cover"
        onClick={onClickOutside}
        onKeyDown={onKeyDown}
      >
        <div className={modalFor === "Requirements" ? 'modal-area-profile modal-area' : modalFor === 'MobileUser' ? 'modal-area-mobile' : "modal-area"} ref={modalRef}>
          
        <div className={modalFor === "Requirements" ? "modal-header" : "" }>
          <div
            ref={buttonRef}
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className="_modal-close"
            onClick={closeModal}
          >
            <span id="close-modal" className="_hide-visual">
              Close
            </span>
            <svg className="_modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </div>
           
          <div className={modalFor === 'MobileUser' ? 'model-header-text-mobile' : 'model-header-text'}  >
              {modalHeaderText}        
            </div>
       </div>
          <div className='model-header-requiement-text'>
          {modalGeneralRequirementText}
        </div>

          <div className= {modalFor === "Requirements" && modalGeneralRequirementText === '' ? '' : modalFor === "Requirements" && modalGeneralRequirementText !== '' ?  "modal-requirement-body-stick-header" : "modal-body" }>           
            <Form modalFor={modalFor} 
                  closeModal={closeModal} 
                  onSubmit={onSubmit} 
                  profileCategory={profileCategory} 
                  stateOrSpecialtyBoard = {stateOrSpecialtyBoard} 
                  onProfileCategoryChanges = {onProfileCategoryChanges} 
                  onRequirerBoardChanges={onRequirerBoardChanges}
                  filterProps={filterProps}  
                  onKeyPress = {onKeyPress} 
                  handleChangeLicRenDate ={handleChangeLicRenDate } 
                  inputProperties={inputProperties}
                  closeModalCustomClick = {closeModalCustomClick}
                  cancelBtnRef = {cancelBtnRef}
                  onChangeDate={onChangeDate}
            />         
          </div>
          
          
          {topicList && topicList?.modalData && topicList?.modalData.map((item, index) => (

          modalFor === "Requirements" ? 
  
          <>
           <div className={modalGeneralRequirementText === ''  ? 'border-bottom-no-requirement' :  topicList && topicList?.modalData.length !==  index+1 ?  'border-bottom-requirement' : topicList && topicList?.modalData.length === 1 ? 'border-bottom-requirement' : null}>
          </div> 
            <div className='model-header-topic-text'>
                {item.description}
              </div>

              <div className="modal-topic-body">
              <Form modalFor={modalFor}
                closeModal={closeModal}
                onSubmit={onSubmit}
                profileCategory={profileCategory}
                stateOrSpecialtyBoard={stateOrSpecialtyBoard}
                onProfileCategoryChanges={onProfileCategoryChanges}
                onRequirerBoardChanges={onRequirerBoardChanges}
                filterProps={filterProps}
                onKeyPress={onKeyPress}
                handleChangeLicRenDate={handleChangeLicRenDate}
                inputProperties={ statuteLoaded ?  item.statute : item.title}
                topicLoaded ={ topicLoaded} />
            </div> 

            <div className={topicList && topicList?.modalData.length ===  index+1 ? 'border-bottom-no-requirement'  : 'border-bottom-requirement' }>
          </div> 
              </>
              : null

          ))}

        </div>
      </aside>
     ,
    document.body
  );
};

export default Modal;
