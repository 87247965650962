import React, { createContext, useContext, useEffect } from "react";
import API_URL from '../config/config';
import UserContextProvider, { userContext } from './userContext';
import {validateToken} from '../api-provider';
import PromiseContextProvider from "./promiseContext";
import { useHistory, useLocation } from "react-router-dom";
import apiProfiles from "../api-operation";
import { v4 as uuid } from 'uuid';
var axios = require('axios');

//'Context/ContactContextProvider' is used to get the authenticated users contact details and segregate that application wide or across components
export const contactContext = createContext<IContextType>({
  contactObject: { Contact: { contactid: '', firstname: '', middlename: '', lastname: '', jobtitle: '', professionaldesignation: '' ,acepid:''} },
  updateContactInfo: () => {},

});
export default function ContactContextProvider(props: any) {
    
    let userObj = useContext(userContext);
    let userData = userObj.User;
    //check user is admin or not
    let isAdmin:boolean = userData.acepMemberStatus === 2 ? true : false;

    const location = useLocation();
    const history = useHistory();

    const queryParams = new URLSearchParams(location.search);

    // Get a specific parameter value by its name
    const queryAcepId = queryParams.get('acepid');
   
    const [loadPage, setLoadPage] = React.useState(false)
    const [contactObject, setContactObject]  = React.useState({Contact: {contactid:'', firstname :'', middlename:'', lastname:'',jobtitle:'',professionaldesignation:'',acepid:''}});

    // Function to update the contact information when a user is clicked
    const updateContactInfo = (contactData: { contactid: any; firstname: any; middlename: any; lastname: any; jobtitle: any; professionaldesignation: any;acepid:any }) => {
      setContactObject({
          Contact: {
              contactid: String(contactData.contactid),
              firstname: contactData.firstname,
              middlename: contactData.middlename,
              lastname: contactData.lastname,
              jobtitle: contactData.jobtitle,
              professionaldesignation: contactData.professionaldesignation,
              acepid:contactData.acepid
          }
      });
      return contactData;
  };
  
useEffect(() => {

  // console.log( "Query acep id " + queryAcepId)
  let acepid = queryAcepId !== null && queryAcepId !== '' && isAdmin ? queryAcepId : userData?.acepid;

  if(isAdmin && queryAcepId === null){
    history.push("/")
  }
  validateToken();  
  if(userData?.acepid && userData?.acepid !=='' && acepid && acepid !== null  ){ 
          axios.get(API_URL+ '/cmecontacts/acepid/' + acepid)
          .then(function (response:any) 
          {
            let res:any = response.data.cmecontact
            if(res){
            let contactInfo = {
                Contact:{
                contactid: String(res.Id),      
                firstname: res.FirstName,
              middlename:res.MiddleName,
              lastname:res.LastName,
              jobtitle:res.Job_Title,
              professionaldesignation:res.Professional_Designation,
              acepid:acepid
              }          
            };
  

        setContactObject(contactInfo);
        setLoadPage(true)
          }
          else{
            history.push("/");
          }
        })

        .catch(function (error:any) {
          console.log(error);
          if(error.response.status === 404){
            console.log("Contact not found, creating new contact")
            const requestOptions = {

              Id:userData?.id,
              // Prefix: userData?.Prefix,
              FirstName: userData?.firstName,
              // MiddleName: userData?.middleName,
              LastName: userData?.lastName,
              FullName: userData?.formalName,
              // Birthdate: req.body.Birthdate,
              Email: userData?.email2,
              // Job_Title: req.body.Job_Title,
              // Professional_Designation:req.body. Professional_Designation,
              Phone_Number: userData?.phone,
              Acep_ContactNumber: userData?.acepid,
              IsActive: 1

            }; 
  
            apiProfiles.url = "/cmecontacts";
            apiProfiles.post(requestOptions)                   
            .then((data:any) => {
                
                var rows=data?.cmecontact || [];
              if(rows){
                
                let contactInfo = {
                  Contact:{
                  contactid: rows.Id,      
                  firstname: rows.FirstName,
                middlename:rows.MiddleName,
                lastname:rows.LastName,
                jobtitle:rows.Job_Title,
                professionaldesignation:rows.Professional_Designation,
                acepid:rows.Acep_ContactNumber
                }          
              };
    
  
          setContactObject(contactInfo);
          setLoadPage(true)

              }
              else{
                history.push("/");
              }
                
            })
            .catch(function (error:any) {
              history.push("/");
            })
          }
          
        });
      }else{
        setLoadPage(false)
      }
  
  }, [userData?.acepid, queryAcepId]);
  return (

    <div>
     
      {loadPage &&  

      <>
      <contactContext.Provider value={{contactObject,updateContactInfo}}>{props.children}
        <PromiseContextProvider/>
        {/* <App /> */}
        
      </contactContext.Provider>
      
      
      
      </>    }
    </div>

   
  );
}
