import ReactDOM from 'react-dom';
import './index.scss';
import SSOACEP from './components/common/SSO';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();



ReactDOM.render( 
<QueryClientProvider client={queryClient} >  
<BrowserRouter>
<SSOACEP />  
</BrowserRouter>
<ReactQueryDevtools initialIsOpen={false} /> 
</QueryClientProvider>  
,document.getElementById('root')
);

 
