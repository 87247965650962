import React, { useContext, useEffect, useLayoutEffect, useRef, useState }  from 'react'
import {FileUploadOutlined} from '@mui/icons-material'; 
import {Button, TextField} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; 
import apiProfiles from "../api-operation";
import ErrorIcon from '@mui/icons-material/Error';
import { v4 as uuid } from 'uuid';
import DeleteTopic from './DeleteTopic';
import API_URL from '../config/config'
import { contactContext } from "../context/contactContext";
import { userContext } from '../context/userContext';
import shortid from 'shortid';
import axios from 'axios';
import { GetRoundUpValue } from '../utils/utils';
import LeftNavMenu from "../components/common/Navbar";
import { GetTopicData, GetActivityTypeData} from '../utils/cache';
import SelfReportedSubmit from './SelfReportedSubmit';
import { useQueryClient } from '@tanstack/react-query';
import { ContactInfo } from './ContactInfo';
import MyDatePicker from './common/MyDatePicker';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc"; 
dayjs.extend(utc); 
export default function SelfReportActivity(props:any){
   const [cmeHours, setCMEHours] = useState(0)
    const [topicInfo, setTopicInfo] = React.useState<iTopicInfo[]>([]);
   // const [uploadedFileName, setUploadedFileName] = React.useState('')
    const [selectedfile, setSelectedFile] = React.useState('')
    const[disableAddTranscriptButton, setdisableAddTranscriptButton] = useState(false)
    const[disableDateError, setDisableSetError] = useState(false)
    const[inputModified,setInputModified] = useState('')
    const[invalidFileMessage,setInvalidFileMessage] = useState(false);
    const [showSubmit,setshowSubmit] = useState(false);
    const [updateContact, setupdateContact] = useState(false);
    const[activityDetails,setActivityDetails] = React.useState<tActivityDetails>({
        Id:uuid(),
        Name: "",
        Description:"",
        CompletionDate: "",        
        MaxHours: "0",
        ClaimedHours: "0",
        CertificateUrl: "", 
        CME_Activity_Id: null,
        CME_ActivityType_Id: "",
        CME_Contact_Id: "-1",
        CME_Tracking_Status_Id: 5
    })
    const [cmeTopicList, setCmeTopicList] = React.useState<any[]|[]> ([]);
    const [cmeActivityTypeList, setcmeActivityTypeList] = React.useState<any[]|[]> ([{Id:-1,Name:''}]);
    const [claimeStyles, setclaimeStyles] = useState({opacity:1, pointer:'auto'});
    const [spinner, setSpinner] = useState(false);
    const [UploadedCertificateUrl,setUploadedCertificateUrl] = React.useState('')
    const [shouldFocus, setShouldFocus] = useState(false);
    
    //For Left Nav Height
    const ref = useRef<HTMLInputElement>(null);
    const [height, setHeight] = useState(0);
    const [loadNavHeight, setLoadNavHeight] = useState(false);

    const {data : topicAPIData,isLoading :topicAPICalled } = GetTopicData();
    const {data : activityTypeAPIData,isLoading :activityTypeAPICalled} = GetActivityTypeData() ;

    let contactObject  = useContext(contactContext);   
    let contactData = contactObject.contactObject.Contact;
    let userObject = useContext(userContext);
    let userData = userObject.User;
    //check user is admin or not
    let isAdmin:boolean = userData.acepMemberStatus === 2 ? true : false;

    if(userData.acepMemberStatus === 2){
        contactData =contactObject.contactObject.Contact;
        if(!updateContact)
        setupdateContact(true);
      }
      else{
            contactData =contactObject.contactObject.Contact;
      }

    // Get QueryClient from the context
    const queryClient = useQueryClient();
   
    useLayoutEffect(()=>{            
        
            var HomePagebackgroundHeight = document.body.getElementsByClassName('HomePagebackground')[0]; // Assuming there's only one element with class 'HomePagebackground'
            var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
            var newHeight = currentHeight + 120; 
            setHeight(newHeight);
            setLoadNavHeight(false)
          
},[loadNavHeight])

    useEffect(() => { 
        getCMETopicList();
        getActivityTypeList();
        setActivityDetails(data => {
            return{ ...data,
             CME_Contact_Id : contactData?.contactid||""}
         })
        
         if(props.cmeActivityTypeList){
            setcmeActivityTypeList(props.cmeActivityTypeList)
         }
         if(props.cmeTopicList){
            setCmeTopicList(props.cmeTopicList)
         }
            

        // props.onLoadPage(true);
    },[activityTypeAPICalled,topicAPICalled])    

      const selectedTopicIdExists = (Id:number,Index:number):boolean => {
        return topicInfo.slice(0,Index).some(function (el, iIndex) {
            return (el.TopicId === Id);
       }); 
      }

    //This function will call when Added Topic Details gets changed by User. It could be Hours or Topic Name
    const onChangeAddedTopicDetails = (event:any,iIndex:number,key:"TopicId"|"TopicHours") => {         
        let selectedVal = event.target.value.trim();
       
        const topicByIndex = topicInfo[iIndex] 
        event.preventDefault();       

        //If Topic Hours are not a number then ignore the further process
        if((key === 'TopicHours' && !isNaN(selectedVal)) || (key === 'TopicId')){
            validateTopicInfoDetails(selectedVal,iIndex, topicByIndex,key,true)
        }
   }

   const validateTopicInfoDetails = (selectedVal:string,iIndex:number, topicByIndex:iTopicInfo,key:"TopicId"|"TopicHours",IsUpdated?:false|true)=>{
    if(key === 'TopicHours'){
            
       // event.target.focus();
       const iAddTopicHours:Number = Number(selectedVal) || 0;
       //setclaimAddTopicHours(iAddTopicHours)
       if(iAddTopicHours > cmeHours || iAddTopicHours == 0){               
           topicByIndex["IsValidTopicHours"] = false; 
          // setdisableAddTranscriptButton(true)             
       }                
       else{                 
           topicByIndex["IsValidTopicHours"] = true;         
           //isValid();       
       }     

       topicByIndex[key] = selectedVal;
    }

    if(key === 'TopicId'){
       if(selectedTopicIdExists(Number(selectedVal),iIndex) || selectedVal == "-1")
       {                 
           topicByIndex["IsValidTopicName"] = false;
          // selectedVal = '-1' 
          // setdisableAddTranscriptButton(true)              
       }
       else{                 
           topicByIndex["IsValidTopicName"] = true;    
           //isValid();           
       }   
       
       topicByIndex[key] = Number(selectedVal);
    }

    //This will update the changed value in the topicinfo array
       
       topicByIndex["IsUpdated"] = true
       setTopicInfo([
           ...topicInfo.slice(0, iIndex), 
           topicByIndex,
           ...topicInfo.slice(iIndex + 1, topicInfo.length)
        ])
   }

   const onAddTopicInfo = (event:any) => {   
    event.preventDefault();     
   
    setTopicInfo(prevTopic => [
        ...prevTopic,
        {NewUID:uuid(),
            TopicId: -1, 
            TopicHours: '0',
            IsValidTopicName:true,
            IsValidTopicHours:true,
            RecordID:'-1',
            IsUpdated:false
        },
      ])
   //  setdisableAddTranscriptButton(true)
   //  props.onLoadPage(true);
   setLoadNavHeight(true)
    
   
}

const onSubmit = (NewUID:string,iIndex:Number) => (event:any) => {  
     event.preventDefault(); 
     setInputModified('')
     event.target[0].click();
     setTopicInfo(prevAddedTopicInfo => {
        return prevAddedTopicInfo.filter((value, i) => value.NewUID !== NewUID)
      })
       
}

const getCMETopicList =() => {
    if(topicAPIData && !topicAPICalled){         
            let listcmetopic : any[] = topicAPIData; 
            if (!listcmetopic.some((item) => item.Id === -1)) {
                listcmetopic.unshift({Id:-1,Name:''})
            }
            setCmeTopicList(listcmetopic)          
    }
  }

  const getActivityTypeList =() => {   
    if(activityTypeAPIData && !activityTypeAPICalled){    
        let listcmeactivitytypes: any[] = activityTypeAPIData.filter(item => item !== '');

     
    // Check if the empty string already exists in the array
    const emptyStringExists = listcmeactivitytypes.includes("");

    // If the empty string doesn't exist, add it at the beginning of the array
    if (!emptyStringExists) {
        listcmeactivitytypes.unshift("");
    }

     
    setcmeActivityTypeList(listcmeactivitytypes)       
   }
   
  }

  function convertToMaterialUIDateFormat(inputDate:any) {
    const parts = inputDate.split('/');
    if (parts.length === 3) {
      return `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
    }
    return '';
  }

  function removeQueries() {
   // queryClient.removeQueries({ queryKey: ['unclaimedCMEFilterData'] });
    queryClient.removeQueries({ queryKey: ['claimedCMEFilterData'] });
    queryClient.removeQueries({ queryKey: ['claimedCMEData'] });
   // queryClient.removeQueries({ queryKey: ['unclaimedCMEData'] });
}

const onAddSelfReportedActivity= (event:any) => { 
    //This will check user session is active or not
   //localStorage.setItem("loadSSOData","1");   
   event.preventDefault(event);
   setInputModified('')
   
   if(isValid() && API_URL){

    setSpinner(true);
      const enableObject={
        opacity:0.5, 
        pointer:'none'
      }
    setclaimeStyles(enableObject);
   //create/store file to azure 
   if(activityDetails.CertificateUrl.length > 0)
        postFile();   

    var curDate = (convertToMaterialUIDateFormat(activityDetails.CompletionDate));
   setActivityDetails(data => {
    return{ ...data,
     CME_Contact_Id : contactData?.contactid||"",
    CompletionDate:curDate
}
    }) 

  let requestOptions = {
        
    }; 
    
            apiProfiles.url = "/cmetracking";            
            apiProfiles.post(activityDetails)
            .then((data: any) => {
                var cmeTrackingRecordId = data.cmetraking.Id;

                requestOptions = {

                                CME_Tracking_Id: cmeTrackingRecordId,
                                CME_topic_info:topicInfo 
 
                    }; 
                    
                    apiProfiles.url = "/cmetrackingdetail";
                    apiProfiles.post(requestOptions)                   
                    .then((data:any) => {
                        
                        var rows=data?.cmetrackingdetails || [];
                       if(rows){
                        setSpinner(false);
                        const enableObject={
                            opacity:1, 
                            pointer:'auto'
                        }
                        setclaimeStyles(enableObject);
                        setshowSubmit(true)
                       }
                        
                    })
                    removeQueries();
           })
    }else{
        isValid();
    if(topicInfo.length <= 0)    {
        setTopicInfo([       
            {NewUID:uuid(),
                TopicId: -1, 
                TopicHours: '0',
                IsValidTopicName:false,
                IsValidTopicHours:false,
                RecordID:'-1',
                IsUpdated:false
            }
          ])
         setdisableAddTranscriptButton(true)
    }else{
        topicInfo.map((element,index) => {
                
            validateTopicInfoDetails(element.TopicId.toString(),index,topicInfo[index],'TopicId',false)
            validateTopicInfoDetails(element.TopicHours.toString(),index,topicInfo[index],'TopicHours',false)
         })
    }
    
    }
};

//On File Selectes
const fileBrowseHandler = (event:any) => {
    setInputModified('')

   const acepid = userData.acepid.toLocaleLowerCase()
   const file = (event.target.files[0]); 
if(file.size <= 5000000){
    setInvalidFileMessage(false)
    setSelectedFile(file) 
    setUploadedCertificateUrl(file.name)
   
    const fileName = file.name.split('.')[0]+'.'+file.name.split('.')[1]    
     setActivityDetails(data => {
         return{ ...data,
             CertificateUrl : fileName}
      })
}else{
    setSelectedFile('') 
    setInvalidFileMessage(true)
    setActivityDetails(data => {
        return{ ...data,
            CertificateUrl : ""}
    })
}
   
};

const postFile = async() => {
   
    const file:any = selectedfile;

    //on selection, this field alredy available    
    const fileName = activityDetails.CertificateUrl;
    const acepid = userData.acepMemberStatus === 2 ? contactData?.acepid?.toLocaleLowerCase() : userData.acepid.toLocaleLowerCase();

    if(file && fileName && fileName.length>0){
        const formdata = new FormData();
        formdata.append("file",file)
               
          axios.post(API_URL + "/uploadBlob/"+acepid,formdata)
          .then(res => 
            {
            console.log(res);           
          })
    }
    else{
        console.log('Please select file');
    }     
}

const isValid = () =>{
    if(
            activityDetails.Name.trim().length<=0 ||        
            activityDetails.CME_ActivityType_Id.trim().length<=0 ||       
            activityDetails.CompletionDate === null || 
            dayjs(activityDetails.CompletionDate).utc().toDate() > dayjs(new Date()).utc().toDate() ||
            activityDetails.ClaimedHours.trim().length == 0 || 
            Number(activityDetails.ClaimedHours.trim()) <= 0 ||
            isNaN(Number(activityDetails.ClaimedHours)) ||
            cmeHours <= 0 ||
            topicInfo.length <= 0 ||
            topicInfo.some(element => element.IsValidTopicHours === false || element.IsValidTopicName === false)
        )
       { 
        setdisableAddTranscriptButton(true)
        return false;
      }
    else
        {
            setdisableAddTranscriptButton(false)
            return true;
        }
}
const roundUpTopicHours = (event:any,iIndex:number,key:"TopicId"|"TopicHours") => 
    {
          setInputModified('');
          let roundUpValue = GetRoundUpValue(event);        
          let selectedVal = roundUpValue;
          const topicByIndex = topicInfo[iIndex] 
          
          validateTopicInfoDetails(selectedVal,iIndex, topicByIndex,key,true)        
    }

const roundUpCMEHours = (event:any) => 
{
    let roundUpValue = GetRoundUpValue(event);
    setShouldFocus(false);     
    setCMEHours(Number(roundUpValue))                
    setActivityDetails(data => {
                        return{ ...data,
                        ClaimedHours : roundUpValue}
    })
    setActivityDetails(data => {
        return{ ...data,
            MaxHours : roundUpValue}
        }) 
}

const handleNewDateChange = (date: any) => {
    if (date) {
    
       var curDate = date.toDate().toString();
      setActivityDetails((data) => ({
        ...data,
        CompletionDate: curDate,
      }));
    }
  };
  

return(
<>
    {spinner ? 
        <div className="spinner">
        </div>
        :
        null
        }

    <div   className= {isAdmin ? 'UnclaimedDashboard HomePagebackground adminView' : 'UnclaimedDashboard HomePagebackground'} 
    style={{opacity:claimeStyles.opacity,pointerEvents:spinner ? "none":"auto",paddingBottom:'50px' }}> 
    <div className="HomePageWrapper ">
        <div className='flaot_left_with_width'>
            <LeftNavMenu nonmember={(  (userData.acepMemberStatus === 0 && userData.sempaMemberStatus !== 15 ) || (userData.acepMemberStatus === 3 || userData.acepMemberStatus === 4 || userData.acepMemberStatus === 7) )
                                    ? 1 : 0} 
            heightDashboardPage={height} />
        </div>

        <div ref={ref}  className={'Right-Div-individual-Dashboard'} >

        {updateContact?  <div className='column-full'>                     
                    <div className="ContactInfo">              
                      <ContactInfo/>                          
                    </div>               
                  </div> : null}
                  
    <div className='Self-Reported-Info'>
        <div className='SelfReportedActivity-Heading'>
            Add Self-Reported CME Activity
        </div>

        {!showSubmit && <>
            <div className="SelfReported-box">
            <span>Activity Name</span>            
            <input type="text"              
             value={activityDetails.Name} 
            onChange={(ev)=>{
              //  setInputModified('')
               ev.preventDefault();
               setActivityDetails(data => {
                return{ ...data,
                    Name : ev.target.value}
                }) 
                                            
            }
        }
            className={activityDetails.Name.trim().length == 0 && disableAddTranscriptButton ? "input-box error-cme-topic-hours-box" : "input-box"}></input>             
        </div>
        { 
                            ( 
                                
                                activityDetails.Name.trim().length == 0 && disableAddTranscriptButton
                                
                            )
                            &&
                            <div className='error-message-cme-hours'>
                                <span className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required'}
                                </span>
                            </div> 
                        }  

        <div key={shortid.generate()} className="SelfReported-box">
            <span>Activity Type</span>            
            <select  
            required
            className={activityDetails.CME_ActivityType_Id.trim().length === 0 && disableAddTranscriptButton ? "input-box error-cme-topic-hours-box" : "input-box"}
             value={activityDetails.CME_ActivityType_Id||''} 
             onChange={(ev)=>{
                // setInputModified('')
                //setInputModified("activitydetails") 
                ev.preventDefault();
                setActivityDetails(data => {
                    return{ ...data,
                        CME_ActivityType_Id : ev.target.value}
                    })  
                          
                }           
                
            }
                >
                {cmeActivityTypeList && cmeActivityTypeList.map((data:any) => (                     
                   
                    <option key={shortid.generate()} value={data.Id}>{data.Name}</option>
                      
                ))}   
            </select>             
        </div>
        { 

                            ( 
                                
                                activityDetails.CME_ActivityType_Id.trim().length === 0 && disableAddTranscriptButton 
                                
                            )
                            &&
                            <div className='error-message-cme-hours'>
                                <span className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required'}
                                </span>
                            </div>                           
        } 

            <div> 
               <div  className="SelfReported-box" >
                    <div className='selfReported-box-left-div'>
                    <span>Completion Date</span> 
                   <div className=""> 
                   <MyDatePicker
                    defaultValue={new Date(activityDetails.CompletionDate)}
                    disableFutureDates 
                    onChange={   handleNewDateChange  }                  
                   
                   
                    required
                    className={
                        (  activityDetails.CompletionDate.length === 0  && disableAddTranscriptButton ) 
                        || ( (dayjs(activityDetails.CompletionDate).utc()  >  dayjs(new Date()).utc() ))  
                        
                        ? "error-cme-topic width-100-perc SelfReported-date-control" : "width-100-perc SelfReported-date-control"}
/> </div>                
            {/* <TextField
            variant="outlined"
             value={activityDetails.CompletionDate||''} 
              onChange={(ev)=>{
                ev.preventDefault();
                setActivityDetails(data => {
                    return{ ...data,
                        CompletionDate : ev.target.value}
                    })
                 }}         
                type="date" 
                InputLabelProps={{
                    shrink: true,
                  }}
                InputProps={{
                    inputProps: {
                      max:new Date().toISOString().split('T')[0],
                    },
                  }}   
                  style={{width:'100%'}}             
                className={(activityDetails.CompletionDate.trim().length == 0  
                    && disableAddTranscriptButton) ||(activityDetails.CompletionDate > new Date().toISOString().split("T")[0]) 
                    ? "error-cme-topic" : ""}
                >
            </TextField>  */}

                        {       
                        //{dayjs(currentDate).utc() > dayjs(new Date()).utc() && (
                           (dayjs(activityDetails.CompletionDate).utc() > dayjs(new Date()).utc())
                            &&
                            <div className='error-message-cme-hours'>
                                <span className='padding_top_5_px'>
                                <ErrorIcon /> {'Date must be in the past'}
                                </span>
                            </div>
                            }
                            {
                            ( 
                                
                                activityDetails.CompletionDate.length === 0  && disableAddTranscriptButton 
                                
                            )
                            &&
                            <div className='error-message-cme-hours'>
                                <span className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required'}
                                </span>
                            </div> 
                        }    
                    </div>
                    <div key={shortid.generate()} className='selfReported-box-cme-hours-div'>
                    <span>CME Hours</span>            
                        <input type="text"
                        className={(activityDetails.ClaimedHours.trim().length == 0 || Number(activityDetails.ClaimedHours.trim()) <= 0
                            || isNaN(Number(activityDetails.ClaimedHours)) ) && disableAddTranscriptButton? "input-box error-cme-topic-hours-box" : "input-box"}  
                        value={activityDetails.ClaimedHours}  
                        required 
                        //focus only when user enters value in textbox and not when page loads and user moves to other control using autofocus
                        autoFocus={shouldFocus}                       
                        onChange={(event) => { 
                            setInputModified('')
                            event.preventDefault();
                            event.target.focus(); 
                            setShouldFocus(true);
                            if(!isNaN(Number(event.target.value))){
                                setCMEHours(Number(event.target.value))                
                                setActivityDetails(data => {
                                    return{ ...data,
                                        ClaimedHours : event.target.value}
                                    }) 
                                setActivityDetails(data => {
                                    return{ ...data,
                                        MaxHours : event.target.value}
                                    }) 
                            }
                            
                        }}
                        onBlur={(event) => roundUpCMEHours(event)}  
                        ></input>
                        { 
                            ( 
                                
                                (activityDetails.ClaimedHours.trim().length == 0 || Number(activityDetails.ClaimedHours.trim()) <= 0
                                || isNaN(Number(activityDetails.ClaimedHours)) ) && disableAddTranscriptButton
                                
                            )
                            &&
                            <div className='error-message-cme-hours'>
                                <span className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required'}
                                </span>
                            </div> 
        }    
                    </div>    
                </div>
            </div>

       { 
      topicInfo &&  topicInfo.map((topicInfoDetails:iTopicInfo, iIndex:number) => (
            <div key={shortid.generate()}> 
               <div  className="SelfReported-box" >
                    <div className='selfReported-box-left-div'>
                        <span>Topic</span>            
                        <select required 
                        value={topicInfoDetails.TopicId.toString()} 
                        onChange={(event) =>{  setInputModified('');onChangeAddedTopicDetails(event,iIndex,"TopicId")}}  
                        className= {(!topicInfoDetails.IsValidTopicName)  
                        ? 'input-box error-cme-topic-hours-box width-100-perc' 
                        :'input-box width-100-perc'}
                        >
                            {cmeTopicList && cmeTopicList.filter((ele: { Id: number; }) => ele.Id !== 1).map((data:any) => (
                                <option  key={shortid.generate()} value={data.Id}>{data.Name}</option>
                            ))}
                        </select> 

                              {!topicInfoDetails.IsValidTopicName && (
                                <div className="error-message-cme-hours">
                                  <span className="padding_top_5_px">
                                    <ErrorIcon />
                                    {topicInfoDetails.TopicId !== -1
                                      ? "Please choose a different topic"
                                      : "This field is required"}
                                  </span>
                                </div>
                              )}  
                    </div>
                    <div key={shortid.generate()} className='selfReported-box-hours-div'>
                        <span>Hours</span>            
                        <input type="text"
                        id={shortid.generate()}
                        ref={(input) => {inputModified === topicInfoDetails.NewUID && input && input.focus() }} 
                        value={topicInfoDetails.TopicHours} 
                             onChange={(event) => {
                                setInputModified(topicInfoDetails.NewUID)                               
                                topicInfoDetails.TopicHours = event.target.value;
                                event.preventDefault();
                                onChangeAddedTopicDetails(event,iIndex,"TopicHours");                                                              
                            }}
                            onBlur={(event) => roundUpTopicHours(event,iIndex,"TopicHours")}
                            className= {topicInfoDetails.IsValidTopicHours  
                                ? 'input-box width-100-perc' :'input-box error-cme-topic-hours-box width-100-perc'}                              
                        >
                        </input>  
                              {!topicInfoDetails.IsValidTopicHours && (
                                <div className="error-message-cme-hours">
                                  <span className="padding_top_5_px">
                                    <ErrorIcon />
                                     {/* YT: Condition added for CME Hours and Hours  */}
                                     {topicInfoDetails.TopicHours.trim() === "" || parseFloat(topicInfoDetails.TopicHours) === 0
                                     ? "This field is required"
                                     : parseFloat(activityDetails.ClaimedHours) <= parseFloat(topicInfoDetails.TopicHours)
                                     ? "Cannot exceed Claim Hours"
                                      : "This field is required"}
                                  </span>
                                </div>
                              )}
                    </div>
                    <div className='selfReported-box-outoftext-div'>                          
                        out of <label  className='unclaim-bold-max-hours'> {isNaN(cmeHours) ? 0 : cmeHours} </label> 
                        
                    </div> 
                    {topicInfo.length > 1 &&
                    <div
                        className='CMETopicDelelteButton selfReported_Delete_button'>  
                        <DeleteTopic 
                        // onBlur={setAddTrnascriptButtonState}
                         onSubmit={onSubmit(topicInfoDetails.NewUID,iIndex)}
                        />                                                                                
                    </div>
                    }
                 
                </div>
            </div>
            ))
        }

        <div>
            <div className='claim-cme-heading claim-cme-heading-right-text'>
                <div style={{padding: "25px 0px 0px"}} className='transcript_add_topic_div'
                 onClick={(event) => onAddTopicInfo(event)}>
                    <span> Add Topic</span>  
                </div> 
            </div>
        </div>   

        {UploadedCertificateUrl.length <= 0 ? 
        
        <><div style={{ color: invalidFileMessage ? 'red' : '', width: '100%', maxWidth: '700px', height: '30px', textAlign: 'left', padding: "25px 0px 0px" }}>
                                    Upload Certification (Max file size 5MB)
                                </div>
                                <div key={shortid.generate()} className='selfReported-box-left-fileupload-div'>
                                        <form action='#'>

                                            {/* <FileUploadOutlined />                     */}
                                            <label htmlFor="file">

                                                <span style={{ cursor: 'pointer' }} className='transcript-cme-heading-right-text'> <FileUploadOutlined style={{ verticalAlign: 'top' }} /> Upload File  </span>
                                                {/* <span className='selfReported-box-left-fileupload-filename'>

                                                    {activityDetails.CertificateUrl}  </span> */}

                                            </label>

                                            <input id='file'
                                                onChange={(event) => fileBrowseHandler(event)}
                                                type="file" className='display-none' />

                                        </form>
                                    </div></>    
    : 
    <div style={{ color: invalidFileMessage ? 'red' : '', width: '100%', maxWidth: '700px', height: '30px', textAlign: 'left', padding: "25px 0px 0px" }}>
        <div key={shortid.generate()} className='selfReported-box-left-fileupload-div'>
                                        {/* <form action='#'> */}

                                            {/* <FileUploadOutlined />                     */}
                                            {/* <label htmlFor="file"> */}

                                                {/* <span style={{ cursor: 'pointer' }} className='transcript-cme-heading-right-text'> <FileUploadOutlined style={{ verticalAlign: 'top' }} /> Upload File  </span> */}
                                                <span className='claim-cme-certificate-url'>
                                                    {activityDetails.CertificateUrl.length > 0 && activityDetails.CertificateUrl ? <>
                                                        <Button
                                                            // ref={buttonRef}
                                                            variant="outlined"
                                                            className='close-button'
                                                            startIcon={<CloseIcon />}
                                                            onClick={() => {
                                                                setUploadedCertificateUrl('')
                                                                // setActivityDetails(data => {
                                                                //     return{ ...data,
                                                                //         CertificateUrl : ""}
                                                                // })
                                                            }}
                                                        >
                                                        </Button>

                                                    </> : null}

                                                    {activityDetails.CertificateUrl}  </span>


                                            {/* </label> */}

                                            {/* <input id='file'
                                                onChange={(event) => fileBrowseHandler(event)}
                                                type="file" className='display-none' /> */}

                                        {/* </form> */}




                                    </div>
                                    </div>}

         

        <div className='divAddTopicButtons divAddTopicButtonsSelfActivity'>
            
            <Button  
                onClick={(event) =>    onAddSelfReportedActivity(event)}    
                                                                              
                 variant="contained" 
                className='add-transcript-button text_transform_none model-submit-button-border'>
                <b style={{color:'white'}}> 
                    Add To Transcript
                </b>
            </Button> 
        </div>  
        </>
        }

        {
            showSubmit && <>
            <SelfReportedSubmit/>
            </>
        }
                  
    </div>
    </div>
    </div>
    </div>
    </>
    )
}