import React, { createContext, useContext, useEffect } from "react";
import App from "../App";

export const promiseContext = createContext<PromiseType | null>(null);
export default function PromiseContextProvider(props: any) {
  
    // let array: any[] | (() => any[]) = []
    const [promiseObject, setPromiseObject]  = React.useState<string[]>([])
    

    return (

    <div>   
      { 
      <>
      <promiseContext.Provider value={{promiseObject,setPromiseObject}}>{props.children}
       <App/>
      </promiseContext.Provider>          
      </>    
      }     
    </div>

   
  );
}
