import { HomeOutlined,Home,
   DnsOutlined,Dns,
   AssignmentTurnedInOutlined,AssignmentTurnedIn,
   DescriptionOutlined,Description,
   AddCircleOutline,AddCircle,
   DynamicFormOutlined ,  DynamicForm ,DeleteOutline,CallMergeOutlined,CallMerge
  } from '@mui/icons-material' 
  

export const SidebarData = [
{
title: 'Home',         
path: '/',
active:(window.location.pathname == '/' || window.location.pathname.toLowerCase() == '/dashboard') ? 1 : 0,
hover:0,
icon: <HomeOutlined />,  
icon_primary: <HomeOutlined color="primary"/>,      
icon_active:<Home />,
nonmember:1,
onlyforadmin:0,
allowAccessToShow : 1
},
{
title: 'Claim CME',         
path: '/dashboard/UnclaimedCME',
active:window.location.pathname.toLowerCase() == '/dashboard/unclaimedcme'.toLowerCase() ? 1 : 0,
hover:0,
icon: <AssignmentTurnedInOutlined />,
icon_primary: <AssignmentTurnedInOutlined color="primary"/>,
icon_active:<AssignmentTurnedIn />, 
nonmember:1,
onlyforadmin:0,
allowAccessToShow : 1
},
{
title: 'Progress',         
path: '/dashboard/InProgress',
active:window.location.pathname.toLowerCase() == '/dashboard/inprogress'.toLowerCase() ? 1 : 0,
hover:0,
icon: <DynamicFormOutlined />,
icon_primary: <DynamicFormOutlined color="primary"/>,
icon_active:<DynamicForm />, 
nonmember:0,
onlyforadmin:0,
allowAccessToShow : 1
},
{
title: 'CME Profile',        
path: '/dashboard/CMEProfiles',
active:window.location.pathname.toLowerCase() === '/dashboard/cmeprofiles'.toLowerCase() ? 1 : 0,
hover:0,
icon: <DnsOutlined  />,
icon_primary: <DnsOutlined color="primary"/>,
icon_active:<Dns />, 
nonmember:0,
onlyforadmin:0,
allowAccessToShow : 1
},
{
title: 'Transcript',         
path: '/dashboard/MostRecentActivities',
active:window.location.pathname.toLowerCase() === '/dashboard/mostrecentactivities' ? 1 : 0,
hover:0,
icon: <DescriptionOutlined />,
icon_primary: <DescriptionOutlined color="primary"/>,
icon_active:<Description />, 
nonmember:1,
onlyforadmin:0,
allowAccessToShow : 1
},
{
  title: 'Self Reported',         
  path: '/dashboard/SelfReported',
  active:window.location.pathname.toLowerCase() === '/dashboard/selfreported' ? 1 : 0,
  hover:0,
  icon: <AddCircleOutline />,
  icon_primary: <AddCircleOutline color="primary"/>,
  icon_active:<AddCircle />, 
  nonmember:1,
  onlyforadmin:0,
  allowAccessToShow : 1
  },
//To Do - for admin
  {
    title: 'Records',         
    path: '/dashboard/DeletedActivities',
    active:window.location.pathname.toLowerCase() === '/dashboard/deletedactivities' ? 1 : 0,
    hover:0,
    icon: <DeleteOutline />,
    icon_primary: <DeleteOutline color="primary"/>,
    icon_active:<DeleteOutline />, 
    nonmember:0,
    onlyforadmin:1,
    allowAccessToShow : 1
  },
  {
    title: 'Merge CME',         
    path: '/dashboard/MergeCME',
    active:window.location.pathname.toLowerCase() === '/dashboard/mergecme' ? 1 : 0,
    hover:0,
    icon: <CallMergeOutlined />,
    icon_primary: <CallMergeOutlined color="primary"/>,
    icon_active:<CallMerge />, 
    nonmember:0,
    onlyforadmin:1,
    allowAccessToShow : 0
  }
]
