import React, {useState} from 'react';
import {ExpandLess} from '@mui/icons-material';
 

const ScrollToTopButton = (props:any): JSX.Element =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (
    <div className='scroll-to-top-button-div' style={{display: visible ? 'inline' : 'none'}}>       
        <div className='scroll-to-top-button'  onClick={scrollToTop}  >
            <ExpandLess fontSize='large' style={{cursor:'pointer'}}  />
        </div>       
   </div>
);
}

export default ScrollToTopButton;
