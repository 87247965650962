import React,{useContext, useState,useLayoutEffect,useRef} from 'react'
import LeftNavMenu from "../common/Navbar";
import '../../styles/Unclaimed-CME-Dashboard.css';
import '../../styles/profile.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, Link, styled, Typography } from "@mui/material";
import shortid from  "shortid";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import apiUsers from "../../api-operation";
import {contactContext} from '../../context/contactContext';
import { BrowserRouter, useHistory } from 'react-router-dom';
import EmptyProgress from '../../images/ProgressEmpty.png'
import { promiseContext } from '../../context/promiseContext';
import {userContext} from "../../context/userContext";
import {GetProfileData,GetRequirementData} from '../../utils/cache';
import { ContactInfo } from '../ContactInfo';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#9D48C6",
    },
  }));

  const CompleteLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#00D16D",
    },
  }));
  


export function InProgressDashboard(progressStyles:any){
    
    const [isNavExpanded, setIsNavExpanded] = React.useState(false);
    const [requirementListData, setListData] = React.useState([]);
    const [completedRequirementListData, setCompleteListData] = React.useState(requirementListData);
    const [showData, setShowData] = useState(false);let listData: any = [];
    const [enableClaim, setEnableClaim] = React.useState('#1976D2');
    const [enableCompleteClaim, setEnableCompleteClaim] = React.useState('#1976D2');
    let completelistData: any = [];
    let contactObject  = useContext(contactContext);
    const history = useHistory();
    const [topicRequirementListData, setTopicRequirementListData] = React.useState([]);
    let contactData = contactObject.contactObject.Contact;
    let topicRequirementData :any =[];
    let topicArray : any =[];
    let profilelistData: any = [];
    const [profileListData, setProfileListData] = React.useState([]);
    const [updateContact, setupdateContact] = useState(false);
    const [showLinearProgress, setLinearProgress] = useState(true);


    let promiseValue = React.useContext(promiseContext) as PromiseType;
      //For Left Nav Height
      const ref = useRef<HTMLInputElement>(null);
      const [height, setHeight] = useState(0);
      const [loadNavHeight, setLoadNavHeight] = useState(false);
      
      let userObject = useContext(userContext);
      let userData = userObject.User;
      //check user is admin or not
    let isAdmin:boolean = userData.acepMemberStatus === 2 ? true : false;

      if(userData.acepMemberStatus === 2){
        contactData =contactObject.contactObject.Contact;
        if(!updateContact)
        setupdateContact(true);
      }
      else{
            contactData =contactObject.contactObject.Contact;
      }

     
      const {data : profileAPIData, isLoading: profileAPICalled}  = GetProfileData(contactData?.contactid);
      const {data : requirementAPIData,  isLoading: requirementAPICalled}  = GetRequirementData(contactData?.contactid);


      useLayoutEffect(()=>{
        
          var HomePagebackgroundHeight = document.body.getElementsByClassName('HomePagebackground')[0]; // Assuming there's only one element with class 'HomePagebackground'
          var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
          var newHeight = currentHeight + 120; 
          setHeight(newHeight);
          setLoadNavHeight(false)
        
      },[loadNavHeight])

    function redirect(){
      //This will check user session is active or not
      localStorage.setItem("loadSSOData","1")

      let targetPath = "/dashboard/CMEProfiles";

      if(isAdmin && targetPath !== '/'){
        targetPath = targetPath + '?acepid='+contactData?.acepid;
      }

    history.push(targetPath, {contact: contactData, from: "AdminDashboard"});
  
    }   
    
    React.useEffect(() => {
      if( (userData.acepMemberStatus === 0 && userData.sempaMemberStatus !== 15 ) || (userData.acepMemberStatus === 3 || userData.acepMemberStatus === 4 || userData.acepMemberStatus === 7) )
      {
        history.push("/")
      }
    else{
            callProfileData();      
            callUsersData();   
    }                           
      
      
    
      },[contactData?.contactid,profileAPICalled,requirementAPICalled]);
    
      function callUsersData() {
        if(contactData?.contactid !=='' && requirementAPIData && !requirementAPICalled)
        {
        var rows  = requirementAPIData; 
          if (rows.length > 0) {
            for (let index = 0; index < rows.length; index++) {
              const listObject: any = {};
              let element: IListData = rows[index];
              listObject["title"] = element.Notes;
              listObject["id"] = element.Id;
              listObject["description"] = element.Description;
              listObject["maxhours"] = element.CMEHours  ;
              listObject["CME_Topic_Id"] = element.CME_Topic_Id  ;
              listObject["Topic_Title"] = element.CME_Topic.Name
              listObject["CompletedHours"] = 0;
              listObject["RemainingHours"] = 0;
              listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();

              if(element.CME_Topic_Id !== 1)
              {                                                           
                listData.push(listObject);

                topicArray.forEach((topic: {
                  Id:[number] ;
                  Frequency: any; CertificationBoardId: string; }) => {
                  if(topic.CertificationBoardId === element.CME_CertificationBoard_Id.toLocaleLowerCase()){
                    topic.Frequency = element.Frequency;
                    topic.Id.push(element.CME_Topic_Id);
                  }
                });  
              }   
         
            }

            setListData(listData);
            progressStyles.showSpinner(false)
            //setCompleteListData(listData);
            completelistData = listData;

            listData = listData.filter((value:any, i:any) => {
              return Number(value.CME_Topic_Id) !== 1})

            completelistData = completelistData.filter((value:any, i:any) => {
              return Number(value.CME_Topic_Id) !== 1 })

            // eslint-disable-next-line react-hooks/exhaustive-deps
            topicArray = topicArray.filter((topic:any) => {
            return topic.Frequency !== 0})

            setListData(listData);
            callTopicRequirementData(topicArray,listData);

          }
          else{
            setEnableClaim('#DDDDDD')
            setShowData(true)

            //this will adjust the heigh on dashboard
            setLoadNavHeight(true)

            if(completelistData.length <= 0) 
              setEnableCompleteClaim('#DDDDDD')

            if(promiseValue.promiseObject.length !== 3)
              promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetail'])   
            
              //To hide the waid indicator
              progressStyles.showSpinner(false)
              setLinearProgress(false)
            
          } 

          if(promiseValue.promiseObject.length !== 3){         
              promiseValue.setPromiseObject((prev: any) => [...prev, 'cmerequirementsbycontact'])  
          }    
     // });
     setLoadNavHeight(true)
    }
   
  };
  function callProfileData(){
    if(profileAPIData && !profileAPICalled){
    // apiUsers.url = "cmeprofiles?contactid=" + contactData?.contactid;
    // apiUsers.getAll().then((resp: any) => {
    // let user = resp.cmeprofiles?.rows;
    var rows  = profileAPIData; 
  // if (rows.length > 0) {
    for (let index = 0; index < rows.length; index++) {
      const listObject: any = {};
      let element: IProfileListData = rows[index];
      listObject["title"] = element.CME_CertificationBoard.Name;
      listObject["id"] = element.Id;
      listObject["key"] = element.Id;
      listObject["recertificationDate"] = new Date(element.RecertificationDate).toDateString().substring(4);
      listObject["generalPercent"] = element.GeneralPercent;
      listObject["topicPercent"] = element.TopicPercent;
      listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();
      
      let getTopicObject = {
        Frequency : 0,
        RecertificationDate : element.RecertificationDate,
        CertificationBoardId :  element.CME_CertificationBoard_Id.toLocaleLowerCase(),
        Id : []
      }

      topicArray.push(getTopicObject);
    
      profilelistData.push(listObject);
    }
    setProfileListData(profilelistData);
  //}
  if(promiseValue.promiseObject.length !== 3){
    promiseValue.setPromiseObject((prev: any) => [...prev, 'cmeprofiles'])
    // promiselistData.push('cmeprofiles')
    // promiseValue.setPromiseObject(promiselistData) ; 
  }

  setLoadNavHeight(true)
 // });
  }
}

  function callTopicRequirementData(topicArray:any, listData:any) {
    if(topicArray.length > 0 ){
    apiUsers.url = "cmedetail/" + contactData?.contactid;
    apiUsers.post({
      topic : topicArray

    }).then((resp: any) => {
      let user = resp?.rows;

      if (resp?.rows?.length > 0) {

        for (let index = 0; index < user.length; index++) {
              
          let elementDetail  =user[index];
                            
          elementDetail.forEach ((topicElement: { CME_Topic_Id: any; TopicTotalHours: any; CME_CertificationBoard_Id:any}) => {

          const listObject: any = {};  
          
          listObject["CME_Topic_Id"] = topicElement.CME_Topic_Id;

          listObject["TopicTotalHours"] = topicElement.TopicTotalHours;

          listObject["CertificationBoardId"] = topicElement.CME_CertificationBoard_Id.toLowerCase();
          
          topicRequirementData.push(listObject);
         
        });          
    }
  
        if(topicRequirementData.length > 0)
        {
            let result = listData.map((item: { CME_Topic_Id: any; CompletedHours: any; CertificationBoardId :any;RemainingHours:any;maxhours:any}) => {
            let match =  topicRequirementData.find((x: { CME_Topic_Id: any; CertificationBoardId:any }) => x.CME_Topic_Id === item.CME_Topic_Id && x.CertificationBoardId === item.CertificationBoardId);
            if(match) {
              item.CompletedHours = match.TopicTotalHours;
              item.RemainingHours = item.maxhours- item.CompletedHours ;
            }
            else{
              // item.CompletedHours = 0;
              item.RemainingHours = item.maxhours- item.CompletedHours;
            }
            return item;
          });
          setListData(result);
        }

      const uniqueRequirements = getUniqueRequirements(listData);
      setListData(uniqueRequirements as never[]);

      //filterRequirements based on remaining hours > 0 
      let partialListData = uniqueRequirements.filter((value:any, i:any) => {
        return Number(value.RemainingHours) > 0});

      let fullListData = uniqueRequirements.filter((value:any, i:any) => {
        return Number(value.RemainingHours) <= 0});


          if(topicRequirementData.length > 0)
          {
            setListData(partialListData as never[]);     
            setCompleteListData(fullListData as never[]);
            setLoadNavHeight(true)    

            if(fullListData.length <= 0) 
            setEnableCompleteClaim('#DDDDDD')

            if(partialListData.length <= 0)  
                setEnableClaim('#DDDDDD')         
          }
         
         
          setShowData(true)
             
          setTopicRequirementListData(topicRequirementData);

        if(promiseValue.promiseObject.length !== 3)
          promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetail']) 
      }
      else{
          if(promiseValue.promiseObject.length !== 3)
            promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetail']) 
      }

      //To hide the waid indicator
      progressStyles.showSpinner(false)
      setLinearProgress(false)
      setLoadNavHeight(true)
    });
  }
  else
  {
    //To hide the waid indicator
      progressStyles.showSpinner(false)
      setLinearProgress(false)
      if(promiseValue.promiseObject.length !== 3)
      promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetail'])         
  }
}

  // Function to filter and get the requirement with the highest RemainingHours for each TopicId
  const getUniqueRequirements = (listData: { CME_Topic_Id: any; RemainingHours: number; }[]) => {
    const uniqueRequirements: any[] = [];

    listData.forEach((requirement: { CME_Topic_Id: any; RemainingHours: number; }) => {
      const index = uniqueRequirements.findIndex((item) => item.CME_Topic_Id === requirement.CME_Topic_Id);

            if (index === -1) {
              uniqueRequirements.push(requirement);
            } else {
              // If a matching TopicId is found, compare RemainingHours and update if needed
              if (requirement.RemainingHours > uniqueRequirements[index].RemainingHours) {
                uniqueRequirements[index] = requirement;
              }
            }
    });

    return uniqueRequirements;
  };

  return(
    <div ref={ref} className= {isAdmin ? 'UnclaimedDashboard HomePagebackground adminView' :'UnclaimedDashboard HomePagebackground'} 
    style={{pointerEvents:progressStyles.progressStyles.pointer, opacity:progressStyles.progressStyles.opacity}}> 
        <div    className="HomePageWrapper ">
            <div style={{float:'left',width:'5%'}}>
                <LeftNavMenu heightDashboardPage={height} onCollapse={(isNavExpanded:any) => {
                    setIsNavExpanded(isNavExpanded);
                }} />
            </div>

            <div   className={isNavExpanded ? 'Right-Div-Dashboard-expanded overlay' : 'Right-Div-individual-Dashboard'}>

            {updateContact?  <div className='column-full'>                     
                    <div className="ContactInfo">              
                      <ContactInfo/>                          
                    </div>               
                  </div> : null}
                  
                <div  className="UncalimedDashboard-Heading">
                    <div className="div-left">
                     Progress
                    </div> 
                    <div className="div-right-Unclaimed-Dashboard">
                        <div className='Unclaimed-CME_Dashboard-right-button' style={{ color : enableClaim, display:'table-cell'}}>                        
                        <BrowserRouter>
                                {/* <Button to={"/dashboard/UnclaimedCME"} component={Link} onClick={redirect}>Claim CME</Button> */}
                                {requirementListData.length > 0 ? <Link href= {isAdmin ? "/dashboard/UnclaimedCME?acepid=" + contactData?.acepid : "/dashboard/UnclaimedCME"} underline="hover" color={enableClaim}> Claim CME </Link>
                                :
                                <Link underline="hover" color={enableClaim}> Claim CME </Link> }                   
                                </BrowserRouter>
                                <ArrowForwardIcon  style={{verticalAlign:'top'}}/>                                
                        </div>                       
                    </div>
                </div>

                <div >
                {requirementListData && requirementListData.length > 0 ?  
                    <div className='Unclaimed-dashboard-secondColumn box-style' style={{top:'230px'}} >  
                       <>                                     
                        <List sx={{ width: '100%'}}>
                        {requirementListData
                        //.sort((item:any,next:any)=> item.Topic_Title > next.Topic_Title ? 1 : -1,)
                        .sort((item:any, next:any) => {
                          // Compare based on RemainingHours in descending order
                          const remainingHoursA = item.RemainingHours;
                          const remainingHoursB = next.RemainingHours;
                      
                          if (remainingHoursA !== remainingHoursB) {
                            // Sort by RemainingHours in descending order
                            return remainingHoursB - remainingHoursA;
                          } else {
                            // If RemainingHours are equal, sort by Topic_Title in alphabetical order
                            const topicTitleA = item.Topic_Title.toLowerCase();
                            const topicTitleB = next.Topic_Title.toLowerCase();
                      
                            if (topicTitleA < topicTitleB) {
                              return -1;
                            } else if (topicTitleA > topicTitleB) {
                              return 1;
                            } else {
                              return 0; // Topics are equal
                            }
                          }
                        })
                        .map((item: any) => (                               
                          
                          <div key={shortid.generate()}>
                            
                              <ListItem key={shortid.generate() } alignItems="flex-start" sx={{ padding: '0 3%', paddingTop:'26px'}}>                                             
                              <ListItemText 
                              primary=
                              {                            
                                <React.Fragment>
                                <Typography
                                component="div"
                                variant="h2"
                                className="RequirementTitle"
                              >
                                {item.Topic_Title}
                              </Typography>
                              {
                                showLinearProgress ?
                                <LinearProgress color="inherit"  />  : 
                                <BorderLinearProgress
                                variant="determinate"
                                sx={{ height: "12px", borderRadius: "20px" }}
                                value={Math.round(item.CompletedHours/ item.maxhours * 100)}
                              />
                              }
                              
                            </React.Fragment>                                        
                          }
                          secondary={
                            <React.Fragment>
                              <div className="HoursDiv">
                                <Typography
                                  // sx={{ display: 'inline' }}
                                  component="span"
                                  //variant="h3"
                                  //color="text.primary"
                                  className="HoursComplete"
                                >
                                  {item.CompletedHours}/{item.maxhours} Hrs Completed
                                </Typography>

                                <Typography
                                  // sx={{ display: 'inline' }}
                                  component="span"
                                  variant="h3"
                                  //color="text.primary"
                                  className="HoursRemaining"
                                >
                                  {item.CompletedHours  < item.maxhours ? (item.maxhours - item.CompletedHours) === 1? '1 Hr Remaining' :item.maxhours - item.CompletedHours + ' Hrs Remaining' : 0 + ' Hrs Remaining' } 
                                      
                                </Typography>
                              </div>
                            </React.Fragment>
                              }
                              />   
                          </ListItem>
                          
                         </div>
                          
                          ))}
                          
                          
                        </List>
                       </>
                       </div>  
                  : null 
                }

 {!showLinearProgress && requirementListData && requirementListData.length === 0 && (
  <div className='EmptyList'>
    <div className='empty-text'> 
      You do not have any topics in progress.
    </div>
    <div>
      <img src={EmptyProgress} alt="Empty Progress"></img>
    </div>
    <div>
      <Button                                        
        style={{ textTransform: 'none' }} 
        variant="contained" 
        className='explore-more-opp-div' 
        onClick={redirect}
      >
        <b> View My CME Profiles</b>
      </Button> 
    </div>
  </div>
)}

                    
                    
                    
                
                </div>
            </div>

            <div className={isNavExpanded ? 'Right-Div-Dashboard-expanded overlay' : 'Right-Div-individual-Dashboard'}>
                <div  className="UncalimedDashboard-Heading">
                    <div className="div-left">
                     Completed
                    </div> 
                    <div className="div-right-Unclaimed-Dashboard">
                        <div className='Unclaimed-CME_Dashboard-right-button' style={{ color : enableCompleteClaim, display:'table-cell'}}>                            
                            <BrowserRouter>
                                {/* <Button to={"/dashboard/UnclaimedCME"} component={Link} onClick={redirect}>Claim CME</Button> */}
                                {completedRequirementListData.length > 0 ? <Link href= {isAdmin ? "/dashboard/MostRecentActivities?acepid=" + contactData?.acepid : "/dashboard/MostRecentActivities"} underline="hover" color={enableCompleteClaim} > Get Transcript  </Link>
                                :
                                <Link underline="hover" color={enableCompleteClaim}> Get Transcript  </Link>}
                                
                                </BrowserRouter>
                                <ArrowForwardIcon  style={{verticalAlign:'top'}}/>                               
                        </div>                       
                    </div>
                </div>

          <div>
            {
              <>
                
                {completedRequirementListData && completedRequirementListData.length > 0 ? (
                        <div className='Unclaimed-dashboard-secondColumn box-style' style={{ top:'1100px'}} >  
                    <>
                      <List sx={{ width: "100%" }}>
                        {completedRequirementListData
                        .sort((item:any, next:any) => {                  
                            // If RemainingHours are equal, sort by Topic_Title in alphabetical order
                            const topicTitleA = item.Topic_Title.toLowerCase();
                            const topicTitleB = next.Topic_Title.toLowerCase();
                      
                            if (topicTitleA < topicTitleB) {
                              return -1;
                            } else if (topicTitleA > topicTitleB) {
                              return 1;
                            } else {
                              return 0; // Topics are equal
                            }                  
                        })
                        .map( (item: any, index: number) =>  (
                            <div key={shortid.generate()}>
                            <ListItem key={shortid.generate() } alignItems="flex-start" sx={{ padding: '0 3%', paddingTop:'26px'}}>

                                <ListItemText
                                        primary=
                                          {
                                            <React.Fragment>
                                            <Typography
                                            component="div"
                                            variant="h2"
                                            className="RequirementTitle"
                                          >
                                            {item.Topic_Title}
                                          </Typography>
                                          <CompleteLinearProgress
                                            variant="determinate"
                                            sx={{ height: "12px", borderRadius: "20px" }}
                                            value={100}
                                          />
                                        </React.Fragment>
                                      }
                                  secondary={
                                    <React.Fragment>
                                      <div className="HoursDiv">
                                        <Typography
                                          // sx={{ display: 'inline' }}
                                          component="span"
                                          //variant="h3"
                                          //color="text.primary"
                                          className="HoursComplete"
                                        >
                                              {item.CompletedHours}/{item.maxhours} Hrs Completed

                                        </Typography>

                                        <Typography
                                          // sx={{ display: 'inline' }}
                                          component="span"
                                          variant="h3"
                                          //color="text.primary"
                                          className="HoursRemaining"
                                        >
                                          {0} Hrs Remaining
                                        </Typography>
                                      </div>
                                    </React.Fragment>
                                  }
                                />
                                                      
                            </ListItem>
                        
                        </div>
                        
                        ))}
                              
              </List>
                    </>
                  </div>
                ) : null}
                {!showLinearProgress &&
                  completedRequirementListData &&
                  completedRequirementListData.length === 0 && (
                    <div className="CompleteEmptyList">
                      <div className="empty-text">
                        You do not have any completed topics.
                      </div>
                    </div>
                  )}
              </>
            }
                </div>
            </div>
            
        </div>
    
    </div>
  )

}

export default function InProgress() {
  let enableObject = {opacity:0.4,pointer:'none'}
  const [progressStyles, setprogressStyles] = useState(enableObject);

  const [spinner, setSpinner] = useState(true);
 

 React.useEffect(() => {  
                     
            if(!spinner){
                 enableObject={
                    opacity:1, 
                    pointer:'auto'
                }              
             
            }

            setprogressStyles(enableObject);
    },[spinner]) 


  return(   
      <>     
      
      {spinner? 
      <div className="spinner">
      </div>
      :
      null
      }
      
      <InProgressDashboard progressStyles={progressStyles} showSpinner={(toShow:boolean)=>{
                    setSpinner(toShow)
                  }}   />
    </>                                
    );    
}