import { useContext } from 'react'
import {ContactInfo} from './ContactInfo'
import {CMERequirement} from './CMERequirement'
import {UnclaimedCMEHours} from './UnclaimedCMEHours'
import '../styles/dashboard.css'
import { CMEProfile } from "./CMEProfile";
import MostRecentActivities from './MostRecentActivities'
import LeftNavMenu from "./common/Navbar";
import { useLayoutEffect, useState} from 'react';
import {userContext} from "../context/userContext";
import { AdminDashboard } from './admin/AdminDashboard'

const DashboardUser = (dashboardStyles:any): JSX.Element =>{
   
  const [height, setHeight] = useState(0);
  const [loadNavHeight, setLoadNavHeight] = useState(false);
  
  useLayoutEffect(() => {
    
        var HomePagebackgroundHeight = document.body.getElementsByClassName('HomePagebackground')[0]; // Assuming there's only one element with class 'HomePagebackground'
        var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
        var newHeight = currentHeight + 120;  
        setHeight(newHeight);
        setLoadNavHeight(false)
     
},[loadNavHeight])

 
    return(

      <div>{
        <div   className='HomePagebackground' > 
          <div  className="HomePageWrapper"> 
            <div  className="navi-menu" style={{float:'left',width:'5%'}}>
              <LeftNavMenu nonmember={0} heightDashboardPage={height} />
            </div>
            <div  className='Right-Div-Dashboard'>                                           
                  <div className="PersonalInfo">              
                    <div className='column-full'></div>  <ContactInfo />  
                   </div>                        
              </div>                 

            <div  className='Right-Div-Dashboard'>                        
              <div className='column-1'> 
                <UnclaimedCMEHours />   
              </div> 
              <div className='column-2'>  
                <CMERequirement /> 
              </div>
            </div>

              <div  className='Right-Div-Dashboard'>    
                <div className='column-full'>
                  <CMEProfile onLoadPage={(onload:boolean)=>{
                    setLoadNavHeight(true)
                  }} />                
                </div>
              </div>

              <div  className='Right-Div-Dashboard'>
                <div className='column-full'>
                  <MostRecentActivities onLoadPage={(onload:boolean)=>{
                    setLoadNavHeight(true)
                  }} />
                </div>
              </div>
          </div>
        </div>
        }
      </div>
   
      
      );    
}

const DashboardAdmin = (dashboardStyles:any): JSX.Element =>{
   
  const [height, setHeight] = useState(0);
  const [loadNavHeight, setLoadNavHeight] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  
  useLayoutEffect(() => {
     
      var HomePagebackgroundHeight = document.body.getElementsByClassName('HomePagebackground')[0]; // Assuming there's only one element with class 'HomePagebackground'
      var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
      var newHeight = currentHeight + 120;
      setHeight(newHeight)
      setLoadNavHeight(false)
    
},[loadNavHeight])

 
    return(

      <div>{
        <div   className='HomePagebackground' > 
          <div  className="HomePageWrapper"> 
            <div  className="navi-menu" style={{float:'left',width:'5%'}}>
              <LeftNavMenu member={1} heightDashboardPage={height} onCollapse={(isNavExpanded:any) => {
                    setIsNavExpanded(isNavExpanded);
                }} />
            </div>
            <div  className={isNavExpanded ? 'Right-Div-Dashboard-expanded overlay' : 'Right-Div-individual-Dashboard'}>                                           
                <AdminDashboard onLoadPage={(onload:boolean)=>{
                    setLoadNavHeight(true)
                  }}   />                   
              </div>                 

            
          </div>
        </div>
        }
      </div>
   
      
      );    
}
const DashboardNonMember = (dashboardStyles:any): JSX.Element =>{
   
  const [height, setHeight] = useState(0);
  const [loadNavHeight, setLoadNavHeight] = useState(false);
  
  useLayoutEffect(() => {
    
      var HomePagebackgroundHeight = document.body.getElementsByClassName('HomePagebackground')[0]; // Assuming there's only one element with class 'HomePagebackground'
      var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
      var newHeight = currentHeight + 120; 
      setHeight(newHeight);
      setLoadNavHeight(false)
    
},[loadNavHeight])

 
    return(

      <div>{
        <div   className='HomePagebackground' > 
          <div  className="HomePageWrapper"> 
            <div  className="navi-menu" style={{float:'left',width:'5%'}}>
              <LeftNavMenu nonmember={1} heightDashboardPage={height} />
            </div>
            <div  className='Right-Div-Dashboard'>
                <div className='column-full'>                     
                    <div className="PersonalInfo">              
                      <ContactInfo />                          
                    </div>               
                  </div> 
            </div>

            <div  className='Right-Div-Dashboard'>
              <div className='column-full'>    
                <UnclaimedCMEHours nonmember={1} />
              </div>               
            </div> 

            <div  className='Right-Div-Dashboard'>
              <div className='column-full'>   
                <MostRecentActivities nonmember={1} onLoadPage={(onload:boolean)=>{
                  setLoadNavHeight(true)
                }} />
                </div>               
            </div>
          </div>
        </div>
        }
      </div>
   
      
      );    
}

export const Dashboard = (): JSX.Element =>{
  let userObject = useContext(userContext);
  let userData = userObject.User;
  let nonMember =  ( (userData.acepMemberStatus === 0 && userData.sempaMemberStatus !== 15 ) || (userData.acepMemberStatus === 3 || userData.acepMemberStatus === 4 || userData.acepMemberStatus === 7) ) ? 1 : 0
  let member = userData.acepMemberStatus === 2  ? 1 : 0;
  return(   
      <> 
     {
      nonMember
      ?
      <>
      
      <DashboardNonMember  />
      </> 
     
      :
      //To do - for admin
      member?
      <DashboardAdmin/>
      :
      <>   
        <DashboardUser  />
        </> 
     } 
    </>                                
    );    
}