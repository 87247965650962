import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import Main from './components/Main';
import { AdminDashboard } from './components/admin/AdminDashboard';
import ProfileList from './components/Profile/ProfileList';
import { Dashboard } from './components/Dashboard';
import UserList from './components/CMEUser/CMEUserList';
import UnclaimedCMEDashboard from './components/UnclaimedCMEDashboard/UnclaimedCMEDashboard';
import MostRecentActivitiesDashboard from './components/MostRecentActivitiesDashboard/MostRecentActivitiesDashboard';
import InProgress from './components/InProgress/InProgress';
import SelfReportActivity from './components/SelfReportedActivity';
import SelfReportSubmit from './components/SelfReportedSubmit';
import ScrollToTopButton from './components/common/ScrollToTopButton';
import DeletedActivities from './components/DeletedActivities';
import  MergeCME  from './components/MergeCME';
import AcepMaster from './acep-master';
import { useState } from 'react';

function App() {
  const [isAcepMasterLoaded, setAcepMasterLoaded] = useState(false);

  const handleAcepMasterLoad = () => {
    setAcepMasterLoaded(true);
  };

  // useEffect(() => {
  //   // Simulating an asynchronous operation to load AcepMaster
  //   setTimeout(() => {
  //     setAcepMasterLoaded(true);
  //   }, 1000);
  // }, []);

  return (  
      <AcepMaster onAcepMasterLoad={handleAcepMasterLoad}>
        {isAcepMasterLoaded ? (
          <div className="App">
            <ScrollToTopButton />
            {/* <Main />     */}

            <Switch>
              <Route path="/index.html" exact component={Dashboard} />
              <Route path="/index.htm" exact component={Dashboard} />
              <Route path="/" exact component={Dashboard} />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/dashboard/Admin" exact component={AdminDashboard} />
              <Route path="/dashboard/CMEProfiles" exact component={ProfileList} />
              <Route path="/dashboard/CMEUsers" exact component={UserList} />
              <Route path="/dashboard/UnclaimedCME" exact component={UnclaimedCMEDashboard} />
              <Route path="/dashboard/InProgress" exact component={InProgress} />
              <Route path="/dashboard/MostRecentActivities" exact component={MostRecentActivitiesDashboard} />
              <Route path="/dashboard/SelfReported" exact component={SelfReportActivity} />
              <Route path="/dashboard/SelfReportedSubmit" exact component={SelfReportSubmit} />
              <Route path="/dashboard/DeletedActivities" exact component={DeletedActivities} />
              <Route path="/dashboard/MergeCME" exact component={MergeCME} />
            </Switch>

          </div>
        ):  null
          }
        </AcepMaster>

  );
}

export default App;
