import * as React from "react";
import { Button, Link, styled, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import "../styles/dashboard.css";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import shortid from  "shortid";
import '../styles/profile.css';
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import apiUsers from "../api-operation";
import { useContext, useState } from "react";
import { contactContext } from "../context/contactContext";
import { useHistory } from "react-router-dom";
import { promiseContext } from "../context/promiseContext";
import {GetProfileData,GetRequirementData} from '../utils/cache';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#9D48C6",
  },
}));

const SuccessBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00D16D",
  },
}));

export const CMERequirement = (props:any): JSX.Element => {
  const [requirementListData, setListData] = React.useState([]);
  const [profileListData, setProfileListData] = React.useState([]);
  let listData: any = [];
  let profilelistData: any = [];
  const history = useHistory();
  let contactObject  = useContext(contactContext);
  let promiseValue= React.useContext(promiseContext) as PromiseType;
  
  const [topicRequirementListData, setTopicRequirementListData] = React.useState([]);
  const [requirementLoaded, setRequirementLoaded] = useState(false);
  let contactData = contactObject.contactObject.Contact;
  let topicRequirementData :any =[];
  let topicArray : any =[];
  
  const {data : profileAPIData,isLoading:profileAPICalled}  = GetProfileData(contactData?.contactid);
  const {data : requirementAPIData, isLoading:requirmentAPICalled}  = GetRequirementData(contactData?.contactid);
  
    React.useEffect(() => {       
      if(contactData?.contactid !=='' && requirementListData.length===0 && requirementAPIData && !requirmentAPICalled){

        callProfileData();

            var rows  = requirementAPIData; 

            for (let index = 0; index < rows.length; index++) {
              const listObject: any = {};
              let element: IListData = rows[index];
              listObject["title"] = element.Notes;
              listObject["id"] = element.Id;
              listObject["description"] = element.Description;
              listObject["maxhours"] = element.CMEHours  ;
              listObject["CME_Topic_Id"] = element.CME_Topic_Id  ;
              listObject["Topic_Title"] = element.CME_Topic.Name;
              listObject["CompletedHours"] = 0;
              listObject["RemainingHours"] = 0;
              listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();
              
              if(element.CME_Topic_Id !== 1)
              {                                  
                           
                  listData.push(listObject);

                topicArray.forEach((topic: {
                  Id:[number] ;
                  Frequency: any; CertificationBoardId: string; }) => {
                  if(topic.CertificationBoardId === element.CME_CertificationBoard_Id.toLocaleLowerCase()){
                    topic.Frequency = element.Frequency;                 
                    topic.Id.push(element.CME_Topic_Id);
                  }
                });  
              }   
              
            }           
            
            // eslint-disable-next-line react-hooks/exhaustive-deps
            listData = listData.filter((value:any) => {
              return value.CME_Topic_Id !== 1})

          // eslint-disable-next-line react-hooks/exhaustive-deps
          topicArray = topicArray.filter((topic:any) => {
                return topic.Frequency !== 0})

            let filteredList = listData.filter( (ele: { CME_Topic_Id: any; }, ind: any) => ind === listData.findIndex( (elem: { CME_Topic_Id: any; }) => elem.CME_Topic_Id === ele.CME_Topic_Id))

             
            setListData(filteredList);
            callTopicRequirementData(topicArray,filteredList);
    }                            
      
      },[requirmentAPICalled]);
    
      function callProfileData(){
        if(profileListData.length === 0 && profileAPIData && !profileAPICalled)
        {
            var rows  = profileAPIData; 

            for (let index = 0; index < rows.length; index++) {
                const listObject: any = {};
                let element: IProfileListData = rows[index];
                listObject["title"] = element.CME_CertificationBoard.Name;
                listObject["id"] = element.Id;
                listObject["key"] = element.Id;
                listObject["recertificationDate"] = new Date(element.RecertificationDate).toDateString().substring(4);
                listObject["generalPercent"] = element.GeneralPercent;
                listObject["topicPercent"] = element.TopicPercent;
                listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();
                
                let getTopicObject = {
                  Frequency : 0,
                  RecertificationDate : element.RecertificationDate,
                  CertificationBoardId :  element.CME_CertificationBoard_Id.toLocaleLowerCase(),
                  Id : []
                }

                topicArray.push(getTopicObject);
              
                profilelistData.push(listObject);
              }
            setProfileListData(profilelistData);

        }
      }

      

      function callTopicRequirementData(topicArray:any,filteredList:any) {
        if(topicArray.length > 0 && topicRequirementListData.length === 0){
        apiUsers.url = "cmedetail/" + contactData?.contactid;
        apiUsers.post({
          topic : topicArray
    
        }).then((resp: any) => {
          let user = resp.rows;
    
          if (resp && resp.rows && resp.rows.length > 0) {
            //user = user[0];
            for (let index = 0; index < user.length; index++) {
              
    
                let elementDetail  =user[index];
                                  
                elementDetail.forEach ((topicElement: { CME_Topic_Id: any; TopicTotalHours: any; CME_CertificationBoard_Id:any}) => {

                  const listObject: any = {};  
                  // listObject["Id"] = topicElement.Id;
                  listObject["CME_Topic_Id"] = topicElement.CME_Topic_Id;
                  // listObject["Hours"] = topicElement.Hours;
                  listObject["TopicTotalHours"] = topicElement.TopicTotalHours;

                  listObject["CertificationBoardId"] = topicElement.CME_CertificationBoard_Id.toLowerCase();
                
                  //if(!checkUnique)
                  topicRequirementData.push(listObject);
               
              });                          
          }

          if(topicRequirementData.length > 0){
            let result = listData.map((item: { CME_Topic_Id: any; CompletedHours: any; CertificationBoardId :any;RemainingHours:any;maxhours:any}) => {
            let match =  topicRequirementData.find((x: { CME_Topic_Id: any; CertificationBoardId:any }) => x.CME_Topic_Id === item.CME_Topic_Id && x.CertificationBoardId === item.CertificationBoardId);
            if(match) {
              item.CompletedHours = match.TopicTotalHours;
              item.RemainingHours = item.maxhours- item.CompletedHours ;
            }
            else{
              item.RemainingHours = item.maxhours- item.CompletedHours;
            }
            return item;
          });
          setListData(result);
        }

        const uniqueRequirements = getUniqueRequirements(listData);
        setListData(uniqueRequirements as never[]);

          setTopicRequirementListData(topicRequirementData); 
          setRequirementLoaded(true);
        }
          
          if(promiseValue.promiseObject.length !== 1)
            promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetail'])
        });
      }
      else{
        if(promiseValue.promiseObject.length !== 1)
            promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetail'])
        setRequirementLoaded(true);
      }
      
      return topicRequirementData;
      }
      
      function redirect(){
        //This will check user session is active or not
        localStorage.setItem("loadSSOData","1")
    
        history.push("/dashboard/CMEProfiles")
      }

        // Function to filter and get the requirement with the highest RemainingHours for each TopicId
  const getUniqueRequirements = (listData: { CME_Topic_Id: any; RemainingHours: number; }[]) => {
    const uniqueRequirements: any[] = [];

    listData.forEach((requirement: { CME_Topic_Id: any; RemainingHours: number; }) => {
      const index = uniqueRequirements.findIndex((item) => item.CME_Topic_Id === requirement.CME_Topic_Id);

            if (index === -1) {
              uniqueRequirements.push(requirement);
            } else {
              // If a matching TopicId is found, compare RemainingHours and update if needed
              if (requirement.RemainingHours > uniqueRequirements[index].RemainingHours) {
                uniqueRequirements[index] = requirement;
              }
            }
    });

    return uniqueRequirements;
  };



    return(                  
      <div className="RequirementsCME">
             <Typography  variant="h2"  className="Naming">
                Progress
              </Typography>

            {requirementListData && requirementListData.length > 0 ?
            
            <div className="secondColumn box-style"> 
            {!requirementLoaded ? 
              <div className="dashboard-spinner">
              </div>
          :
          <>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {requirementListData.slice(0,5)
              //.sort((item:any,next:any)=> item.Topic_Title > next.Topic_Title ? 1 : -1,)
              .sort((item:any, next:any) => {
                // Compare based on RemainingHours in descending order
                const remainingHoursA = item.RemainingHours;
                const remainingHoursB = next.RemainingHours;
            
                if (remainingHoursA !== remainingHoursB) {
                  // Sort by RemainingHours in descending order
                  return remainingHoursB - remainingHoursA;
                } else {
                  // If RemainingHours are equal, sort by Topic_Title in alphabetical order
                  const topicTitleA = item.Topic_Title.toLowerCase();
                  const topicTitleB = next.Topic_Title.toLowerCase();
            
                  if (topicTitleA < topicTitleB) {
                    return -1;
                  } else if (topicTitleA > topicTitleB) {
                    return 1;
                  } else {
                    return 0; // Topics are equal
                  }
                }
              })
              .map((item: any) => (                               
                
                <div key={shortid.generate()}>
                   <ListItem key={shortid.generate() }  alignItems="flex-start">
                                <ListItemText  className="requir-auto-height"
                                  primary=
                                  {
                                  <React.Fragment>                                
                                  <>
                                    <Typography
                                      component="span"
                                      variant="h2"
                                      className="RequirementTitle">
                                    {item.Topic_Title}
                                    </Typography>
                                  
                                  {item.CompletedHours < item.maxhours ?
                                  <BorderLinearProgress
                                  variant="determinate"
                                  sx={{ height: "12px", borderRadius: "20px" }}
                                  value={Math.round(item.CompletedHours/ item.maxhours * 100)}                             
                                  />
                                  : 
                                  <SuccessBorderLinearProgress
                                                 variant="determinate"
                                                 //color="success"
                                                 sx={{
                                                   height: "12px",
                                                   borderRadius: "20px",
                                                   backgroundColor: "#00D16D"
                                                 }}
                                                 value={100}
                                                  />
                                  }
                                  </> 
                                  </React.Fragment>
                                  }
                                secondary ={
                                <React.Fragment>                                 
                                  <div className="HoursDiv">
                                    <Typography
                                      // sx={{ display: 'inline' }}
                                      component="span"
                                      //variant="h3"
                                      //color="text.primary"
                                      className="HoursComplete"
                                    >
                                      
                                      {item.CompletedHours}/{item.maxhours} Hrs Completed
                                    </Typography>

                                    <Typography
                                      // sx={{ display: 'inline' }}
                                      component="span"
                                      variant="h3"
                                      //color="text.primary"
                                      className="HoursRemaining"
                                    >
                                      
                                      {item.CompletedHours  < item.maxhours ? (item.maxhours - item.CompletedHours) === 1? '1 Hr Remaining' :item.maxhours - item.CompletedHours + ' Hrs Remaining' : 0 + ' Hrs Remaining' } 
                                      
                                    </Typography>
                                  </div>                                 
                                </React.Fragment>
                              }

                              secondaryTypographyProps = {
                                { component : 'div'}
                              }
                            />
                    </ListItem>
                </div>
                  
                  
                  ))}
                
                
                  </List>
                  <div className='Most-recent-box-bottom-right-button' style={{paddingTop:"2%"}}>
                    <Link href="/dashboard/InProgress" underline="hover"> View All </Link>
                        <ArrowForwardIcon />                                 
                    </div>                                   
                  </>
                  }
                </div>                      
                : 
                <div className="secondColumn EmptyDashboard">
                {!requirementLoaded ? 
              <div className="dashboard-spinner">
              </div>

              :<>
                <div className=''>
                            <div className='empty-text'> 
                                You do not have any topics in progress.
                            </div>                     
                            <div >
                            <Button                                        
                                style={{textTransform: 'none'}} variant="contained" className='explore-more-opp-div' onClick={redirect}>
                                <b> View My CME Profiles</b>
                            </Button> 
                            </div>
                            
                        </div> 
                  <div style={{color:"#DDDDDD"}} className='Most-recent-box-bottom-right-button' >
                        <Link underline="hover" style={{color:"#DDDDDD"}}> View All </Link>
                        <ArrowForwardIcon />                                 
                      </div>
                      </>
                }
                </div>
            }
                
        </div>
  );
};
