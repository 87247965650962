import * as React from "react";
import '../../styles/dashboard.css'
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { SearchContainer } from "./SearchContainer";

export const AdminDashboard = (props:any): JSX.Element =>{
  const [spinner, setSpinner] = useState(false);  
  const history = useHistory();

  React.useEffect(function() {
    props.onLoadPage(true);
  }, [])

    function handleContactClick(item:any) {
        history.push("/dashboard/UnclaimedCME?acepid=" + item.acepid, {contact: item, from: "AdminDashboard"});        
    }

  return(
    <>{spinner ?
          <div className="spinner" style={{position:"fixed"}}>
          </div>
          :
          null}
       <div  style={{opacity:spinner ? 0.5 : 1, pointerEvents: spinner ? 'none' : 'auto'}}>
                <div className="AdminDashboard-Heading">
                        CME Tracker (Admin)
                </div>
            <SearchContainer handleContactClick={handleContactClick} 
            showSearchResultAfterContactSelection={true}
            showSpinner={(toShow: boolean) => {
              setSpinner(toShow);
            }}
            onLoadPage={props.onLoadPage} showSearchEmptyResultImage={true} />
  
          </div></>  
    );    
}
