import React, { useContext } from 'react'
import '../styles/Contact-info.css'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { userContext } from '../context/userContext';
import {contactContext} from '../context/contactContext';
import { DefaultNoImageURL} from '../config/config';
import {promiseContext} from '../context/promiseContext';
import {UpdateUserContextOutside} from '../utils/cache'
import { useLocation } from 'react-router-dom';

 export const ContactInfo = (props:any): JSX.Element =>{

      //For Contact Information details component
      //fetch Contact info from Acep Contact Number

    const [contactDetails, setContactDetails] = React.useState<IContactDetails>({
        firstname:"",
        middlename:"",
        lastname:"",
        jobtitle:"",
        professionaldesignation:"",
        city:"",
        state:"",
        country:""
      })

   const [showData, setShowData] = React.useState(false);
   const [enableAdmin, setenableAdmin] = React.useState(false);

      let userObject = useContext(userContext);

      let userData = userObject.User;

      let contactObject  = useContext(contactContext);
   
      let contactData = contactObject.contactObject.Contact;

      const location = useLocation();

      const queryParams = new URLSearchParams(location.search);

       // Get a specific parameter value by its name
      const queryAcepId = queryParams.get('acepid');
   
      console.log( "Query acep id " + queryAcepId)
      let acepid = queryAcepId !== null ? queryAcepId : userData?.acepid;
     
      let contactAddressData: {
         country: string;
         state: string; city: string; 
      } = { country: "", state: "", city: "" };     
      
        
      const fetchDataAndUpdateUserContext = async () => {
            
           setenableAdmin(true);
       
           const apiData = (await UpdateUserContextOutside(acepid)) as IContactData;

           const { country,stateProvince, city } = apiData;

             contactAddressData.city = city;
             contactAddressData.state = stateProvince;
            contactAddressData.country = country;

            const contactInfo = {
               firstname: contactData?.firstname,
               middlename:contactData?.middlename,
               lastname:contactData?.lastname,
               jobtitle:contactData?.jobtitle,
               professionaldesignation:contactData?.professionaldesignation,
               city:contactAddressData.city,
               state: contactAddressData.state,
               country: contactAddressData.country
              }

            setContactDetails(contactInfo);
     
       };
   
  React.useEffect(function() {
    
    if(contactData?.contactid !=='')
    {   
      fetchDataAndUpdateUserContext() ;

      const contactInfo = {
              firstname: contactData?.firstname,
              middlename:contactData?.middlename,
              lastname:contactData?.lastname,
              jobtitle:contactData?.jobtitle,
              professionaldesignation:contactData?.professionaldesignation,
              city:userData?.city,
              state: userData?.state,
              country: userData?.country
             }
            setContactDetails(contactInfo)
 
            setShowData(true);
          }
       
   }, [userData?.acepid,contactData?.acepid, queryAcepId])

  
 
    return(
        <> 
            
        <img className='contact-eclipse-photo' 
        alt=""
        src={userData.acepMemberStatus === 2 ? `https://s3.amazonaws.com/AcepPortfolio/${contactData?.acepid}/avatar.png` : `${userData?.avatar}`}
        onError={(e) => {
         e.preventDefault();
         e.currentTarget.onerror = null;
         e.currentTarget.src = DefaultNoImageURL
         }}       
        >          
        </img> 
           
        { showData && <div className='contact-details'>
             {contactDetails.firstname}

             {contactDetails.middlename ? " " + contactDetails.middlename : ""}

             {" " + contactDetails.lastname}

             {contactDetails.jobtitle
                ? ", " + contactDetails.jobtitle
                : ""}

             {contactDetails.professionaldesignation
                ? ", " + contactDetails.professionaldesignation
                : ""}

            
             <span className='contact-details-span'>
                {(contactDetails.city?.length || 0) > 0 && (contactDetails.state?.length || 0) > 0 && (contactDetails.country?.length || 0) > 0 ?
                   <>
                      <LocationOnOutlinedIcon /> {contactDetails.city}, {contactDetails.state}, {contactDetails.country}
                   </>
                   :
                   (contactDetails.city?.length || 0) === 0 && (contactDetails.state?.length || 0) > 0 && (contactDetails.country?.length || 0) > 0 ?
                      <>
                         <LocationOnOutlinedIcon /> {contactDetails.state}, {contactDetails.country}
                      </>
                      :
                      (contactDetails.state?.length || 0) === 0 && (contactDetails.city?.length || 0) > 0 && (contactDetails.country?.length || 0) > 0 ?
                         <>
                            <LocationOnOutlinedIcon /> {contactDetails.city}, {contactDetails.country}
                         </>
                         :
                         (contactDetails.city?.length || 0) === 0 && (contactDetails.state?.length || 0) === 0 && (contactDetails.country?.length || 0) > 0 ?
                            <>
                               <LocationOnOutlinedIcon /> {contactDetails.country}
                            </>
                            :
                            ''
                            }
             </span>

            {/* <div className='switch-cme-tracker'>                 
                <Typography variant="subtitle1" style={{fontWeight:"600"}}>Try the New CME Tracker</Typography>
                  <Switch 
                     checked={checked}
                     onChange={handleChange}
                     inputProps={{ 'aria-label': 'controlled' }}
                  />
            </div> */}
          </div>
        }

      
      
    

        </>
    )
}