import React from 'react';
import jwt from 'jwt-decode' // import dependen
import axios from 'axios';
import API_URL,{ LOGIN_URL,CMEAuthorizatioinAPIURL, Token_Header_Key, Client_Username,Client_Password} from '../../config/config';

import UserContextProvider from '../../context/userContext';

export default function SSOACEP(props:any){
 
  const [loadPage, setLoadPage] = React.useState(false);  
  React.useEffect(() => {         
    setSSOCookies(true);
    
    setInterval(function(){ 
      const loadDataVar:string = localStorage.getItem("loadSSOData")||'0'
      if(loadDataVar === '1')
          setSSOCookies();
    }, 1000);
  },[]) 

  const checkSSOFromStorage = ( ) => {
    
      var date = new Date();
      var int_date_frame = Math.floor(date.getTime()/1000) ;
       
      const user_info:ITokenInfo = localStorage.getItem('USERINFO') ? jwt(String(localStorage.getItem('USERINFO'))) : {jti:'',
      sub:'',
      email:'',
      given_name:'',
      family_name:'',
      exp:0,
      iss:'',
      aud:''}

      var exp_time_session = Number(user_info.exp)|| 0
      if(int_date_frame >= exp_time_session){ 
                    
          setSSOCookies(); 
      }else{
        setLoadPage(true)  
      }    
  }
  
   //Firstly will try to set Token info
   //if user is un-authorzed it means it is not logged in
   //so then we can redirect url
   const setSSOCookies = (isPageRefreshedFromBrowser?:boolean|false) => {
    const loadDataVar:string = localStorage.getItem("loadSSOData")||'1'
    if(loadDataVar === '1' || isPageRefreshedFromBrowser)
    {
      localStorage.removeItem("cme_token_header_key")
      localStorage.removeItem("USERINFO")
      localStorage.removeItem("loadSSOData")
      //Here will can end point to get encrypted token fom https://devsignin.acep.org/spapi/api/Authorization
      var requestOptions:any = {
        method: 'GET',
        redirect: 'follow',
        withCredentials: true //This is needed for Cookies to be sent to the Api Call : MB 8/8/2022
      };
      
      axios.get(CMEAuthorizatioinAPIURL, requestOptions)   
        .then(result => {  
            localStorage.setItem("USERINFO",String(result.data)) 
            localStorage.setItem("loadSSOData","0")            
            setLoadPage(true)  
         })
        .catch(error =>  {  
          localStorage.setItem("loadSSOData","0")       
          window.location.href = LOGIN_URL+window.location.href
        });

      axios.post(API_URL+'/generateToken',{
        "username": Client_Username,
        "password": Client_Password 
      })
        .then((result : any)=>{
          localStorage.setItem(Token_Header_Key,String(result.data.cme_token)) 
        })
    }
    else
    {
      checkSSOFromStorage()
    } 
   }

    return (
        <div>
           { loadPage && <UserContextProvider />    }
        </div>
       
    )

}