import React,{useContext, useState,useLayoutEffect,useRef} from 'react'
import LeftNavMenu from "./common/Navbar";
import '../styles/Unclaimed-CME-Dashboard.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, Link, styled, Typography } from "@mui/material";
import shortid from  "shortid";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import apiUsers from "../api-operation";
import {contactContext} from '../context/contactContext';
import { BrowserRouter, useHistory } from 'react-router-dom';
import EmptyProgress from '../images/ActivityEmpty.png'
import { promiseContext } from '../context/promiseContext';
import {userContext} from "../context/userContext";
import {GetActivitiesDeletedByAdminData} from '../utils/cache';
import { ContactInfo } from './ContactInfo'; 


export function DeletedActivitiesByAdmin(props:any){
    const [DeletedActivitesList,setDeletedActivitesList] = useState<[IDeletedActivityDetails]|[]>([]);
    const [claimedLoaded, setclaimedLoaded] = useState(false);
    const [order, setOrder] = useState({ Date: 'asc', Name: 'asc' });   
    

    let contactObject  = useContext(contactContext);
    const history = useHistory();   
    let contactData = contactObject.contactObject.Contact; 

    

    const [isNavExpanded, setIsNavExpanded] = React.useState(false);   
       
    const [updateContact, setupdateContact] = useState(false);

    let trackingArray : any =[];

    let promiseValue = React.useContext(promiseContext) as PromiseType;
      //For Left Nav Height
      const ref = useRef<HTMLInputElement>(null);
      const [height, setHeight] = useState(0);
      const [loadNavHeight, setLoadNavHeight] = useState(false);
      
      let userObject = useContext(userContext);
      let userData = userObject.User;
      //check user is admin or not
    let isAdmin:boolean = userData.acepMemberStatus === 2 ? true : false;

      if(userData.acepMemberStatus === 2){
        contactData =contactObject.contactObject.Contact;
        if(!updateContact)
        setupdateContact(true);
      }
      else{
            contactData =contactObject.contactObject.Contact;
      }

      useLayoutEffect(()=>{
        
        
          var HomePagebackgroundHeight = document.body.getElementsByClassName('HomePagebackground')[0]; // Assuming there's only one element with class 'HomePagebackground'
          var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
          var newHeight = currentHeight + 120; 
          setHeight(newHeight);
          setLoadNavHeight(false)
        
      },[loadNavHeight])

      function getArrow(order: string) {
        if (order === 'asc') 
        // return <div className='tracking-sort-arrow'><ArrowDownwardIcon fontSize='small' htmlColor='#8B8B8B'/> </div>;
        // return <div className='tracking-sort-arrow'> <ArrowUpwardIcon fontSize='small' htmlColor='#8B8B8B'/> </div>;
        // if (order === 'asc') 
        return <span style={{cursor:'pointer', verticalAlign:'top',float:'right'}}><ArrowDownwardIcon fontSize='small' htmlColor='#8B8B8B'/> </span>;
        return <span style={{cursor:'pointer', verticalAlign:'top',float:'right'}}> <ArrowUpwardIcon fontSize='small' htmlColor='#8B8B8B'/> </span>;
    }

  
function handleClick(target :any) {
  if (target === 'Date') {
    if (order.Date === 'asc') {
      setOrder({
        Date: 'desc',
        Name: order.Name,
      });

      const sortedAscending = DeletedActivitesList.sort(
        (a, b) => new Date(a.DeletedByAdminDate).valueOf() - new Date(b.DeletedByAdminDate).valueOf()
      );

      setDeletedActivitesList(sortedAscending);
    } else {
      setOrder({
        Date: 'asc',
        Name: order.Name,
      });

      const sortedDescending = DeletedActivitesList.sort(
        (a, b) => new Date(b.DeletedByAdminDate).valueOf() - new Date(a.DeletedByAdminDate).valueOf()
      );

      setDeletedActivitesList(sortedDescending);
    }
  } else if (target === 'Name') {
    if (order.Name === 'asc') {
      setOrder({
        Date: order.Date,
        Name: 'desc',
      });

      const sortedAscending = DeletedActivitesList.sort(
        (a, b) => (a.DeletedActivityName > b.DeletedActivityName  ? 1 : -1)
      );

      setDeletedActivitesList(sortedAscending);
    } else {
      setOrder({
        Date: order.Date,
        Name: 'asc',
      });

      const sortedDescending = DeletedActivitesList.sort(
        (a, b) => (a.DeletedActivityName  < b.DeletedActivityName ? 1 : -1)
      );

      setDeletedActivitesList(sortedDescending);
    }
  }
}
    
    React.useEffect(() => {
      if(userData.acepMemberStatus !== 2 )
      {
        history.push("/")
      }
     if(contactData?.contactid !=='' && DeletedActivitesList.length === 0)
      {
        apiUsers.url ="cmetracking/deletedrecords/" + contactData?.contactid;
        apiUsers.getAll().then((data: any) => {
            var rows = data.deletedactivities?.rows ;

             
                       
            for (let index = 0; index < rows.length; index++) {
              const listObject: any = {};
              let element: IDeletedActivityDetails = rows[index];
              listObject["DeletedByAdminName"] = element.DeletedByAdminName;
              listObject["DeletedByAdminDate"] = element.DeletedByAdminDate;
              listObject["DeletedActivityName"] = element.DeletedActivityName;
              
          
              trackingArray.push(listObject);
            }

                setDeletedActivitesList(trackingArray);
                setclaimedLoaded(true);
                setLoadNavHeight(true)
        }) 
                
                              
     

       
      
    }
      },[]);
    
  return(
    <div ref={ref} className= {isAdmin ? 'UnclaimedDashboard HomePagebackground adminView' :'UnclaimedDashboard HomePagebackground'} style={{pointerEvents:props.progressStyles.pointer, opacity:props.progressStyles.opacity}}> 
        <div    className="HomePageWrapper ">
            <div style={{float:'left',width:'5%'}}>
                <LeftNavMenu heightDashboardPage={height} onCollapse={(isNavExpanded:any) => {
                    setIsNavExpanded(isNavExpanded);
                }} />
            </div>

            <div   className={isNavExpanded ? 'Right-Div-Dashboard-expanded overlay' : 'Right-Div-individual-Dashboard'}>

            {updateContact?  <div className='column-full'>                     
                    <div className="ContactInfo">              
                      <ContactInfo/>                          
                    </div>               
                  </div> : null}
                  
                <div  className="UncalimedDashboard-Heading">
                    <div className="div-left">
                     Deleted Records
                    </div> 
                    
                </div>

                <div >
                { DeletedActivitesList && DeletedActivitesList.length > 0?  
                    <div style={{top:'230px'}} >  
                       <>                                     
                       <div className='FourthRow box-style'>                     
                        <div  className='MostRecent-header-line'>                    
                            <div style={{float: "left",textAlign: "left"}} onClick={() => handleClick("Name")} className='box-inner-header'>Activity Name <> {getArrow(order.Name)}</></div> 
                            <div  style={{float: "right"}} className='box-inner-header ' onClick={() => handleClick("Date")}> Deletion Date<> {getArrow(order.Date)}</></div>
                            <div  style={{float: "right",paddingRight:'91px', textAlign:'center'}} className='box-inner-header'> Admin Name</div>
                        </div> 
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                { 
                                
                                DeletedActivitesList.map((item, index) => 
                                    {  
                                        return(
                                                <div key={shortid.generate()} className='dvMostRecentList'>
                                                    <ListItem key={shortid.generate()} alignItems="flex-start" sx={{paddingTop:"0px"  }}>
                                            
                                                        <ListItemText key={shortid.generate()}
                                                        primary=
                                                        {
                                                            <React.Fragment>                                                        

                                                            <Typography component="div"  style={{float:'left',width:'calc(95% - 400px)',marginLeft:'3%'}}  className="RequirementTitle box-inner-left-text " >
                                                            {item.DeletedActivityName}
                                                            </Typography>

                                                                <Typography component="div" style={{float:'right',width:'118px', paddingRight:'8px'}}  className="RequirementTitle deleted-record-date-right-padding box-inner-left-text unclaim-dateRow" >
                                                                {item.DeletedByAdminDate}
                                                                
                                                                </Typography>                                               

                                                                <Typography component="div" style={{float:'right',marginRight:'35px',textAlign:'center',width:'232px'}}   className="RequirementTitle deleted-record-adminname-right-margin box-inner-left-text " >
                                                                {item.DeletedByAdminName}
                                                                
                                                                </Typography>

                                                               
                                                            </React.Fragment>
                                                        }
                                                        
                                                      
                                                        />
                                                    </ListItem>
                                                </div>                         

                                                ) 
                                        })                   
                            }
                            </List>  
                       
                    </div>
                       </>
                  </div>  
                  :  null}

    {DeletedActivitesList && DeletedActivitesList.length === 0 ? (
        <div className="EmptyList">
            <>
                {props.spinner ? (
                    <div className="spinner"></div>
                ) : (
                    <>
                        <div className="empty-text">
                            This user does not have any deleted records.
                        </div>
                        <img src={EmptyProgress} alt="Loading" />
                    </>
                )}
            </>
        </div>
    ) : null}

                   
                    
                    
                
                </div>
            </div>

           
            
        </div>
    
    </div>
  )

}

export default function DeletedActivities() {
  const [spinner, setSpinner] = useState(true);
  const [progressStyles, setprogressStyles] = useState({opacity:0.5, pointer:'none'});

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if(!timeout){
    timeout = setTimeout(() => {
      setSpinner(false);
      const enableObject={
        opacity:1, 
        pointer:'auto'
      }
      setprogressStyles(enableObject);
    }, 2000);
    }

    return () => {
      // cancel the dashboard
      clearTimeout(timeout);
  };
  
},[])


  return(   
      <>     
      
      {spinner? 
      <div className="spinner">
      </div>
      :
      null
      }
      
      <DeletedActivitiesByAdmin
        progressStyles={progressStyles}
        spinner={spinner}
        showSpinner={(toShow: boolean) => {
          setSpinner(toShow);
        }}
      />  </>                                
    );    
}