export function handleResponse(response: {data: any; }) {
  
    if (response.data) {
      return response.data;
    }
  
    return response;
  }
  
  export function handleError(error: { data: any; }) {
    if (error.data) {
      return error.data;
    }
    return error;
  }