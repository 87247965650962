import {apiProvider} from './api-provider';

export class ApiCore {
  getAll!: () => any;
  getSingle!: (id: any) => any;
  post!: (model: any) => any;
  put!: (model: any) => any;
  patch!: (model: any) => any;
  remove!: (id: any) => any;
  url!: string;
  multiRemove!:(model: any) => any;
  
  constructor(options: { getAll: any; url: any; getSingle: any; post: any; put: any; patch: any; remove: any; multiRemove:any}) {
    if (options.getAll) {
      this.getAll = () => {
        return apiProvider.getAll(this.url);
      };
    }

    if (options.getSingle) {
      this.getSingle = (id) => {
        return apiProvider.getSingle(this.url, id);
      };
    }

    if (options.post) {
      this.post = (model) => {
        return apiProvider.post(this.url, model);
      };
    }

    if (options.put) {
      this.put = (model) => {
        return apiProvider.put(this.url, model);
      };
    }

    if (options.patch) {
      this.patch = (model) => {
        return apiProvider.patch(options.url, model);
      };
    }

    if (options.remove) {
      this.remove = (id) => {
        return apiProvider.remove(this.url, id);
      };
    }

    if (options.multiRemove) {
      this.multiRemove = (model) => {
        return apiProvider.multiRemove(this.url, model);
      };
    }
  }
}