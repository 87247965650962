import * as React from "react";
import { Typography,Link, Button } from "@mui/material";
import "../styles/dashboard.css";
import "../styles/profile.css";
import "../styles/Unclaimed-CME-Dashboard.css";
import apiUsers from "../api-operation";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BrowserRouter, useHistory } from "react-router-dom";
// Import react-circular-progressbar module and styles
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useContext, useState } from "react";
import { contactContext } from "../context/contactContext";
import GetFormattedDate from '../utils/utils';
import { promiseContext } from "../context/promiseContext";
import {GetProfileData,GetRequirementData,GetGeneralRequirementData} from '../utils/cache';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; 
dayjs.extend(utc); 

export const CMEProfile = (props:any): JSX.Element => {
  const [profileListData, setListData] = React.useState([]);
  const [generalRequirementListData, setGeneralRequirementListData] = React.useState([]);
  const [topicRequirementListData, setTopicRequirementListData] = React.useState([]);
  const [requirementListData, setRequirementListData] = React.useState([]);
  const [circularProgressColor, setCircularProgressColor] = React.useState('0084FF');
  const [profileLoaded, setprofileLoaded] = useState(false);
  const listData: any = [];
  const history = useHistory();
  const generalRequirementData: any = [];
  let topicRequirementData :any =[];
  const requirementData: any = [];
  let topicArray : any =[];
  

  let contactObject  = useContext(contactContext);
   
  let contactData = contactObject.contactObject.Contact;
  let promiseValue= React.useContext(promiseContext) as PromiseType;

  const {data : profileAPIData, isLoading :profileAPICalled}  = GetProfileData(contactData?.contactid);
  const {data : requirementAPIData,isLoading :requirementAPICalled}  = GetRequirementData(contactData?.contactid);
  const {data : generalRequirementAPIData, isLoading :generalAPICalled}  = GetGeneralRequirementData(contactData?.contactid);

  
  React.useEffect(() => {
    if(contactData?.contactid !=='' && profileListData.length===0  && profileAPIData && !profileAPICalled)
    // &&!requirementAPICalled && requirementAPIData)
    {
    // apiUsers.url = "cmeprofiles?contactid=" + contactData?.contactid;
    // apiUsers.getAll().then((resp: any) => {
    //   let user = resp.cmeprofiles?.rows;
    var rows  = profileAPIData; 

      //if (rows.length > 0) {
        for (let index = 0; index < rows.length; index++) {
          const listObject: any = {};
          let element: IProfileListData = rows[index];
          listObject["title"] = element.CME_CertificationBoard.Name;
          listObject["id"] = element.Id;
          listObject["key"] = element.Id;
          listObject["recertificationDate"] = new Date(element.RecertificationDate).toDateString().substring(4);
          listObject["generalPercent"] = element.GeneralPercent;
          listObject["topicPercent"] = element.TopicPercent;
          listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();

          let getTopicObject = {
            Frequency : 0,
            RecertificationDate : element.RecertificationDate,
            CertificationBoardId :  element.CME_CertificationBoard_Id.toLocaleLowerCase(),
            Id : []
          }

          topicArray.push(getTopicObject);

          listData.push(listObject);
        }
        setListData(listData);  
          
        callRequirementData(); 
        callGeneralRequirementData();
    
    props.onLoadPage(true);
  }
  
  }, [generalAPICalled]);

  function callGeneralRequirementData() {
    if(generalRequirementListData.length=== 0 && generalRequirementAPIData && !generalAPICalled){
    var rows  = generalRequirementAPIData; 
      // if (rows.length > 0) {
        for (let index = 0; index < rows.length; index++) {
          const listObject: any = {};
          let element: IGeneralRequirementListData = rows[index];
          listObject["recertificationDate"] = GetFormattedDate(element.RecertificationDate);
          listObject["frequency"] = element.Frequency;
          listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLowerCase();
          listObject["TotalClaimedHours"] = element.TotalClaimedHours;
          listObject["Hours"] = element.Hours;
          listObject["ShortTitle"] = element.Title;
          listObject["Title"] = element.Name;
          
          if(element.Hours === 0)
            setCircularProgressColor('#00D16D')
          
          generalRequirementData.push(listObject);
        }
        setGeneralRequirementListData(generalRequirementData);
        props.onLoadPage(true);
  }
  }

  function callRequirementData() {
    if(requirementListData.length === 0 && requirementAPIData && !requirementAPICalled){
    var rows  = requirementAPIData; 

      // if (rows.length > 0) {
        for (let index = 0; index < rows.length; index++) {
          const listObject: any = {};
          let element: IListData = rows[index];
          listObject["title"] = element.Notes;
          listObject["statute"] = element.Statute;
          listObject["id"] = element.Id;
          listObject["description"] = element.Description;
          listObject["frequency"] = element.Frequency;
          listObject["interval"] = element.Interval;
          listObject["maxhours"] = element.CMEHours  ;
          listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();
          listObject["CME_Topic_Id"] = element.CME_Topic_Id
          listObject["Topic_Title"] = element.CME_Topic.Name
          listObject["CompletedHours"] = 0;
                      
          if(element.CME_Topic_Id !== 1)
          {                                  
                           
                requirementData.push(listObject);

                topicArray.forEach((topic: {
                  Id:[number] ;
                  Frequency: any; CertificationBoardId: string; }) => {
                  if(topic.CertificationBoardId === element.CME_CertificationBoard_Id.toLocaleLowerCase()){
                    topic.Frequency = element.Frequency;                 
                    topic.Id.push(element.CME_Topic_Id);
                  }
                });  
          } 
        }

        setRequirementListData(requirementData);


        
        callTopicRequirementData(topicArray,requirementData);
        props.onLoadPage(true);

  }
  }

 

  function callTopicRequirementData(topicArray:any,filterdList:any) {
    if(topicArray.length > 0 && topicRequirementListData.length === 0){
    apiUsers.url = "cmedetail/" + contactData?.contactid;
    apiUsers.post({
      topic : topicArray

    }).then((resp: any) => {
      let user = resp.rows;

      if (resp && resp.rows && resp.rows.length > 0) {
        for (let index = 0; index < user.length; index++) {
          let elementDetail  =user[index];                       
          elementDetail.forEach ((topicElement: { CME_Topic_Id: any; TopicTotalHours: any; }) => {
            const listObject: any = {};  
            listObject["CME_Topic_Id"] = topicElement.CME_Topic_Id;
            listObject["TopicTotalHours"] = topicElement.TopicTotalHours;    
            topicRequirementData.push(listObject);
         
        });         
    }

    if(topicRequirementData.length > 0){
      let result = filterdList.map((item: { CME_Topic_Id: any; CompletedHours: any; }) => {
      let match =  topicRequirementData.find((x: { CME_Topic_Id: any; }) => x.CME_Topic_Id === item.CME_Topic_Id);
      if(match) {
        item.CompletedHours = match.TopicTotalHours;
      }
      return item;
    });


    setRequirementListData(result);
    
  } 

      setTopicRequirementListData(topicRequirementData);  
      setprofileLoaded(true);
      props.onLoadPage(true);
    }     
      if(promiseValue.promiseObject.length !== 10)
        promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetaillist'])
    });
  }
  else{
    if(promiseValue.promiseObject.length !== 10)
        promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetaillist'])
        setprofileLoaded(true);
  }
  props.onLoadPage(true);
  }

  function redirect(){
    //This will check user session is active or not
    localStorage.setItem("loadSSOData","1")

    history.push("/dashboard/CMEProfiles")
  }

  const calculateTopicPercentage = (item : any) => {
    let filterdList = requirementListData.filter((value:any) => {
      return value.CertificationBoardId === item.CertificationBoardId && value.CME_Topic_Id !== 1 });
          
    let hoursId = 0;  
    let maxHours = 0;
    let topicPercentage = 0;

      if(filterdList.length > 0 )
      {
        filterdList.forEach((requirement: {
          maxhours: number;CompletedHours: number; }) => {
              maxHours = requirement.maxhours + maxHours;                       
              hoursId = requirement.CompletedHours + hoursId;
        });  
        topicPercentage = Math.round(hoursId/ maxHours * 100); 
        if(topicPercentage > 100)    
          topicPercentage = 100;   
        if(Number.isNaN(topicPercentage))
         topicPercentage = 0;         
     }  
                                   
 return topicPercentage;
}

  return (
    <div className='ProfileCME'>
      <Typography  variant="h2"  className="Naming">
                        CME Profiles
                    </Typography>

      {profileListData && profileListData.length > 0 ?    
      <div className="thirdColumn">
        <>
        {!profileLoaded ? 
              <div className="dashboard-spinner">
              </div>
          :

        <><div className="listWrapper">
        {generalRequirementListData.map((item: any,index:any) => (
              <>
                <div key={index} className='profile-box profile-box-width'>
                  <div className="listContent">
                    
                    {item.Hours === 0 || item.TotalClaimedHours >= item.Hours ? 
                    <><div className="profile-box-circle-width">

                    <CircularProgressbarWithChildren
                      value= {100}
                      strokeWidth={8}
                      styles={buildStyles({
                                  
                        pathColor: "#00D16D",
                        textColor: "#00D16D",
                        // trailColor: "transparent"
                      })}
                    > 

                    <div style={{ width: "75%" }}>
                        
                    <CircularProgressbar
                      value={calculateTopicPercentage(item)}
                      styles={buildStyles({
                        pathColor: "#9D48C6"
                        // trailColor: "transparent"
                      })} />    
                                           
                    </div>
                                                     
                  </CircularProgressbarWithChildren>
                  </div>
                  <div className="containerProfile">
                      <Typography
                        component="div"
                        variant="h2"
                        className="RequirementTitle"
                      >
                        {item.Title}
                      </Typography>
                      <div className="ProfileDetails">
                        <div className="Detail1" >
                          General
                          <p style={{color:'#00D16D'}}> {100 }% </p>
                        </div>
                     
                        <div className="Detail2" >
                          Topic
                          
                          {calculateTopicPercentage(item) > 0 ? <p style={{color:'#9D48C6'}}> {calculateTopicPercentage(item)}%</p>
                          :
                          <p> {calculateTopicPercentage(item)}%</p> }
                        </div>
                        
                        {dayjs(item.recertificationDate).utc().startOf('day').
                              isAfter(dayjs(new Date()).utc().startOf('day')) 
                              || 
                              dayjs(item.recertificationDate).utc().startOf('day').
                              isSame(dayjs(new Date()).utc().startOf('day')) 
                              
                         ? <div className="Detail3">
                        License Expiration Date
                          <p> {dayjs(item.recertificationDate).startOf('day').format('MM/DD/YYYY')} </p>
                        </div> : <div className="DetailR">
                        License Expiration Date
                          <p> {dayjs(item.recertificationDate).startOf('day').format('MM/DD/YYYY')} </p>
                        </div>}

                      </div>
                    </div></>

                    : 
                    <><div className="profile-box-circle-width">

                    <CircularProgressbarWithChildren
                      value= {item.TotalClaimedHours ?  item.TotalClaimedHours/ item.Hours * 100 : 0}
                      strokeWidth={8}
                      styles={buildStyles({
                        pathColor: "#0084FF",
                        // trailColor: "transparent"
                      })}
                    >
                    
                                    
                      <div style={{ width: "75%" }}>                    
                        <CircularProgressbar
                          value={calculateTopicPercentage(item)}
                          styles={buildStyles({
                            pathColor: "#9D48C6"
                            // trailColor: "transparent"
                          })} />                                       
                      </div>
                    </CircularProgressbarWithChildren>

                  </div>
                  <div className="containerProfile">
                      <Typography
                        component="div"
                        variant="h2"
                        className="RequirementTitle"
                      >
                        {item.Title}
                      </Typography>
                      <div className="ProfileDetails">
                        <div className="Detail1" >
                          General
                          { Math.round(item.TotalClaimedHours/ item.Hours * 100) > 0 ? <p style={{color:'#0084FF'}}> { Math.round(item.TotalClaimedHours/ item.Hours * 100) }% </p>
                          : <p> { Math.round(item.TotalClaimedHours/ item.Hours * 100) }% </p>} 
                          
                        </div>
                        

                        <div className="Detail2" >
                          Topic
                          {calculateTopicPercentage(item) > 0 ? <p style={{color:'#9D48C6'}}> {calculateTopicPercentage(item)}%</p>
                          :
                          <p> {calculateTopicPercentage(item)}%</p> }
                        </div>
                        
                        {dayjs(item.recertificationDate).utc().startOf('day').
                              isAfter(dayjs(new Date()).utc().startOf('day')) 
                              || 
                              dayjs(item.recertificationDate).utc().startOf('day').
                              isSame(dayjs(new Date()).utc().startOf('day')) 
                         ? <div className="Detail3">
                        License Expiration Date
                          <p> {dayjs(item.recertificationDate).startOf('day').format('MM/DD/YYYY')} </p>
                        </div> : <div className="DetailR">
                        License Expiration Date
                          <p> {dayjs(item.recertificationDate).startOf('day').format('MM/DD/YYYY')} </p>
                        </div>}

                      </div>
                    </div></>
                    }
                  </div>
                  {/* <div className="Seperator"></div> */}
                </div>

                {( (profileListData.length > 3) && ((profileListData.length === index + 1) || ((index + 1) % 3 === 0))) &&
                  <div className="border-div-profile"></div>}

              </>
            ))}
          
          </div>
          <div className='Most-recent-box-bottom-right-button'>
              <BrowserRouter>
                {/* <Button color="inherit" to={"/dashboard/CMEProfiles"} component={Link} onClick={redirect}>View Details </Button> */}
                <Link href="/dashboard/CMEProfiles" underline="hover">View Details </Link>
              </BrowserRouter>

              <ArrowForwardIcon />
            </div></>
        }
        </>
      </div>
      : 
      <div className="thirdColumn EmptyProfile">
      {!profileLoaded ? 
              <div className="dashboard-spinner">
              </div>
          :
          <>
      <div className="" >
                                  <div className='empty-text'> 
                                     You do not have any CME profiles.
                                  </div>
                                  
                                  <div>                                 
                                  <Button style={{textTransform: 'none'}} variant="contained" className='explore-more-opp-div' onClick={redirect}>
                                   <b> View My CME Profiles</b>
                                  </Button> 
                                  </div>
                                  
      </div>
      <div style={{color:"#DDDDDD"}} className='Most-recent-box-bottom-right-button'>
                                    <BrowserRouter>
                                      {/* <Button color="inherit" to={"/dashboard/CMEProfiles"} component={Link} onClick={redirect}>View Details </Button> */}
                                      <Link underline="hover" style={{color:"#DDDDDD"}}>View Details </Link>
                                    </BrowserRouter>
                                    <ArrowForwardIcon />
                                </div>
                                </>
      }
      </div>
      
      }
    </div>    
  );
};