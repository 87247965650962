import { ApiCore } from "./api-core";

const apiOperation = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  remove: true,
  multiRemove : true,
  url: "",
});

export default apiOperation;