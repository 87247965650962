import React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'; 
import ListIcon from "@mui/icons-material/List";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
const Trigger = ({ triggerText, buttonRef, showModal,customBtnRef,disableReportButtons,disableRequirement }) => {
  return (

    < >
        {
          triggerText == 'MobileUser' 
          ?            
          <Button
              ref={customBtnRef}
              onClick={showModal}              
            >           
          </Button>        
          :
          triggerText == 'Certificates' 
          ?            
          <Button
              ref={customBtnRef}
              onClick={showModal}              
            >           
          </Button>        
          :
          triggerText == 'Delete Profile' || triggerText == 'Delete Requirer'
          ? 
          <span style={{color:'#E44040'}}>
          <Button
          startIcon={<DeleteIcon />}
            style={{textTransform: 'none',marginTop:'3px'}}
            variant='text' color='error'  
              className="btn btn-lg btn-danger center modal-button profile-remove-button-hover"
              ref={buttonRef}
              onClick={showModal}
              
            >
           <span className='DeleteActivitiyLabel'>{triggerText}</span> 
          </Button>
        </span>
        :
        triggerText == 'Delete CMEActivity'
        ? 
        <span >
        <Button
        startIcon={<DeleteIcon />}
          style={{textTransform: 'none',verticalAlign:'top', padding:'0px'}}
          variant='text' color='error'  
            className="btn btn-lg center modal-button profile-remove-button-hover"
            ref={buttonRef}
            onClick={showModal}
            
          >
         
        </Button>
      </span>
          :triggerText == 'Delete Activity'
          ? 
          <div style={{color:'#E44040'}}>
          <Button startIcon={<DeleteIcon />}
            style={{textTransform: 'none',fontStyle:'normal',fontWeight:'600',fontSize:'16.8px',lineHeight:'140%',textAlign:'right'}}
            variant='text' color='error'  
            className="btn btn-lg btn-danger center modal-button profile-remove-button-hover"
            ref={buttonRef}
            onClick={showModal}              
            >
           <span>{triggerText}</span> 
          </Button>
        </div>
          :
          triggerText == 'Add Profile'
          ?
          <Button 
            style={{textTransform: 'none', backgroundColor:'#1976D2', width:'152px', height:'37.2px'}}
            variant='contained' 
              className="btn btn-lg btn-danger center modal-button"
              ref={buttonRef}
              onClick={showModal}
              
            >
           Add <span className='addProfileButton' style={{paddingLeft:'3px'}}> Profile</span>  
          </Button>
            :  triggerText == 'Add Requirer'
            ?
            <Button  startIcon={<AddIcon /> }
              style={{textTransform: 'none'}}
              variant='contained' 
                className="btn btn-lg btn-danger center modal-button"
                ref={buttonRef}
                onClick={showModal}
                
              >
              {triggerText}
            </Button>
          :
          triggerText == 'Edit Profile'
          ?
          <Button  startIcon={<DriveFileRenameOutlineIcon /> }
            style={{textTransform: 'none',color:'black'}}
            variant='text' 
              className="btn btn-lg btn-danger center modal-button"
              ref={buttonRef}
              onClick={showModal}              
            >
           
          </Button>

          : triggerText == 'Delete Topic' 
          ? 
          <span style={{color:'red'}}>
          <i                   
            variant='text' style={{color:'white', backgroundColor: 'transparent !important'}}  
              className="btn btn-lg center modal-button"
              ref={buttonRef}
              onClick={showModal}             
            >   
            <DeleteIcon />        
          </i>
        </span>
        : triggerText == 'Filters'
        ?
        <div>
          <Button variant="outlined"
          ref={buttonRef}
          onClick={showModal}  
                      
          className='cancel-transcript-button filters_activity_button_div'>
              <b> Filters</b>                                                
          </Button>
        </div>
        :triggerText == 'Requirements'?
          <span  >
          <Button
          
            style={{textTransform: 'none'}}
            variant='text'  
              className="btn btn-lg center  modal-requirements-button"
              onClick={showModal} 
              
             
            >
            {triggerText}
          </Button>
        </span>
        :triggerText == 'Delete Blob'?
        <span> 
          <Button
          ref={buttonRef}
          variant="outlined" 
          className='close-button' 
          startIcon={<CloseIcon />}
          onClick={showModal}>           
          </Button>
      </span>
        :triggerText === 'MergeCME' ?  
        <Button 
        style={{textTransform: 'none', backgroundColor:'#1976D2'}}
        variant='contained' 
          className="btn btn-lg btn-danger center modal-button"
          ref={buttonRef}
          onClick={showModal}
          
        >
         Merge All CME Activities
      </Button>
        :<span  >
          
        <Button
        
          style={{backgroundColor:'transparent',textTransform: 'none'}}
          variant='text'  
            className={disableRequirement ? "btn btn-lg center  modal-requirements-button profile-no-requirement" : "btn btn-lg center  modal-requirements-button"}
            ref={buttonRef}
            onClick={showModal}
            
          >
          {triggerText}
        </Button>
      </span>
        }
         

        
    </ >

  );
};
export default Trigger;
