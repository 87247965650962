import { Button, InputAdornment, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useEffect, useState } from "react";
import apiUsers from "../../../src/api-operation";
import InfiniteScroll from "react-infinite-scroll-component";
import AdminSearch from '../../../src/images/CMEAdmin.jpg'
import { useHistory } from "react-router-dom";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React from "react";

interface SearchContainerProps {
    handleContactClick: (item: any) => void;
    showSearchResultAfterContactSelection: boolean;
    showSpinner: (toShow: boolean) => void;
    onLoadPage: (toLoad: boolean) => void;
    showSearchEmptyResultImage?: boolean;
    selectedContactRemoved?: (toRemove: boolean) => void;
    selectedFirstContact?: any;
    disableInitialEvents?: boolean;
    selectedFirstContactText?: string;
    searchText?: string;
    clearSearchText?: () => void;
    
  }

export const SearchContainer = (props: SearchContainerProps) : JSX.Element => {
    console.log(props)
        const [searchText, setSearchText] = useState('');
        const [searchButton, setSearchButton] = useState(true);
        const [searchList, setSearchList] = useState<any[]|[]>([]);
        const [page, setPage] = useState(1);
        const [showErrorMsgForNoRecordFound, setShowErrorMsgForNoRecordFound] = useState(false);
        const [spinner, setSpinner] = useState(false);
        const [spinnerStyles, setSpinnerStyles] = useState({opacity:1, pointer:'auto'});
        const [prevSearchText, setPrevSearchText] = useState('');
        const [loaded, setLoaded] = useState(true);
        const [order, setOrder] = useState('asc');         
        const history = useHistory();       
         
      //clear search text on clear icon click
      const clearSearchText = () => {
        setSearchText('');
        setPrevSearchText('');
        setSearchButton(true);
        setSearchList([]);
       props.onLoadPage(true); 
        setPage(1);
        setShowErrorMsgForNoRecordFound(false)
       if(props.selectedContactRemoved) 
            props.selectedContactRemoved(true)
    }

    const handleSearchButton = (searchValue:string) => {
        setSearchText(searchValue);
        setShowErrorMsgForNoRecordFound(false)
        if(searchValue.length > 3){
            setSearchButton(false)
        }
        else{
            setSearchButton(true)
            
        }
    }
    
    //handle search button click 
    //this will call the api to get the search results
    async function handleSearch() {
        
        if(searchText.length > 3){
            props.showSpinner(true);
            setSpinnerStyles({opacity:0.5, pointer:'none'})
            setSearchButton(true);
            
            if (searchText !== prevSearchText)
            {  
                setSearchList([]); // Clear search list if searchText has changed          
                setPage(1);// Set new results if searchText has changed
            } 
            //axios.post(searchURL,  {"SearchText": searchText, "PageNumber": searchText !== prevSearchText ? 1 : page})
            apiUsers.url ="cmecontactsfilter";
            await apiUsers.post({
                "SearchText": searchText,
                "PageNumber": searchText !== prevSearchText ? 1 : page
              })   
            .then((resp : any )=> {  
                let userList :any = resp?.contacts;   
                
                if(props.selectedFirstContact && props.selectedFirstContact.acepid !== 0)
                        userList = userList?.filter((user: any) => user.acepid !== props.selectedFirstContact.acepid);

                if(userList?.length === 0){
                    setLoaded(false);
                    setShowErrorMsgForNoRecordFound(true)
                }
                else{
                    setLoaded(true);
                    setShowErrorMsgForNoRecordFound(false)
                }

                setSearchList(prevSearchList => [...prevSearchList, ...userList]);
                setPage(prevPage => prevPage + 1); // Append new results to existing search list
                setPrevSearchText(searchText);         
                
                 
                props.showSpinner(false);
                setSearchButton(false);
                //this will adjust the heigh on dashboard
                 props.onLoadPage(true); 
            
                setSpinnerStyles({opacity:1, pointer:'auto'})
            })
            
       }
    }

    ///handle search button click
    //
    function handleClick() {
        if (order === 'asc') {
          setOrder('desc');
          const sortedAscending = searchList.sort((a, b) => {
            const firstNameA = a.FirstName || '';
            const firstNameB = b.FirstName || '';
      
            const firstNameComparison = firstNameA.localeCompare(firstNameB);
            if (firstNameComparison !== 0) {
              return firstNameComparison;
            }
      
            return a.LastName.localeCompare(b.LastName);
          });
      
          setSearchList(sortedAscending);
        } else {
          setOrder('asc');
          const sortedDescending = searchList.sort((a, b) => {
            const firstNameA = a.FirstName || '';
            const firstNameB = b.FirstName || '';
      
            const firstNameComparison = firstNameB.localeCompare(firstNameA);
            if (firstNameComparison !== 0) {
              return firstNameComparison;
            }
      
            return b.LastName.localeCompare(a.LastName);
          });
      
          setSearchList(sortedDescending);
        }
    }
   

///get arrow icon based on order
    function getArrow(order: string) {
        if (order === 'asc') 
        return <div className='tracking-sort-arrow'><ArrowDownwardIcon fontSize='small' htmlColor='#8B8B8B'/> </div>;
        return <div className='tracking-sort-arrow'> <ArrowUpwardIcon fontSize='small' htmlColor='#8B8B8B'/> </div>;
    }


  return (

    <>
        <div >
                <div className="search-container">
                <div className='SearchBar-User' style={props.disableInitialEvents ? {pointerEvents : 'none',opacity:'0.5'} : {}}>

                    <TextField
                        size="small"
                        className='search-box'
                        value={props.searchText ? props.searchText :  searchText}
                        helperText={false}
                        label='Search user by first name, last name and ACEP ID'
                        InputProps={{
                            classes: {
                                focused: "search-inside-div"
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <ClearIcon onClick={() => props.clearSearchText ? props.clearSearchText() : clearSearchText()} style={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => handleSearchButton(e.target.value)}
                        //fullWidth= {true}
                        sx={{width:"100%", borderRadius: "50px" }} />
                </div>

                <div  >
                    <Button
                        style={{ textTransform: 'none',backgroundColor: searchButton ? 'lightblue' : '#1976D2', width: '140px', height: '40px', 
                            pointerEvents: searchButton ? 'none' : 'auto' }}
                        variant='contained'
                        className="btn btn-lg btn-danger center modal-button"
                        //ref={buttonRef}
                        onClick={() => handleSearch()}
                        //   disabled={searchButton}

                    >
                        <span className='addProfileButton' style={{ paddingLeft: '3px' }}> Search</span>
                    </Button>
                </div>
                </div>
                <>
                {searchList && searchList.length > 0 ?
                    props.showSearchResultAfterContactSelection && 
                    <div
                        className={'Unclaimed-dashboard-secondColumn box-style searchlist_top_margin'} style={{ marginTop: "30px" }}>
                        <div className='Unclaimed-Dashboard-header-line'>

                            <div className='box-inner-header admin-acepid-column'>
                                ACEP ID
                            </div>

                            <div className={'box-inner-header admin-usernameColumn'} onClick={() => handleClick()}>
                                User Name {getArrow(order)}
                            </div>
                        </div>
                        <InfiniteScroll
                            dataLength={searchList.length}
                            next={handleSearch}
                            hasMore={loaded} // Set to false when there is no more data
                            loader={null} // Loading indicator while fetching data
                            endMessage={null} // Message to display when all data is loaded
                            >
                            {searchList.map((item, index) => {
                                return (
                                <div key={index} className={'dvMostRecentList searchlist_main_div'} onClick={(event) => props.handleContactClick(item)}>
                                    <ListItem key={index} className='lstUnclaimed' alignItems="flex-start" sx={{ width: '100%' }}>
                                    <ListItemText
                                        key={index}
                                        className='padding_0_percent'
                                        primary={
                                        <React.Fragment>
                                            <Typography component="div" className="unclaim-dateRow">
                                            {item.acepid}
                                            </Typography>
                                            <Typography component="div" className="adminuser-eventRow">
                                            {item.firstname} {item.lastname}
                                            {item.jobtitle ? ', ' + item.jobtitle : ''} {item.professionaldesignation ? ', ' + item.professionaldesignation : ''}
                                            </Typography>
                                        </React.Fragment>
                                        }
                                    />
                                    </ListItem>
                                </div>
                                );
                            })}
                            </InfiniteScroll>

                    </div>
                    :                     
                      props.showSearchEmptyResultImage &&   searchList.length === 0 &&                     
                        <div className='EmptyUserList'>
                            <div className='empty-text'>
                                { showErrorMsgForNoRecordFound ? 
                                <>
                                <WarningAmberIcon className="warningAmberIcon"  />
                                User not found, please try again.
                                </>  
                                :  'Which user are you looking for?' }

                            </div>
                           
                            <div>
                                <img src={AdminSearch} alt="Admin User Search" width="471px" height="330"></img>
                            </div>
                            
                        </div>
                    } 
                      {  showErrorMsgForNoRecordFound && searchList.length === 0 ?
                        
                        <div className="empty-text"> 
                            <WarningAmberIcon className="warningAmberIcon"  />
                            User not found, please try again.
                       </div>
                        : null
                      }
                  {  props.selectedFirstContactText && props.selectedFirstContactText.length > 0 && props.selectedFirstContactText === props.searchText ?
                        
                        <div className="empty-text"> 
                            <WarningAmberIcon className="warningAmberIcon"  />
                            Please select another contact.
                        </div>
                        : null
                      }

                
                </>
        </div>
    </> 
  );
}