import * as React from 'react';
import { ChangeEvent, memo } from 'react';
 import { InputAdornment, TextField } from '@mui/material';
 import SearchIcon from '@mui/icons-material/Search';

const FilterLiveSearch : React.FC<FilterLiveSearchProps>  = ({onSearchChange}) => {
    return (
                <TextField 
                size="small"
                    helperText={false}
                    label='Search by First Name, Last Name, ACEP ID, Email'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon color="disabled" />
                            </InputAdornment>
                        ),
                    }}
                   onChange={onSearchChange}
                   fullWidth= {true}
                   sx={{width:"350%" }}
                />
    );
};

export default memo(FilterLiveSearch);
