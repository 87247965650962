import React,{useContext, useState} from 'react'
import '../styles/Unclaimed-CME-Hours.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography,Link, Button } from "@mui/material"; 
import {OpportunitiesLink} from '../config/config'
import { BrowserRouter } from "react-router-dom";
import shortid from  "shortid";
import {contactContext} from '../context/contactContext';
import '../styles/profile.css';
import {GetUnclaimedFilteredData} from '../utils/cache';

export const UnclaimedCMEHours = (props : any) : JSX.Element =>{
 
    const [cmeTrackingDetails,setCMETrackingDetails] = useState<[ICMETrackingDetails]|[]>([]);
    const [unclaimedLoaded, setunclaimedLoaded] = useState(false);
    
    const contactObject  = useContext(contactContext);
  
    let contactData = contactObject.contactObject.Contact;

    const {data : filteredData, isLoading:unclaimedAPICalled}  = GetUnclaimedFilteredData(contactData?.contactid);

    let trackingArray : any =[];

    function redirectEducation(){
        window.open(OpportunitiesLink, "_blank");
    }

    React.useEffect(function() {
        if(contactData?.contactid !=='' && cmeTrackingDetails.length=== 0  && filteredData && !unclaimedAPICalled){
                var rows  = filteredData; 
                  
                for (let index = 0; index < rows.length; index++) {
                  const listObject: any = {};
                  let element: ICMETrackingDetails = rows[index];
                  listObject["id"] = element.Id;
                  listObject["key"] = element.Id;
                  listObject["Name"] = element.Name;
                  listObject["Details"] = element.Details;
                  listObject["MaxHours"] = element.MaxHours;
                  listObject["ClaimedHours"] = element.ClaimedHours;
                  listObject["RemainingHoursToClaim"] = element.RemainingHoursToClaim
              
                  trackingArray.push(listObject);
                }
                
                setCMETrackingDetails(trackingArray);
                setunclaimedLoaded(true);
 
        }
    }, [unclaimedAPICalled])

    return(
       
        <div className='UnclaimedCME' style={{flexBasis: props.nonmember === 1 ? '100%' : '50%'}} >
             { 
                <>
                                    
                    <Typography  variant="h2"  className="Naming">
                        Unclaimed CME Hours
                    </Typography>
                                     
                    
                    {cmeTrackingDetails && cmeTrackingDetails.length > 0 ? 
                    <> 
                    {!unclaimedLoaded ? 
                        <div className="dashboard-spinner">
                        </div>
                        :
                    <div className='secondColumn box-style'>                     
                        <div  className={props.nonmember === 1 ? 'Unclaimed-header-line-nonmember':'Unclaimed-header-line'}>
                            <div style={{float:"left"}} className='box-inner-header'> Event Name</div> 
                            <div style={{float:"right"}} className='box-inner-header'> Max Hours to Claim</div>
                        </div> 
                        <List sx={{ width: '100%', bgcolor: 'background.paper'  }}>
                                { 
                                
                                cmeTrackingDetails.map((item, index) => 
                                    {  
                                        return(
                                            <div key={shortid.generate()}>
                                                <ListItem key={shortid.generate()} alignItems="flex-start" sx={{paddingTop:"0px"  }}>
                                        
                                                    <ListItemText key={shortid.generate()}
                                                    primary=
                                                    {
                                                        <React.Fragment>
                                                            <Typography component="div"  
                                                            sx={{
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: "2",
                                                                WebkitBoxOrient: "vertical",
                                                                width:props.nonmember === 1 ? '84%' : '70%', 
                                                                paddingLeft:props.nonmember === 1 ? '2%' :'57px'
                                                            }}
                                                            className={props.nonmember === 1 ? "box-inner-left-text":"RequirementTitle box-inner-left-text"} >
                                                            {item.Name + ( item.Details ? ' - ' + item.Details : '' )}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span" variant="h3"   
                                                                className={props.nonmember === 1 ? "box-inner-right-text HoursRemainingNonmember":"box-inner-right-text HoursRemaining"}>
                                                                {item.ClaimedHours === null ? item.MaxHours : item.RemainingHoursToClaim } 
                                                            </Typography>                           

                                                        </React.Fragment>
                                                    }
                                                    />
                                                </ListItem>
                                            </div>                         

                                                ) 
                                        })                   
                            }
                            </List>    
                        <div className='Most-recent-box-bottom-right-button'  >
                                <BrowserRouter>
                                {/* <Button to={"/dashboard/UnclaimedCME"} component={Link} onClick={redirect}>Claim CME</Button> */}

                                <Link href="/dashboard/UnclaimedCME" underline="hover"> Claim CME </Link>
                                </BrowserRouter>
                                <ArrowForwardIcon />                                

                                </div>
                    </div>}
                    </>
                    : 
                    <div className='secondColumn EmptyDashboard  '> 
                        {!unclaimedLoaded ? 
                        <div className="dashboard-spinner">
                        </div>
                        :
                        <>
                        <div  style={{width: props.nonmember === 1 ? '1200px !important' : '502px !important'}} className=''>
                                    <div className='empty-text'> 
                                        You do not have any unclaimed CME hours.
                                    </div>
                                    
                                    <div >
                                    <Button                                        
                                        style={{textTransform: 'none'}} variant="contained" className='explore-more-opp-div' onClick={redirectEducation}>
                                        <b> Explore Opportunities</b>
                                    </Button>                                  
                                    </div>
                                                                    
                                </div>
                                <div style={{color:"#DDDDDD"}} className= 'Most-recent-box-bottom-right-button'>
                                        <BrowserRouter>                                 
                                        <Link underline="hover" style={{color:"#DDDDDD"}}> Claim CME </Link>
                                        </BrowserRouter>
                                        <ArrowForwardIcon />                                
                                    </div> 
                        </>
                        }
                    </div>
                    } 
                    
                     
                       
                </>
            }
        </div>

    )
}

