import { SearchContainer } from "./admin/SearchContainer"
import {userContext} from "../context/userContext";
import React,{useContext, useEffect, useLayoutEffect, useState} from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; 
import LeftNavMenu from "./common/Navbar"; 
import WarningAmberIcon from '@mui/icons-material/WarningAmber' 
import {contactContext} from '../context/contactContext'; 
import apiProfiles from "../api-operation";
import Container from "../components/CMEProfileManagement/ModalContainer";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

export const ref = React.createRef<HTMLDivElement>();

export function MergeCMEByAdmin(props:any){
    interface contactObject {
        contactid: number;
        firstname: string;
        middlename: string;
        lastname: string;
        jobtitle: string;  
        professionaldesignation: string;       
        acepid: number | string;
    }
    const queryClient = useQueryClient();

    const history = useHistory(); 
    const emptyContact: contactObject = { contactid: 0, firstname: '', middlename: '', lastname: '', jobtitle: '',professionaldesignation:'', acepid: 0 };    
    
    //for contacts selection
    const [selectedFirstContact, setSelectedFirstContact] = useState<contactObject>(emptyContact);
    const [selectedSecondContact, setSelectedSecondContact] = useState<contactObject>(emptyContact);

    //props to main component for first and second contacts
    //based on this will hide and show the search results
    const [showSearchResultAfterFirstContactSelection, setShowSearchResultAfterFirstContactSelection] = useState(true);
    const [showSearchResultAfterSecondContactSelection, setShowSearchResultAfterSecondContactSelection] = useState(true);

    //This will be set to true after contacts are merged
    const [contactsMerged, setContactsMerged] = useState(false);

    //for adjusting the left nav height if something is added or removed
    const [height, setHeight] = useState(0);
    const [loadNavHeight, setLoadNavHeight] = useState(false);

    //this is to force re-render when first contact is removed
    const [uniqueKey, setUniqueKey] = useState(0); // Add state for unique key  
  

    //get user data
    let userObject = useContext(userContext);
    let userData = userObject.User;

    //get selected contact by admin
    const { contactObject } = useContext(contactContext);
    const selected_contact_acepid = contactObject.Contact?.acepid || '';

    //check user is admin or not
    let isAdmin:boolean = userData.acepMemberStatus === 2 ? true : false;    
    const [isNavExpanded, setIsNavExpanded] = React.useState(false);

    useEffect(() => {
        if(userData.acepMemberStatus !== 2 || true )
          {
            history.push("/")
          }
      }
      , []);


    useLayoutEffect(() => {
        var HomePagebackgroundHeight =
          document.body.getElementsByClassName("HomePagebackground")[0]; // Assuming there's only one element with class 'HomePagebackground'
        var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
        var newHeight = currentHeight + 120;
        setHeight(newHeight);
        setLoadNavHeight(false);
      }, [loadNavHeight]);

      function handleFirstContactClick(item:contactObject) {        
        setSelectedFirstContact(item);   
        setShowSearchResultAfterFirstContactSelection(false)     
        setLoadNavHeight(true); 
          
    }

    function handleSecondContactClick(item: contactObject) {        
      setSelectedSecondContact(item); 
     setShowSearchResultAfterSecondContactSelection(false)  
      setLoadNavHeight(true);   
          
    }
     
    function returnContactName(contactObject:contactObject){
      //handle empty or undefined properties below
      //like last name coulb be null or undefined or empty

      if(!contactObject){
        return '';
      } 

     else if(contactObject.acepid)
        return contactObject.firstname + ' ' + (contactObject.lastname ? contactObject.lastname : '') +
      (contactObject.professionaldesignation ? ', '+contactObject.professionaldesignation: '') 
      + (contactObject.jobtitle ? ', '+contactObject.jobtitle: '') 
      + (' (' + contactObject.acepid + ') ' );
      else return ''
    }

  const mergeCMEActivities = async (event:any) => { 
   
    props.showSpinner(true);
    const enableObject={
      opacity:0.5, 
      pointer:'none'
    }
  props.setprogressStyles(enableObject);

  event.preventDefault();
  event && event.currentTarget && event.currentTarget.getElementsByClassName("model-cancel-button")[0].click()

  //API call for move self reorted blobs
  apiProfiles.url = "/moveBlobForMergeCME/";
  let requestOptionsForMoveBlob = {
    src_contactnumber : selectedFirstContact.acepid,
    src_cotact_id : selectedFirstContact.contactid,
    target_contactnumber : selectedSecondContact.acepid
  };
 await apiProfiles.post(requestOptionsForMoveBlob)
 
  //API call for merge contacts
  apiProfiles.url = "/cmetracking/mergecontacts";
    let requestOptions = {
      src_contactnumber : selectedFirstContact.acepid,
      target_contactnumber : selectedSecondContact.acepid
    };
    apiProfiles.post(requestOptions) 
    .then((data:any) => {     

        setSelectedFirstContact(emptyContact);
        setSelectedSecondContact(emptyContact);
        setUniqueKey(uniqueKey + 1); // Update unique key to force re-render when first contact is removed
        props.showSpinner(false);
        setContactsMerged(true);

      }).catch((error:any) => {
        console.log(error);
        props.showSpinner(false);
        props.setprogressStyles({opacity:1, pointer:'auto'});
      });
      
  
    
     //In case admin proxied the Target contact then remove all the queries so it will re-fetch the data and will sow
     //the merged claimed and unclamed records on the dashboard
     if(requestOptions.target_contactnumber === selected_contact_acepid || requestOptions.src_contactnumber === selected_contact_acepid)
        removeQueriesForAdmin();
  }

  function removeQueriesForAdmin() {
    queryClient.removeQueries({ queryKey: ["unclaimedCMEFilterData"] });
    queryClient.removeQueries({ queryKey: ["claimedCMEFilterData"] });
    queryClient.removeQueries({ queryKey: ["claimedCMEData"] });
    queryClient.removeQueries({ queryKey: ["unclaimedCMEData"] });
    queryClient.removeQueries({ queryKey: ["generalRequirementData"] });
    queryClient.removeQueries({ queryKey: ["requirementData"] });
    queryClient.removeQueries({ queryKey: ["profileData"] });
    
  }

    return (
      <>
         <div  key={uniqueKey}   className= {isAdmin ? 'UnclaimedDashboard HomePagebackground adminView' :'UnclaimedDashboard HomePagebackground'} 
         
        > 
            <div    className="HomePageWrapper ">
                <div style={{float:'left',width:'5%'}}>
                    <LeftNavMenu member={1} heightDashboardPage={height}  />
                </div>                

                <div   className={isNavExpanded ? 'Right-Div-Dashboard-expanded overlay' : 'Right-Div-individual-Dashboard'}>   
                    <div className="AdminDashboard-Heading">
                        Merge CME Activities (Admin)
                    </div>                   

                  {  !contactsMerged ? 
                    <div >
                            <div className="firstContact">                      
                              <div className="mergeContactInstruction">
                              Merge from Contact
                              </div>
                              <SearchContainer  onLoadPage={(onload:boolean)=>{  setLoadNavHeight(true)}} 
                                                searchText = {returnContactName(selectedFirstContact)}
                                                showSearchResultAfterContactSelection={showSearchResultAfterFirstContactSelection}
                                                handleContactClick={handleFirstContactClick}
                                                showSpinner={(toShow: boolean) => {
                                                  props.showSpinner(toShow);
                                                }}
                                                selectedContactRemoved={(contactRemoved:boolean)=>{ 
                                                  //once removed set the selected contact to empty
                                                  setSelectedFirstContact(emptyContact);

                                                  //if first contact is removed then set below props to true so search results will be shown
                                                  setShowSearchResultAfterFirstContactSelection(true);

                                                  //force re-render when first contact is removed
                                                  setSelectedSecondContact(emptyContact);                                         
                                                    setUniqueKey(uniqueKey + 1); // Update unique key to force re-render when first contact is removed
                                                  }
                                                }
                                                />
                            </div>
                            <div  
                            className="SecondContact">                       
                              <div className="mergeContactInstruction">
                              Merge to Contact
                              </div>
                              <SearchContainer  onLoadPage={(onload:boolean)=>{ setLoadNavHeight(true)}} 
                                              disableInitialEvents={selectedFirstContact && selectedFirstContact.acepid !== 0 ? false : true}
                                              selectedFirstContact={selectedFirstContact}
                                                selectedFirstContactText={returnContactName(selectedFirstContact)}
                                                searchText = {returnContactName(selectedSecondContact)}
                                                showSearchResultAfterContactSelection={selectedSecondContact.acepid ===0 ? true : showSearchResultAfterSecondContactSelection}
                                                handleContactClick={handleSecondContactClick}
                                                showSpinner={(toShow: boolean) => {
                                                  props.showSpinner(toShow);
                                                }}
                                                selectedContactRemoved={(contactRemoved:boolean)=>{ 
                                                  //once removed set the selected contact to empty
                                                    setSelectedSecondContact(emptyContact);

                                                    //if second contact is removed then set below props to true so search results will be shown
                                                    setShowSearchResultAfterSecondContactSelection(true);
                                                  
                                                  }
                                                  
                                                }
                                                />
                            </div>
                          { selectedFirstContact && selectedFirstContact.acepid !== 0 && selectedSecondContact && 
                              selectedSecondContact.acepid !== 0 && selectedFirstContact.acepid !== selectedSecondContact.acepid &&
                          <>
                              <div className="selectedContactsHeader">
                              <WarningAmberIcon className="warningAmberIcon"  /> All CME activities for <span style={{fontWeight:'700'}}>{returnContactName(selectedFirstContact)}</span> 
                              will be merge to <span style={{fontWeight:'700'}}>{returnContactName(selectedSecondContact)}</span>
                                </div> 
                              <div className="mainSelectedContactPanel">
                                    <div style={{textAlign:'right',width:'100%',paddingTop:'50px'}}>              
                                          {/* <Button                                                         
                                              variant="contained" 
                                              onClick={(ev) => { mergeCMEActivities(ev)} }
                                              className='add-transcript-button text_transform_none model-submit-button-border'>
                                              <b style={{color:'white' }}>                                           
                                                  Merge All CME Activities
                                              </b>
                                          </Button>  */}
                                          <Container
                                          modalFor="MergeCME"
                                          triggerText="MergeCME"
                                          modalHeaderText="Confirm Merge"
                                          
                                          onSubmit={(ev:any) => { mergeCMEActivities(ev)}}
                                          // closeModal={props.closeModal}
                                        />
                                    </div>
                                </div>
                            </>
                          }                  
                      </div>                 
                  :
                  <>
                   <div style={{ display: 'flex', alignItems: 'center' }} className="megedContactMessage">
                     <CheckCircleIcon style={{color:'green', height:'40px', width:'40px'}}/>  
                     <span style={{ marginLeft: '8px', lineHeight: '21px' }}>All CME activities successfully  merged.</span>
                    </div>

                    <div className="mainSelectedContactPanel">
                                    <div style={{textAlign:'right',width:'100%',paddingTop:'50px'}}>              
                                          <Button                                                         
                                              variant="contained" 
                                              onClick={(ev) => { setUniqueKey(uniqueKey + 1); setContactsMerged(false);}}
                                              className='add-transcript-button text_transform_none model-submit-button-border'>
                                              <b style={{color:'white' }}>                                           
                                                  Merge More Activities
                                              </b>
                                          </Button> 
                                          </div>
                                </div>
                  </>
                  }
                </div>
               
            </div>
        </div>
        
      </>
    )
   
}

export default function MergeCME(props:any) {
    const [spinner, setSpinner] = useState(false);
    const [progressStyles, setprogressStyles] = useState({opacity:0.5, pointer:'none'});
  
 
  
  
    return(   
        <>          
          <div className={spinner ? "spinner": ''}>   </div>
          <div  style={{opacity:spinner ? 0.5 : 1, pointerEvents: spinner ? 'none' : 'auto'}}>
            <MergeCMEByAdmin
              setprogressStyles={setprogressStyles}
              spinner={spinner}
              showSpinner={(toShow: boolean) => {
              setSpinner(toShow);
            }}
            /> 
          </div>
         </>                                
      );    
  }