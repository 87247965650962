// require("dotenv").config();

//Here urls will be differernt for dev, test and prod
//SPA(dev) https://devsignin.acep.org/
//SPA(test) https://testsignin.acep.org/
//SPA(prod) https://signin.acep.org/

//local
// const API_URL = 'http://localhost:4000/v1/api'
// export const CMEDashboardURL = 'http://localhost:3000/';

//dev
// const API_URL  = 'https://acep-scus-dev-cme-api.azurewebsites.net/v1/api';
// export const CMEDashboardURL = 'https://acep-scus-dev-cme-ui.azurewebsites.net/';

//test
// const API_URL  =  'https://cmetrackerapisit.acep.org/v1/api'; 
// export const CMEDashboardURL = 'https://cmetrackersit.acep.org/';

//prod
//const API_URL = 'https://cme-api-acep.azurewebsites.net/v1/api';
//export const CMEDashboardURL = 'https://cmemanagement-acep.azurewebsites.net/';

const API_URL = process.env.REACT_APP_API_URL || '';
export const CMEDashboardURL = process.env.REACT_APP_CME_DASHBOARD_URL || '';

//based on this variable, dev, test or prod, we will handle the subsequence URL
export const env = process.env.REACT_APP_ENVIRONMENT;

export const ssoEnvironment = process.env.REACT_APP_SSO_ENVIRONMENT;

//here, we will also add/handle the URL based on the Organizatins like ACEP or SEMPA or EMRA
export const LOGIN_URL = 'https://' + env + 'signin.acep.org/spapi/Saml/InitiateSingleSignOn?returnurl='
export const LOGOUT_URL = 'https://' + env + 'signin.acep.org/spapi/Saml/InitiateSingleLogout?returnurl='

export const CMEAuthorizatioinAPIURL = 'https://' + env + 'signin.acep.org/spapi/api/Authorization'
export const CMEGetContactDetailsAPIURL = 'https://' + env + 'ws.acep.org/aceptoolkit/api/2015/contactdetailsbyid?acepid='


export const CMEAccountURL = 'https://' + env + 'webapps.acep.org/membership/dashboard/';
export const CMETrackerURL = 'http://' + env + 'webapps.acep.org/cmetracker/Default.aspx';
export const CMELogoURL = 'https://www.acep.org/globalassets/sites/acep/media/global/acep-logo3x.png';
export const AcepHeaderLogo_RedirectURL = "https://www.acep.org/"
export const MyCME_RedirectURL = "https://cmetracker.acep.org"
export const DefaultNoImageURL = "https://s3.amazonaws.com/user-portraits/none.jpg"
export const AuthorizationCredentials = "Basic Y21lbWFuYWdlbWVudDpQRXh3R0xSdw=="

export const JoinRenewURL = "http://" + env + "webapps.acep.org/memberapplication"

export const HeaderEclipsePhotoURL = "https://s3.amazonaws.com/AcepPortfolio/"

export const OpportunitiesLink = 'https://www.acep.org/education/cme/';

//Report URL's
export const ReportURL = 'https://' + env + 'ws.acep.org/AcepToolkit/api/2022/report'

export const ChatBotURL = 'https://www.acep.org/Static/Common/js/external/liveperson.js'
export const CorsURL = "https://cors-anywhere.herokuapp.com/";
export const Token_Header_Key = 'cme_token_header_key';
export const Client_Username = process.env.REACT_APP_CLIENT_USERNAME;
export const Client_Password = process.env.REACT_APP_CLIENT_PASSWORD;
export const MaxLength_FileName = 100;
export default API_URL;

