import React from 'react'
import { Container } from "./CMEProfileManagement/ModalContainer";
  
  export default function DeleteProfile(props:any){    
    
   const triggerText = "Delete Profile";  
   const modalHeaderText = "Are you sure?";
   const modalForText = "Delete"; 



  return (
    <Container 
    modalFor={modalForText}
    triggerText={triggerText} 
    modalHeaderText = {modalHeaderText}
    onSubmit={props.onSubmit} 
    onKeyPress={props.onKeyPress}
    />
  )
  }