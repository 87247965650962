export default function GetFormattedDate(dateTime: string | number | Date){
    let inputDate = new Date(dateTime);

    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    // let day = inputDate.getDate();
    // var month = months[inputDate.getMonth()];
    // let year = inputDate.getFullYear().toString();

    const day = inputDate.getUTCDate();  // Use getUTCDate() to get the day in UTC
    const month = months[inputDate.getUTCMonth()];  // Use getUTCMonth() to get the month in UTC
    const year = inputDate.getUTCFullYear().toString();  // Use getUTCFullYear() to get the year in UTC
    
    let dateFormatted = `${month} ${day}, ${year}`; 

    return dateFormatted;
}
export function GetRoundUpValue(event:any)
{
    event.preventDefault();
    const iClaimCMEHours = event.target.value;
    let roundUpValue: string ;
    var step = 0.25;
    var inv = 1.0 / step;
    roundUpValue = (Math.round(Number(iClaimCMEHours) * inv) / inv).toString();
          
    return roundUpValue;
}
