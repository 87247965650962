import React, { useEffect, useContext, useLayoutEffect, useState, useRef } from "react";
import '../../styles/profile.css';
import '../../styles/Unclaimed-CME-Dashboard.css';
import apiProfiles from "../../api-operation";
import apiUsers from "../../api-operation";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Container } from "../CMEProfileManagement/ModalContainer";
import Button from '@mui/material/Button';
import Alert from '../common/Alert'
import LeftNavMenu from "../common/Navbar";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import EmptyProfile from '../../images/ProfileEmpty.png'
import { v4 as uuid } from 'uuid';
import GetFormattedDate from '../../utils/utils';
import ErrorIcon from '@mui/icons-material/Error';
import MyDatePicker from '../common/MyDatePicker';

import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import InfiniteScroll from "react-infinite-scroll-component";
 
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  linearProgressClasses,
  styled,
  Link,
  Snackbar,
  Stack,
  InputLabel, 
  TextField
} from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddNewProfile from "../AddNewProfile";
import DeleteProfile from "../DeleteProfile";
import { contactContext } from "../../context/contactContext";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { promiseContext } from "../../context/promiseContext";
import {userContext} from "../../context/userContext";
import {useHistory} from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import {GetProfileData,GetRequirementData,GetGeneralRequirementData,GetProfileCategoryData,GetCertificationBoardData} from '../../utils/cache';

import { ContactInfo } from "../ContactInfo";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; 
dayjs.extend(utc); 
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#9D48C6",
  },
}));

const SuccessBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00D16D",
  },
}));

let msg = "";
let profileCategory = [
  {
    Name: "",
    Id: "",
  }
];

let certBoardCategory = [
  {
    Name: "",
    Id: "",
  }
];

const ProfileListProgress = (profileStyles:any): JSX.Element => {
  
  const [openAlertMsg, setOpenAlertMsg] = React.useState(false);
  const [profileListData, setListData] = React.useState<any[]|[]>([]);
  const [progressProfileListData, setProgressProfileListData] = useState<any[]>([
    { /* Your first object properties */ },
    { /* Your second object properties */ }
  ]);
  const listData: any = [];
  const categorylistData: any = [];
  const listGeneralData: any = [];
  const [requirementListData, setRequirementListData] = React.useState([]);
  const [topicLoaded, setTopicLoaded] = React.useState(false);
  const [requirementGeneralListData, setRequirementGeneralListData] = React.useState([]);
  const [generalRequirementListData, setGeneralRequirementListData] = React.useState([]);
  const [topicRequirementListData, setTopicRequirementListData] = React.useState([]);
  const requirementData: any = [];
  let topicArray : any =[];
  const generalRequirementData: any = [];
  let topicRequirementData :any =[];
  const [loadData, setLoadData] = React.useState(true);
  const [updateCurSelecteLicRenDate, setUpdateCurSelecteLicRenDate] = React.useState('');
  const [circularProgressColor, setCircularProgressColor] = React.useState('0084FF');
  const [stateOrSpecialtyBoard, setStateOrSpecialtyBoard] = React.useState(
    [{
        Name: "",
        Id: "",
        CME_ProfileCategory_Id:""
      }]
    ); 
  const [stateOrSpecialtyBoardFiltered, setStateOrSpecialtyBoardFiltered] = React.useState(certBoardCategory); 
  let contactObject  = useContext(contactContext);
   
    let contactData = contactObject.contactObject.Contact;
    let promiseValue= React.useContext(promiseContext) as PromiseType;
    const history = useHistory(); 
      //For Left Nav Height
      const ref = useRef<HTMLInputElement>(null);
      const [height, setHeight] = useState(0);
      const [loadNavHeight, setLoadNavHeight] = useState(false);
      const [showRequiredMessage, setShowRequiredMessage] = useState<boolean>(false); 
      const [updateContact, setupdateContact] = useState(false);
      const [page, setPage] = useState(1);
      const [loaded, setLoaded] = useState(true);
      const [showSpinner, setSpinner] = useState(false);

      let userObject = useContext(userContext);
      let userData = userObject.User;
      //check user is admin or not
      let isAdmin:boolean = userData.acepMemberStatus === 2 ? true : false;


      if(userData.acepMemberStatus === 2){
        contactData =contactObject.contactObject.Contact;
        if(!updateContact)
        setupdateContact(true);
      }
      else{
            contactData =contactObject.contactObject.Contact;
      }

      // Get QueryClient from the context
      const queryClient = useQueryClient();

      const {data : profileAPIData,isLoading: profileAPICalled}  = GetProfileData(contactData?.contactid,2);
      const {data : requirementAPIData,isLoading: requirementAPICalled}  = GetRequirementData(contactData?.contactid);
      const {data : generalRequirementAPIData,isLoading: generalAPICalled}  = GetGeneralRequirementData(contactData?.contactid);
      const {data : categoryAPIData,isLoading: categoryAPICalled}  = GetProfileCategoryData();
      const {data : certificationBoardAPIData,isLoading: certificationAPICalled}  = GetCertificationBoardData();
  

      useLayoutEffect(()=>{
      
          var HomePagebackgroundHeight = document.body.getElementsByClassName('HomePagebackground')[0]; // Assuming there's only one element with class 'HomePagebackground'
          var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
          var newHeight = currentHeight + 120; 
          setHeight(newHeight);
          setLoadNavHeight(false)
         
      },[loadNavHeight])
      
 

  useEffect(() => {


    if( (userData.acepMemberStatus === 0 && userData.sempaMemberStatus !== 15 ) || (userData.acepMemberStatus === 3 || userData.acepMemberStatus === 4 || userData.acepMemberStatus === 7) )
      {
        history.push("/")
      }
      else{
    GetModalData();
    GetStateOrSpecialtyBoard(); 
    callProfileData();
    callRequirementData();
    callGeneralRequirementData();
      }

  }, [generalAPICalled,categoryAPICalled,certificationAPICalled,requirementAPICalled]);

  function callRequirementData() {
    if(requirementAPIData && !requirementAPICalled)
    {
    // apiProfiles.url = "cmerequirementsbycontact/" + contactData?.contactid;
    // apiProfiles.getAll().then((resp: any) => {
    //   let user = resp.cmerequirements?.rows;
    var rows  = requirementAPIData; 
      //if (rows.length > 0) {
        for (let index = 0; index < rows.length; index++) {
          const listObject: any = {};
          let element: IListData = rows[index];
          listObject["title"] = element.Notes;
          listObject["statute"] = element.Statute;
          listObject["id"] = element.Id;
          listObject["description"] = element.Description;
          listObject["frequency"] = element.Frequency;
          listObject["interval"] = element.Interval;
          listObject["maxhours"] = element.CMEHours  ;
          listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();
          listObject["CME_Topic_Id"] = element.CME_Topic_Id
          listObject["Topic_Title"] = element.CME_Topic.Name
          listObject["CompletedHours"] = 0;
          listObject["RemainingHours"] = 0;

        if(element.CME_Topic_Id !== 1)
              {                                                            
              requirementData.push(listObject);

                topicArray.forEach((topic: {
                  Id:[number] ;
                  Frequency: any; CertificationBoardId: string; }) => {
                  if(topic.CertificationBoardId === element.CME_CertificationBoard_Id.toLocaleLowerCase()){
                    topic.Frequency = element.Frequency;                 
                    topic.Id.push(element.CME_Topic_Id);
                  }
                });  
              }
        else
        {
          if(index === (rows.length - 1) && requirementData.length === 0)
            profileStyles.showSpinner(false);

            listGeneralData.push(listObject);
        } 
        }

        topicArray = topicArray.filter((topic:any) => {
          return topic.Frequency !== 0})

        
        setRequirementListData(requirementData);
        setRequirementGeneralListData(listGeneralData);
        
        
                
        if(topicArray.length > 0 ){
          setTopicLoaded(true);
          callTopicRequirementData(topicArray,requirementData);
        }
      
      else{
        
        if(promiseValue.promiseObject.length !== 4)
        promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetail'])
      }
    
   }    
  }

  function handleTopicProgress(topicArray:any, requirementData:any)
  {
    //check topic id from requirementData and topic array and update the topic array with Frequency and recertificaiton date
    requirementData.forEach((requirement: {
      CME_Topic_Id: number; CertificationBoardId: string; frequency:number }) => {
      topicArray.forEach((topic: {
        Id:[number] ;
        Frequency: any; CertificationBoardId: string; }) => {
          if(topic.CertificationBoardId === requirement.CertificationBoardId.toLocaleLowerCase()){
            topic.Frequency = requirement.frequency;                 
            topic.Id.push(requirement.CME_Topic_Id);
          }
      });
    });
    topicArray = topicArray.filter((topic:any) => {
      return topic.Frequency !== 0})

      if(topicArray.length > 0 ){
        setTopicLoaded(true);
        callTopicRequirementData(topicArray,requirementData);
      }
  }

  function callGeneralRequirementData() {
    if(generalRequirementAPIData && !generalAPICalled){
    var rows  = generalRequirementAPIData; 

        for (let index = 0; index < rows.length; index++) {
          const listObject: any = {};
          let element: IGeneralRequirementListData = rows[index];
          listObject["recertificationDate"] = element.RecertificationDate;
          listObject["frequency"] = element.Frequency;
          listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLowerCase();
          listObject["TotalClaimedHours"] = element.TotalClaimedHours;
          listObject["Hours"] = element.Hours;
          listObject["ShortTitle"] = element.Title;
          
          if(element.Hours === 0)
            setCircularProgressColor('#00D16D')
          
          generalRequirementData.push(listObject);
        }
        setGeneralRequirementListData(generalRequirementData);    
    }
  }

  function callTopicRequirementData(topicArray:any, requirementData:any) {
    apiUsers.url = "cmedetail/" + contactData?.contactid;
    apiUsers.post({
      topic : topicArray

    }).then((resp: any) => {
      let user = resp?.rows;

       //To hide the wait indicator
      profileStyles.showSpinner(false)
      if (resp?.rows?.length > 0) {
        //user = user[0];
        for (let index = 0; index < user.length; index++) {

                
          let elementDetail  =user[index];
                            
          elementDetail.forEach ((topicElement: { CME_Topic_Id: any; TopicTotalHours: any; CME_CertificationBoard_Id :any}) => {
            const listObject: any = {};  
            
            listObject["CME_Topic_Id"] = topicElement.CME_Topic_Id;
            
            listObject["TopicTotalHours"] = topicElement.TopicTotalHours;

            listObject["CertificationBoardId"] = topicElement.CME_CertificationBoard_Id.toLowerCase();
          
            topicRequirementData.push(listObject);
         
        });
        
            
    }
    if(topicRequirementData.length > 0){
      let result = requirementData.map((item: { CME_Topic_Id: any; CompletedHours: any; CertificationBoardId :any, maxhours:any,RemainingHours:any}) => {
      let match =  topicRequirementData.find((x: { CME_Topic_Id: any; CertificationBoardId:any }) => x.CME_Topic_Id === item.CME_Topic_Id && x.CertificationBoardId === item.CertificationBoardId);
      if(match) {
        item.CompletedHours = match.TopicTotalHours;
        item.RemainingHours = item.maxhours- item.CompletedHours ;
      }
      else{
        // item.CompletedHours = 0;
        item.RemainingHours = item.maxhours- item.CompletedHours;
      }
      return item;
    });
    setRequirementListData(result);
  }       
        setTopicRequirementListData(topicRequirementData);
      }  
      if(promiseValue.promiseObject.length !== 4)
        promiseValue.setPromiseObject((prev: any) => [...prev, 'cmedetail'])    
    });
  
  }

  async function handleGetProfile() {
        setSpinner(true);
        apiUsers.url ="cmeprofiles?contactid="+contactData?.contactid+ "&limit=2&page=" + page;
        await apiUsers.getAll()
        .then((resp : any )=> {  
            let rows :any = resp?.cmeprofiles?.rows;                         

            if(rows?.length === 0){
              setLoaded(false);
              setSpinner(false);
              //setShowErrorMsgForNoRecordFound(true)
          }
          else{
              setLoaded(true);
              //setShowErrorMsgForNoRecordFound(false)
          }

            if(rows && rows.length > 0){
              for (let index = 0; index < rows.length; index++) {
                const listObject: any = {};
                let element: IProfileListData = rows[index];
                listObject["Id"] = element.Id;
                listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();
                listObject["name"] = element.CME_CertificationBoard.Name;
                listObject["recertificationDate"] = element.RecertificationDate;          
                listObject["recertificationDateFormatted"] = element.RecertificationDateFormatted;
                listObject["fullrecertificationDate"] = (element.RecertificationDate);
        
                if(element.CME_CertificationBoard.Website !== null)
                {
                if(element.CME_CertificationBoard.Website.toLowerCase().includes("http://"))
                {
                  listObject["requirerWebsite"] = element.CME_CertificationBoard.Website;
                }
                else{
                  listObject["requirerWebsite"] = "http://"+element.CME_CertificationBoard.Website;
                }
              }
        
              let getTopicObject = {
                Frequency : 0,
                RecertificationDate : element.RecertificationDate,
                CertificationBoardId :  element.CME_CertificationBoard_Id.toLocaleLowerCase(),
                Id : []
              }
        
              topicArray.push(getTopicObject);
        
                
                listObject["ShowDeleteView"] = false;
                listData.push(listObject);

                handleTopicProgress(topicArray,requirementListData);
                setSpinner(false);
              }
            }else{
               //To hide the wait indicator
               profileStyles.showSpinner(false)
               setSpinner(false);
            }
                
            //setListData by adding listData to existing list
            setListData(prevListData => [...prevListData, ...listData]);

            setLoadNavHeight(true);

            setPage(prevPage => prevPage + 1); // Append new results to existing search list
           
            //this will adjust the heigh on dashboard
            //props.onLoadPage(true); 
            
        })
        
   
}

  function callProfileData() {
    if(profileAPIData && !profileAPICalled){
    var rows  = profileAPIData; 

    if(rows && rows.length > 0){
      for (let index = 0; index < rows.length; index++) {
        const listObject: any = {};
        let element: IProfileListData = rows[index];
        listObject["Id"] = element.Id;
        listObject["CertificationBoardId"] = element.CME_CertificationBoard_Id.toLocaleLowerCase();
        listObject["name"] = element.CME_CertificationBoard.Name;
        listObject["recertificationDate"] = element.RecertificationDate;          
        listObject["recertificationDateFormatted"] = element.RecertificationDateFormatted;
        listObject["fullrecertificationDate"] = (element.RecertificationDate);

        if(element.CME_CertificationBoard.Website !== null)
        {
        if(element.CME_CertificationBoard.Website.toLowerCase().includes("http://"))
        {
          listObject["requirerWebsite"] = element.CME_CertificationBoard.Website;
        }
        else{
          listObject["requirerWebsite"] = "http://"+element.CME_CertificationBoard.Website;
        }
      }

      let getTopicObject = {
        Frequency : 0,
        RecertificationDate : element.RecertificationDate,
        CertificationBoardId :  element.CME_CertificationBoard_Id.toLocaleLowerCase(),
        Id : []
      }

      topicArray.push(getTopicObject);

        
        listObject["ShowDeleteView"] = false;
        listData.push(listObject);
      }
    }else{
       //To hide the wait indicator
       profileStyles.showSpinner(false)
    }
        setPage(2); // Append new results to existing search list
        setListData(listData);

        //this will adjust the heigh on dashboard
        setLoadNavHeight(true)

        // profileStyles.showSpinner(false)
  }  
  }

  function GetModalData() {
    if(categoryAPIData && !categoryAPICalled){
      var rows  = categoryAPIData; 
      profileCategory = []
        rows.map((data:{Id:string,Name:string}) => 
            profileCategory.push(data) 
        )
  }
  }

  function GetStateOrSpecialtyBoard() {
    if(certificationBoardAPIData && !certificationAPICalled){
      var rows  = certificationBoardAPIData; 
        for (let index = 0; index < rows.length; index++) {
          const listObject: any = {};
          let element: ICategoryListData = rows[index];
          listObject["Id"] = element.CME_CertificationBoard.Id;
          listObject["Name"] = element.CME_CertificationBoard.Name;
          listObject["CME_ProfileCategory_Id"] = element.CME_CertificationBoard.CME_ProfileCategory_Id;
          categorylistData.push(listObject);
        }
        let uniqueList = categorylistData
        .map((e: { [x: string]: any; }) => e['Id'])
        .map((e: any, i: any, final: string | any[]) => final.indexOf(e) === i && i)
        .filter((obj: string | number)=> categorylistData[obj])
        .map((e: string | number) => categorylistData[e]);

        setStateOrSpecialtyBoard(uniqueList);
    }
  }

  const onProfileCategoryChanges = (event:any) => {
    event.preventDefault(event);  
    const filteredBoardData = stateOrSpecialtyBoard.filter(
        (data) => data.CME_ProfileCategory_Id.toLowerCase().trim() === event.target.value.toLowerCase().trim()
      );
      
      if(filteredBoardData.length > 0 )
        setStateOrSpecialtyBoardFiltered(filteredBoardData);
        else
        setStateOrSpecialtyBoardFiltered(certBoardCategory);

       
   }; 

   const onRequirerBoardChanges = (event:any) => {
    event.preventDefault(event);  
     
        
        if(profileListData.some((item:any) => item.CertificationBoardId.toLocaleLowerCase() === event.currentTarget.value.toLocaleLowerCase())){
          event.target.parentElement.parentElement.parentElement.firstChild.textContent  = "Profile already Exists!!";
        }else{
          event.target.parentElement.parentElement.parentElement.firstChild.textContent = "";
        }
   };

  const onAddNewProfile= (event:any) => { 
    var selectedDate = dayjs(new Date(event.target[2].value)).startOf('day'); // Ignore time part
    var currentDate = dayjs(new Date()).startOf('day');

    if (selectedDate !== null && (selectedDate.isSame(currentDate, 'day') || selectedDate.isAfter(currentDate, 'day'))) {
    profileStyles.showSpinner(true) 
    
     //This will check user session is active or not
     localStorage.setItem("loadSSOData","1")
    
    var selectedBoard:any = stateOrSpecialtyBoard.filter(data => {return data.Id == event.target[1].value})
    event.preventDefault(event);    
    const requestOptions = {

                            Id:uuid(),
                            Name: selectedBoard[0].Name,
                            RecertificationDate: event.target[2].value || '',
                            DefaultProfile: '',
                            CME_Contact_Id: contactData?.contactid,
                            CME_CertificationBoard_Id: selectedBoard[0].Id || ''
                        }
                          
     //check selected board record already present or not then do not allow to add duplicate board record
    if(profileListData.some((item:any) => item.CertificationBoardId.toLocaleLowerCase() === event.target[1].value.toString().toLocaleLowerCase()))
    {
      event.target.firstChild.textContent = "Profile already Exists!!";
      profileStyles.showSpinner(false) 
    }
    else{
      event.currentTarget.getElementsByClassName("model-cancel-button")[0].click()
      event.target.firstChild.textContent  = "";

            apiProfiles.url = "cmeprofiles";            
            apiProfiles.post(requestOptions)
            .then((data: any) => {
              removeQueries();
              setLoadData(data => !data);
             
              
              msg = "Profile Created Successfully!"
              setOpenAlertMsg(true);         
              profileStyles.showSpinner(false)
              setLoaded(true);
           })

    }  
  }else{
    event.preventDefault(event);
    event.currentTarget.getElementsByClassName('date-picker-modal')[0].style.display = 'block'
  }
    
};

function removeQueries() {
              queryClient.removeQueries({ queryKey: ['profileData'] });
              queryClient.removeQueries({ queryKey: ['requirementData'] });
              queryClient.removeQueries({ queryKey: ['generalRequirementData'] });
              promiseValue.setPromiseObject([]);
}

const onLicenseRenwlUpdate=(index : any,ID:string) => (event:any) => {
  
   //This will check user session is active or not
   localStorage.setItem("loadSSOData","1")

   
   

  event.preventDefault();

  const cur_data:any = [...profileListData];
  cur_data[index].RecertificationDate = updateCurSelecteLicRenDate;
  setListData(cur_data);

  const requestOptions = {                    
    RecertificationDate: updateCurSelecteLicRenDate,
}; 

var currentDate = dayjs(new Date()).utc().startOf('day');
var certiDate = dayjs(new Date(requestOptions.RecertificationDate)).utc().startOf('day');
if((requestOptions.RecertificationDate) !== null && certiDate < currentDate){
return;
}

  if(updateCurSelecteLicRenDate === '' || updateCurSelecteLicRenDate === undefined)
   {     
    setShowRequiredMessage(true)
   }
  else {
    profileStyles.showSpinner(true)
    setShowRequiredMessage(false)

    apiProfiles.url = "cmeprofiles/" + ID;            
    apiProfiles.put(requestOptions)
    .then((data: any) => {
              setLoadData(data);
    if( event && event.target && event.target[1])
      event.target[1].click();
    }).then((data: any) => {
      removeQueries();
    resetEditProfileView(); 

    msg = "Profile Updated Successfully!";
    setOpenAlertMsg(true); 
    profileStyles.showSpinner(false)
    
  })
  }

};

  const onSubmitOnDeleteClck=(ID:string) => (event:any) => {
    profileStyles.showSpinner(true)
    event.target[1].click(); 
     //This will check user session is active or not
     localStorage.setItem("loadSSOData","1")
     
    event.preventDefault();
      
    apiProfiles.url = "cmeprofiles";            
            apiProfiles.remove(ID)
            .then((data: any) => {
              setLoadData(data => !data);                  
        
    }).then((data: any) => {
        removeQueries();
      resetEditProfileView();
      
      msg = "Profile Deleted Successfully!";
     setOpenAlertMsg(true); 
     profileStyles.showSpinner(false)
      setLoaded(true);
     if(profileListData.length === 1)
     {
      profileStyles.showSpinner(false)
      setLoaded(false);
      setListData([]);
    }

    
  })

  };

  const onKeyPress = (event: any) => {
    event.preventDefault();
   // event.target[0].click(); 
   setTimeout(() => {
    resetEditProfileView();
     
  }, 100);
   
  };

  function ChangeLicRenDateNew(value: any, context: any): void {

    

    
    setShowRequiredMessage(false);
    setUpdateCurSelecteLicRenDate(value);;
  
  }

  const onChangeDate = (value: any, context: any) => {     

   alert(value)
  };

  function resetEditProfileView() {
    profileListData.map(data => {return data.ShowDeleteView = false}) 
    const cur_data:any = [...profileListData];
    setListData(cur_data);
  }

  function getStatute (item : any) {  
    let generalStatute = requirementGeneralListData.filter((value:any, i:any) => {
     return value.CertificationBoardId === item.CertificationBoardId && (value.CME_Topic_Id) === 1 && (value.statute) !== null && (value.statute) !== ''})
    
    let topicStatute = requirementListData.filter((value:any, i:any) => {
     return value.CertificationBoardId === item.CertificationBoardId  && (value.CME_Topic_Id) !== 1 && (value.statute) !== null && (value.statute) !== ''})                               
    
     //check if both statute fields are null 
      if(generalStatute.length=== 0 && topicStatute.length === 0)
        return true;   
      else
        return false;  
  }

  
  function getRequirement  (item : any)  {   
    let generalRequirement = requirementGeneralListData.filter((value:any, i:any) => {
     return value.CertificationBoardId === item.CertificationBoardId && (value.CME_Topic_Id) === 1 && (value.title) !== null && (value.title) !== ''})
    
    let topicRequirement = requirementListData.filter((value:any, i:any) => {
     return value.CertificationBoardId === item.CertificationBoardId  && (value.CME_Topic_Id) !== 1 && (value.title) !== null && (value.title) !== ''})                               
    
     //check if both statute fields are null 
      if(generalRequirement.length===0 && topicRequirement.length === 0)
      return true;
      else
      return false;
  }
  
  

  return (
     <>
    <div  ref={ref}  className= {isAdmin ? 'CMEProfiles HomePagebackground adminView' :'CMEProfiles HomePagebackground'} style={{pointerEvents:profileStyles.profileStyles.pointer, opacity:profileStyles.profileStyles.opacity}}> 
          <div className="HomePageWrapper ">
          <div style={{float:'left',width:'5%'}}>
            <LeftNavMenu heightDashboardPage={height}/>
            </div>

            <div className={'Right-Div-individual-Dashboard'}> 
              
            {updateContact?  <div className='column-full'>                     
                    <div className="ContactInfo">              
                      <ContactInfo/>                          
                    </div>               
                  </div> : null}

              <div  className="NamingProfile ">
                <div className="div-left">CME Profiles</div> 
                <div className="div-right-profile">
                <AddNewProfile 
                  stateOrSpecialtyBoard={stateOrSpecialtyBoard} 
                  onRequirerBoardChanges={onRequirerBoardChanges}
                  onProfileCategoryChanges={onProfileCategoryChanges}
                  profileCategory={profileCategory}
                  stateOrSpecialtyBoardFiltered={stateOrSpecialtyBoardFiltered}
                  onChangeDate={onChangeDate}
                  onSubmit={onAddNewProfile}/>
                </div>
              </div>
              {profileListData && profileListData.length > 0 ?
               <InfiniteScroll
               dataLength={profileListData.length}
               next={handleGetProfile}
               hasMore={loaded} // Set to false when there is no more data
               loader={null} // Loading indicator while fetching data
               endMessage={null} // Message to display when all data is loaded
               >
               <div>
              <List sx={{ width: "100%"}}>
                {profileListData.map((item: any, index:number) => (
                  <div className="ProfileList">
                  
                  
                    <ListItem className={item.ShowDeleteView ? "":''} sx={{ display: "block", paddingLeft:"42px" }}>
                      <div style={{paddingBottom:'50px'}}>
                        <Typography variant="h2" className="ProfileTitle">
                          {item.name}  
                        </Typography>
                        <div className="ProfleEditButton Edit-Profile-Tablet-view">
                          { 
                          !item.ShowDeleteView &&
                            <span 
                            
                            onClick={(ev) => { 
                                
                                setShowRequiredMessage(false)
                                profileListData.map(data => {data.ShowDeleteView = false}) 
                                const cur_data:any = [...profileListData];
                                cur_data[index].ShowDeleteView = true;
                                setUpdateCurSelecteLicRenDate(cur_data[index].recertificationDate);
                                setListData(cur_data);

                                
                                }}>
                                  <DriveFileRenameOutlineIcon />
                                <span className="vertical-align-top">Edit Profile </span>
                              </span>
                            }
                        </div>                       
                      </div>
                      <div className="Row">
                        <div className={item.ShowDeleteView ? "Column columnFirst":'Column columnFirst'}>
                          {!item.ShowDeleteView && <div className="Controls">
                            <div className="ControlsLicense">
                              {" "}
                              <CalendarTodayIcon fontSize="medium" />{" "}
                            </div>
                            <div >
                              <Typography
                                className="ProfileLabel"
                                sx={{ marginTop: "0px !important" }}
                              >
                                License Expiration Date
                              </Typography>
                              {dayjs(item.recertificationDate).utc().startOf('day').
                              isAfter(dayjs(new Date()).utc().startOf('day')) 
                              || 
                              dayjs(item.recertificationDate).utc().startOf('day').
                              isSame(dayjs(new Date()).utc().startOf('day')) 
                              ? 
                              <Typography className="InputLabel">
                                {" "}
                                {item.recertificationDateFormatted}
                              </Typography>:
                              <Typography className="OldInputLabel">
                              {" "}
                              {item.recertificationDateFormatted}
                            </Typography>
                              }
                            </div>
                          </div>}

                          { item.ShowDeleteView && <div className="Controls1">
                            
                          <div style={{paddingBottom:"10px"}}>
                              <Typography
                                className="ProfileLabel"
                                sx={{ marginTop: "0px !important" }}
                              >
                                License Expiration Date*
                              </Typography>
                              <Typography className="input-renewal-date">
                                {" "}
                                <MyDatePicker
                                    // onChange={ChangeLicRenDateNew}
                                    onChange={(date:any) => {
                                      setShowRequiredMessage(false);
                                      setUpdateCurSelecteLicRenDate(date);

                                     // item.recertificationDate = date;
                                  }}
                                    disablePastDates                                      
                                    required
                                    
                                    className= {showRequiredMessage ? 'form-control model-input-required' : "form-control model-input "} 
                                    defaultValue={dayjs(updateCurSelecteLicRenDate).utc().toDate()}
                                  />
                                  {/* <LocalizationProvider  dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    label=""
                                    
                                    onChange={ChangeLicRenDateNew} 
                                    format="MM/DD/YYYY"
                                    minDate={dayjs(new Date())}                                    
                                    className= {showRequiredMessage ? 'form-control model-input-required date-control' : "form-control model-input date-control"} 
                                    defaultValue={dayjs(item.recertificationDate)}
                                    slotProps={{ field: { shouldRespectLeadingZeros: true } }}
                                  />
                                   </LocalizationProvider> */}
                              </Typography>
{/*                                     
                                <TextField required 
                                
                                onChange={handleChangeLicRenDate} 
                                defaultValue= {item.recertificationDateFormatted} 
                                type="date" 
                                InputProps={{
                                 
                                  inputProps: {
                                    format: 'MM/dd/yyyy', // Set the format here
                                    min:new Date().toISOString().split('T')[0]
                                  },
                                }}
                                                     
                                className= {showRequiredMessage ? 'form-control model-input-required' : "form-control model-input"}  
                                id="date" placeholder="date" ></TextField>    */}
                               
                              {/* <Typography className="input-renewal-date">
                                {" "}
                                <input required onChange={handleChangeLicRenDate} defaultValue= {item.recertificationDateFormatted} type="date" min={new Date().toISOString().split('T')[0]} 
                                
                                className= {showRequiredMessage ? 'form-control model-input-required' : "form-control model-input"}  id="date" placeholder="date" ></input>   
                               
                              </Typography> */}
                              { 
                                                                                        showRequiredMessage && 
                                                                                        <div className='error-message-cme-hours'>
                                                                                            <span>
                                                                                            <ErrorIcon /> This field is required
                                                                                            </span>
                                                                                        </div>
                                }
                            </div>
                            <div className="form-group" style={{width:'83%'}}>
                                <Button onClick={resetEditProfileView} variant="contained" className='profile-cancel-button text_transform_none'  >
                                    Cancel
                                </Button>
                                  <Button onClick={onLicenseRenwlUpdate(index,item.Id)} variant="contained"  className='profile-submit-button text_transform_none'   >
                                  Save
                                </Button>
                                
                            </div>
                          </div>}

                          <div className="Controls">
                            {" "}
                            <div className="ControlsLicense">
                              {" "}
                              <BadgeOutlinedIcon fontSize="medium" />{" "}
                            </div>
                            <div >
                              <Typography className="ProfileLabel">
                                {" "}
                                Licensure Period
                              </Typography>
                              <Typography className="InputLabel">
                              {requirementGeneralListData.filter((value:any, i:any) => {
                                 return value.CertificationBoardId === item.CertificationBoardId && (value.CME_Topic_Id) === 1})
                                .map(({ frequency }) =>  frequency === 1 ? '1 year' : frequency + " years") } 
 
                              </Typography>
                            </div>
                          </div>

                          <div className="Controls ">
                            {" "}
                            <div className="ControlsLicense" style={{paddingTop:"10px", color:"#1976D2"}}>
                              {" "}
                              <LinkOutlinedIcon fontSize="medium" />{" "}
                            </div>
                            <div>
                              <Typography className="RequirerLabel">
                                {" "}  
                                                  
                                <Link href={item.requirerWebsite} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" >Requirer Website</Link>
                              </Typography> 
                            </div>
                          </div>

                          <div className="Controls">
                            {" "}
                            <div className="ControlsLicense" style={{color: getRequirement(item)? 'lightblue': "#1976D2"}}>   
                            {" "}
                              <ReceiptLongOutlinedIcon fontSize="medium" />{" "} 

                                             
                            <Container 
                                modalFor={"Requirements"}
                                triggerText={ <Typography className="RequirerLabel" color={getRequirement(item) ?'lightblue': "#1976D2" }  style={ {paddingTop:'20px', pointerEvents:'none'}} > Requirements </Typography>} 
                                modalHeaderText = {item.name }
                                modalGeneralRequirementText = {requirementGeneralListData.filter((value:any, i:any) => {
                                  return value.CertificationBoardId === item.CertificationBoardId && (value.CME_Topic_Id) === 1 && (value.title) !== null})
                                  .map(({ description }) =>  description||'' + "\n \n ").join().replace(',','')
                                }
                                modalTopicRequirementText = {requirementListData.filter((value:any, i:any) => {
                                  return value.CertificationBoardId === item.CertificationBoardId && (value.CME_Topic_Id) !== 1&& (value.title) !== null})
                                  .sort((a:any, b:any) => {
                                    // Sort by description alphabetically
                                    if (a.description < b.description) return -1;
                                    if (a.description > b.description) return 1;
                                    return 0;
                                  })                                 
                                  .map(({ description }) =>  description||'' + "\n \n ").join().replace(',','')
                                }                            
                                inputProperties={{
                                  modalData:requirementGeneralListData.filter((value:any, i:any) => {
                                 return value.CertificationBoardId === item.CertificationBoardId  && (value.CME_Topic_Id) === 1 && (value.title) !== null})
                                 .map(({ title }) =>  title||'' + "\n \n ").join().replace(',','')
                                }}

                                topicList={{
                                  modalData:requirementListData.filter((value:any, i:any) => {
                                 return value.CertificationBoardId === item.CertificationBoardId  && (value.CME_Topic_Id) !== 1 && (value.title) !== null})                               
                                }}
                                topicLoaded={topicLoaded}
                                disableRequirement={getRequirement(item)}
                                />  
                                               
                            </div>
                          </div>

                          <div className="Controls">
                            {" "}
                            <div className="ControlsLicense" style={{color: getStatute(item)? 'lightblue': "#1976D2"}}>   
                            {" "}
                              <HistoryEduOutlinedIcon fontSize="medium" />{" "} 

                                             
                            <Container 
                                modalFor={"Requirements"}
                                triggerText={ <Typography className="RequirerLabel" color={getStatute(item) ?'lightblue': "#1976D2" } style={ {paddingTop:'20px'}}> Statute </Typography>} 
                                modalHeaderText = "Statute"
                                
                                modalGeneralRequirementText = {requirementGeneralListData.filter((value:any, i:any) => {
                                  return value.CertificationBoardId === item.CertificationBoardId && (value.CME_Topic_Id) === 1 && (value.statute) !== null})
                                  .map(({ description }) =>  description||'' + "\n \n ").join().replace(',','')
                                }
                                modalTopicRequirementText = {requirementListData.filter((value:any, i:any) => {
                                  return value.CertificationBoardId === item.CertificationBoardId && (value.CME_Topic_Id) !== 1&& (value.statute) !== null})
                                  .sort((a:any, b:any) => {
                                    // Sort by description alphabetically
                                    if (a.description < b.description) return -1;
                                    if (a.description > b.description) return 1;
                                    return 0;
                                  })                                 
                                  .map(({ description }) =>  description||'' + "\n \n ").join().replace(',','')
                                }                            
                                inputProperties={{
                                  modalData:requirementGeneralListData.filter((value:any, i:any) => {
                                 return value.CertificationBoardId === item.CertificationBoardId && (value.CME_Topic_Id) === 1 && (value.statute) !== null})
                                 .map(({ statute }) =>  statute||'' + "\n \n ").join().replace(',','')
                                }}

                                topicList={{
                                  modalData:requirementListData.filter((value:any, i:any) => {
                                 return value.CertificationBoardId === item.CertificationBoardId  && (value.CME_Topic_Id) !== 1 && (value.statute) !== null})                               
                                }}
                                topicLoaded={topicLoaded}
                                statuteLoaded={true}
                                disableRequirement={getStatute(item)}

                                />  
                                               
                            </div>
                          </div>

                          {
                            item.ShowDeleteView &&
                            <div className="Controls">
                              {" "}
                              <div className="ControlsLicense">
                                
                                <DeleteProfile
                                onKeyPress={onKeyPress}
                                onSubmit={onSubmitOnDeleteClck(item.Id)} />
                              </div>
                              
                            </div>
                            }
                        </div>

                        <div  className={item.ShowDeleteView ? "Column columnSecond":'Column columnSecond'}>
                          
                          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                          {generalRequirementListData.filter((value:any, i:any) => {
                                 return value.CertificationBoardId === item.CertificationBoardId})
                            .map((item: any) => (
                            <><div className="NamingRequirement">
                             {item.ShortTitle}  General CME Requirements
                            </div><div className="CircularProgressBar-width-profile" >

                              {
                                item.Hours === 0 || item.TotalClaimedHours >= item.Hours ? 
                                <CircularProgressbarWithChildren
                                value= {item.TotalClaimedHours ?  item.TotalClaimedHours/ item.Hours * 100 : 0}
                                strokeWidth={8}
                                // text={`75%   7 /14 Hrs Completed`}

                                styles={buildStyles({
                                  
                                  pathColor: "#00D16D",
                                  textColor: "#00D16D",
                                  // trailColor: "transparent"
                                })}
                              >

                               
                                <CheckOutlinedIcon fontSize="large"  
                                sx={{ fontSize:"4.1875rem", color:"#00D16D"

                                }}/>{" "}                             
                                <br/>
                                <Typography
                                 className="percentCenterCompleted"
                                    variant="subtitle2"
                                    sx={{
                                      textAlign:"center",                                       
                                      position: "absolute",
                                      color: "#545d69",
                                      fontSize: "12px",
                                      fontWeight: "700",
                                      paddingTop:"74px",
                                    }}
                                  >
                                   {item.TotalClaimedHours ? item.TotalClaimedHours : 0}/{item.Hours ? item.Hours : 0} Hrs 
                                   <br/> Completed
                                  </Typography>
                                
                              </CircularProgressbarWithChildren>:
                                <CircularProgressbarWithChildren
                                  value= {item.TotalClaimedHours ?  item.TotalClaimedHours/ item.Hours * 100 : 0}
                                  strokeWidth={8}
                                  // text={`75%   7 /14 Hrs Completed`}

                                  styles={buildStyles({
                                    
                                    pathColor: "#0084FF",
                                    textColor: "black",
                                    // trailColor: "transparent"
                                  })}
                                >
                                  <br/>
                                  <div className="percentCenter">
                                  { Math.round(item.TotalClaimedHours/ item.Hours * 100) } %{" "}
                                  </div>
                                  <Typography
                                  className="percentCenterCompleted"
                                    variant="subtitle2"
                                    display="block"
                                    sx={{
                                      textAlign:"center",
                                      paddingTop: "74px",
                                      
                                      position: "absolute",
                                      color: "#545d69",
                                      fontSize: "12px",
                                      fontWeight: "700",                               
                                    }}
                                  >
                                   {item.TotalClaimedHours ? item.TotalClaimedHours : 0}/{item.Hours ? item.Hours : 0} Hrs    
                                   <br/> Completed                               
                                  </Typography>
                                  
                                  
                                </CircularProgressbarWithChildren>
                              }
                                
                              </div><div className="NamingRequirement-below">
                              {item.Hours === 0 || item.TotalClaimedHours >= item.Hours ?  0 + ' Hrs Remaining' :  (item.Hours  - item.TotalClaimedHours) === 1 ? '1 Hr Remaining' : item.Hours  - item.TotalClaimedHours + ' Hrs Remaining'} 
                                     
                              </div></>
                        
                            ))                           
                          }
                          { generalRequirementListData.filter((value:any, i:any) => {
                              return value.CertificationBoardId === item.CertificationBoardId}).length === 0 && <div className="Missing-NamingRequirement">This state does not have a set number of required CME credit hours for physicians.</div> 
                           }
                            </List>
                          </div>
                          

                        <div className={item.ShowDeleteView ? "Column columnThird":'Column columnThird'}>
                         
                        <div>
                                  
                         <List sx={{ width: "100%", bgcolor: "background.paper" }}>   
                         {requirementListData
                         .sort((item:any, next:any) => {
                          // Compare based on RemainingHours in descending order
                          const remainingHoursA = item.RemainingHours;
                          const remainingHoursB = next.RemainingHours;
                      
                          if (remainingHoursA !== remainingHoursB) {
                            // Sort by RemainingHours in descending order
                            return remainingHoursB - remainingHoursA;
                          } else {
                            // If RemainingHours are equal, sort by Topic_Title in alphabetical order
                            const topicTitleA = item.Topic_Title.toLowerCase();
                            const topicTitleB = next.Topic_Title.toLowerCase();
                      
                            if (topicTitleA < topicTitleB) {
                              return -1;
                            } else if (topicTitleA > topicTitleB) {
                              return 1;
                            } else {
                              return 0; // Topics are equal
                            }
                          }
                        })
                         .filter((value:any, i:any) => {
                                 return value.CertificationBoardId === item.CertificationBoardId && value.CME_Topic_Id !== 1 }).map((item: any) => (
                                                             
                                <ListItem
                                  alignItems="flex-start"
                                  sx={{ paddingTop: "26px" }}
                                >
                                  <ListItemText
                                    primary={
                                      <React.Fragment>

                                        <Typography
                                               component="div"
                                               variant="h2"
                                               className="RequirementTitle"
                                             >
                                               {item.Topic_Title}
                                        </Typography>
                                        {item.CompletedHours < item.maxhours ?
                                        <BorderLinearProgress
                                                 variant="determinate"
                                                 sx={{
                                                   height: "12px",
                                                   borderRadius: "20px",
                                                   backgroundColor: "#9D48C6"
                                                 }}
                                        value={Math.round(item.CompletedHours/ item.maxhours * 100)}  />
                                        :
                                        <SuccessBorderLinearProgress
                                                 variant="determinate"
                                                 //color="success"
                                                 sx={{
                                                   height: "12px",
                                                   borderRadius: "20px",
                                                   backgroundColor: "#00D16D"
                                                 }}
                                                 value={100}
                                                  />
                                        }
                                            
                                         </React.Fragment>                                      
                                    }

                                    
                                    secondary={                               
                                      <React.Fragment>
                                                                                                              
                                      <div className="HoursDiv">
                                          <Typography
                                            // sx={{ display: 'inline' }}
                                            component="span"
                                            //variant="h3"
                                            //color="text.primary"
                                            className="HoursComplete"
                                          >
                                            {item.CompletedHours}/{item.maxhours} Hrs
                                            Completed
                                          </Typography>

                                          <Typography
                                            // sx={{ display: 'inline' }}
                                            component="span"
                                            variant="h3"
                                            //color="text.primary"
                                            className="HoursRemaining"
                                          >
                                            {item.CompletedHours  < item.maxhours ? (item.maxhours - item.CompletedHours) === 1? '1 Hr Remaining' :item.maxhours - item.CompletedHours + ' Hrs Remaining' : 0 + ' Hrs Remaining' } 
                                      
                                          </Typography>
                                        </div>
                                    
                                      </React.Fragment>                                                                     
                                    }
                                  />
                                </ListItem>
                              
                              ))}   
                         </List>
                          
                          </div>
                        </div>
                      </div>
                    </ListItem>

                  </div>
                ))}

                {
                showSpinner ? 
                progressProfileListData.map((item: any, index:number) => (
                <div className="ProfileList">
                <ListItem className={item.ShowDeleteView ? "":''} sx={{ display: "block", paddingLeft:"42px" }}>
                <div style={{paddingBottom:'50px'}}>
                  
                  </div>
                  <div className="Row">
                  <div className="spinner-container">
                  <div className="inner-spinner"></div>
                </div>
                  </div>
                </ListItem>
                
                </div>
                ))
                : null 

                }
              </List>
              </div>
               </InfiniteScroll>
               
              
              : null }
              <>
 {
  // YT: condition added for image where it will disappear when the spinner is on
  <>
    {profileListData && profileListData.length === 0 && (
      <div className="EmptyList">
        {!profileStyles.spinner && (
          <>
            <div className="empty-text">
              You do not have any CME profiles.
            </div>
            <img src={EmptyProfile} alt="Empty Profile" />
            <div>
              <AddNewProfile
               onChangeDate={onChangeDate}
                stateOrSpecialtyBoard={stateOrSpecialtyBoard}
                onProfileCategoryChanges={onProfileCategoryChanges}
                profileCategory={profileCategory}
                stateOrSpecialtyBoardFiltered={
                  stateOrSpecialtyBoardFiltered
                }
                onSubmit={onAddNewProfile}
              />
            </div>
          </>
        )}
      </div>
    )}
  </>
}

              </>
              
              
             
          </div>
        </div>
        
    </div>
    <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar open={openAlertMsg} autoHideDuration={3000} onClose={() => setOpenAlertMsg(false)} >
                <Alert   severity="info" sx={{ width: "100%" }}>
                  {msg}
                </Alert>
              </Snackbar>
            </Stack> 
    </>  
  );
};

const ProfileList = (): JSX.Element => {
  let enableObject = {opacity:0.5,pointer:'none'}
  let userObject = useContext(userContext);
  let userData = userObject.User;
  const history = useHistory();
  const [profileStyles, setprofileStyles] = useState(enableObject);
  const [spinner, setSpinner] = useState(true);

  React.useEffect(() => {
    if(  (userData.acepMemberStatus === 0 && userData.sempaMemberStatus !== 15 ) || (userData.acepMemberStatus === 3 || userData.acepMemberStatus === 4 || userData.acepMemberStatus === 7) )
    {
      history.push("/")
    }
   
    if(!spinner){
      enableObject={
         opacity:1, 
         pointer:'auto'
     }              
  
 }
 setprofileStyles(enableObject);
  },[spinner])
  


  return(   
      <>     
      
      {spinner? 
      <div className="spinner">
      </div>
      :
      null
      }

      <ProfileListProgress
        spinner={spinner} 
        showSpinner={(toShow: boolean) => {
          setSpinner(toShow);
        }}
        profileStyles={profileStyles}
      />
    </>
  );
};


export default ProfileList;