import React from 'react'
import { Container } from "../components/CMEProfileManagement/ModalContainer";
import API_URL from '../config/config' 
import apiUsers from "../api-operation";
  
 
  export default function AddNewProfile(props:any){   
    

    const triggerText = "Add Profile"; 
    const modalHeaderText = "Add Profile";
    const modalForText = "Add";
    
    React.useEffect(() => {  
      
      }, []);

    return (
        <Container 
        modalFor={modalForText}
        triggerText={triggerText} 
        modalHeaderText = {modalHeaderText}
        onSubmit={props.onSubmit} 
        onProfileCategoryChanges = {props.onProfileCategoryChanges}
        onRequirerBoardChanges={props.onRequirerBoardChanges}
        stateOrSpecialtyBoard = {props.stateOrSpecialtyBoardFiltered}
        profileCategory = {props.profileCategory}
        closeModal={props.closeModal}
        onChangeDate={props.onChangeDate}
        />
    )
  }