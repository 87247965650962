import React,{useState, useContext} from 'react'
import '../styles/Most-Recent-Activities.css' 
import '../styles/profile.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, Link, Typography } from "@mui/material";
import {OpportunitiesLink} from '../config/config'
import shortid from  "shortid";
import { contactContext } from '../context/contactContext';
import { promiseContext } from '../context/promiseContext';
import { GetClaimedFilteredData }  from '../utils/cache'

export default function MostRecentActivities(props:any){

    const [MostRecentActivities,setMostRecentActivities] = useState<[IClaimdDetails]|[]>([]);
    const [claimedLoaded, setclaimedLoaded] = useState(false);

    let contactObject  = useContext(contactContext);
   
    let contactData = contactObject.contactObject.Contact;

    let promiseValue= React.useContext(promiseContext) as PromiseType;

    const {data : claimedFilteredData,isLoading:claimedAPICalled} = GetClaimedFilteredData(contactData?.contactid)

    let trackingArray : any =[];

    function redirectEducation(){
        window.open(OpportunitiesLink, "_blank");
    }
    
    React.useEffect(function() {  
        if(contactData?.contactid !=='' && MostRecentActivities.length === 0 && claimedFilteredData && !claimedAPICalled)
        {
            //    apiTrackings.url ="cmetracking/claimed/" + contactData?.contactid +"?limit=10";
            //    apiTrackings.getAll().then((data: any) => {
                // var rows = data.cmetracking?.rows ;

                var rows  = claimedFilteredData; 
                           
                for (let index = 0; index < rows.length; index++) {
                  const listObject: any = {};
                  let element: IClaimdDetails = rows[index];
                  listObject["id"] = element.Id;
                  listObject["key"] = element.Id;
                  listObject["Name"] = element.Name;
                  listObject["Details"] = element.Details;
                  listObject["MaxHours"] = element.MaxHours;
                  listObject["ClaimedHours"] = element.ClaimedHours;
                  listObject["RemainingHoursToClaim"] = element.RemainingHoursToClaim
                  listObject["FormatCompletionDate"] = element.FormatCompletionDate
              
                  trackingArray.push(listObject);
                }

                    setMostRecentActivities(trackingArray);
                    setclaimedLoaded(true);
                    props.onLoadPage(true);
                    // if(promiseValue.promiseObject.length !== 10)
                    //     // promiseValue.setPromiseObject(promiseValue.promiseObject.push('cmetrackingclaimed'));
                    //     promiseValue.setPromiseObject((prev: any) => [...prev, 'cmetrackingclaimed'])
            //    });
        }
    }, [claimedAPICalled])


    return(
       
        <div className='MostRecentActivities'>
             { 
                <>
                     <Typography  variant="h2"  className="Naming" >
                        Most Recent CME Activities
                    </Typography>
                   
                    {MostRecentActivities && MostRecentActivities.length > 0 ?
                    <>
                    {!claimedLoaded ? 
                        <div className="dashboard-spinner">
                        </div>
                        :
                
                    <div className='FourthRow box-style'>                     
                        <div  className='MostRecent-header-line'>
                            <div   className='box-inner-header dateColumn'>Date</div> 
                            <div   className='box-inner-header activityNameColumn'> Activity Name</div> 
                            <div   className='box-inner-header hoursEarnedColumn'> CME Hours Earned</div>
                        </div> 
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                { 
                                
                                MostRecentActivities.map((item, index) => 
                                    {  
                                        return(
                                                <div key={shortid.generate()} className='dvMostRecentList'>
                                                    <ListItem key={shortid.generate()} alignItems="flex-start" sx={{paddingTop:"0px"  }}>
                                            
                                                        <ListItemText key={shortid.generate()}
                                                        primary=
                                                        {
                                                            <React.Fragment>                                                        

                                                            <Typography component="span"    className="RequirementTitle box-inner-left-text StartDateActivities" >
                                                            {item.FormatCompletionDate}
                                                            </Typography>
                                                                                                                

                                                                <Typography component="div"    className="RequirementTitle box-inner-left-text MiddleTextCMEActivities" >
                                                                {item.Name + ( item.Details ? ' - ' + item.Details : '' )}
                                                                
                                                                </Typography>

                                                                <Typography component="p"   className="RequirementTitle box-inner-left-text MobileMiddleTextCMEActivities" >
                                                                {item.Name + ( item.Details ? ' - ' + item.Details : '' )}
                                                                
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                        
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span" variant="h3"   className="box-inner-right-text HoursEarned">
                                                                    {item.ClaimedHours}
                                                                </Typography>                           
        
                                                            </React.Fragment>
                                                        }
                                                        />
                                                    </ListItem>
                                                </div>                         

                                                ) 
                                        })                   
                            }
                            </List>  
                        <div className='Most-recent-box-bottom-right-button'>                                
                                <Link href="/dashboard/MostRecentActivities" underline="hover"> Get Transcript </Link>
                                <ArrowForwardIcon />                                 
                            </div>  
                    </div>
                    
                    }
                    </>
                    : 
                    <div className='secondColumn EmptyActivity  '> 
                    {!claimedLoaded ? 
                        <div className="dashboard-spinner">
                        </div>
                        :
                     <>
                    <div className=''>
                                    <div className='empty-text'> 
                                    You do not have any CME activities
                                    </div>
                                    
                                    <div >
                                    <Button                                        
                                        style={{textTransform: 'none'}} variant="contained" className='explore-more-opp-div' onClick={redirectEducation}>
                                        <b> Explore Opportunities</b>
                                    </Button> 
                                    </div>
                                   
                                </div>
                    <div className='Most-recent-box-bottom-right-button' style={{color:"#DDDDDD"}}>                            
                            <Link underline="hover" style={{color:"#DDDDDD"}}> Get Transcript </Link>
                            <ArrowForwardIcon />                                 
                        </div>
                        </>
                    }
                    </div>                               
                }   
                </>
            }
        </div>

    )
}