import { useQuery } from "@tanstack/react-query";
import apiUsers from "../api-operation";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import { AuthorizationCredentials, CMEGetContactDetailsAPIURL } from "../config/config";
import axios from "axios";

  
const fetchUnclaimedCMETopSix = async (contactId:string | undefined) => {
    let res: never[] = [];
    apiUsers.url ="cmetracking/unclaimed/" + contactId +"?limit=6";   
    await apiUsers.getAll().then((resp: any) =>
    {
      res = resp.cmetracking?.rows;
    });
    if(!res)
    res = [];
    return res;
}; 

const fetchUnclaimedCME = async (contactId:string | undefined) => {
  let res: never[] = [];
  apiUsers.url ="cmetracking/unclaimed/" + contactId;   
  await apiUsers.getAll().then((resp: any) =>
  {
    res = resp.cmetracking?.rows;
  });
  if(!res)
  res = [];
  return res;
}; 

const fetchProfile = async (contactId:string | undefined,limit:any ) => {
    let res: never[] = [];
    apiUsers.url = "cmeprofiles?contactid=" + contactId + "&limit=" + limit;
    await apiUsers.getAll().then((resp: any) =>
    {
      res = resp.cmeprofiles?.rows;
    });
    if(!res)
    res = [];
    return res;
};
  
const fetchRequirement = async (contactId:string | undefined) => {
    let res: never[] = [];
    apiUsers.url = "cmerequirementsbycontact/" + contactId;
    await apiUsers.getAll().then((resp: any) =>
    {
      res = resp.cmerequirements?.rows;
    });
    if(!res)
    res = [];
    return res;
};
  
const fetchGeneralRequirement = async (contactId:string | undefined) => {
    let res: never[] = [];
    apiUsers.url = "cmegeneralrequirements/" + contactId;
    await apiUsers.getAll().then((resp: any) =>
    {
      res = resp.cmerequirements?.rows;
    });
    if(!res)
    res = [];
    return res;
};

const fetchClaimedCMETopTen = async (contactId:string | undefined) => {
    let res: never[] = [];
    apiUsers.url ="cmetracking/claimed/" + contactId +"?limit=10";
    await apiUsers.getAll().then((resp: any) =>
    {
      res = resp.cmetracking?.rows;
    });
    if(!res)
    res = [];
    return res;
};

const 
fetchActivitiesDeletedByAdmin = async (contactId:string | undefined) => {
  let res: never[] = [];
  apiUsers.url ="cmetracking/deletedrecords/" + contactId ;
  await apiUsers.getAll().then((resp: any) =>
  {
    res = resp.cmetracking?.rows;
  });
  if(!res)
  res = [];
  return res;
};


const fetchClaimedCME = async (contactId:string | undefined) => {
  let res: never[] = [];
  apiUsers.url ="cmetracking/claimed/" + contactId ;
  await apiUsers.getAll().then((resp: any) =>
  {
    res = resp.cmetracking?.rows;
  });
  if(!res)
  res = [];
  return res;
};

const fetchTopics = async () => {
  let res: never[] = [];
  apiUsers.url = "cmetopics";
  await apiUsers.getAll().then((resp: any) =>
  {
    res = resp.cmetopics?.rows;
  });
  if(!res)
  res = [];
  return res;
};

const fetchProfileCategory = async () => {
  let res: never[] = [];
  apiUsers.url = "cmeprofilecategory";
  await apiUsers.getAll().then((resp: any) =>
  {
    res = resp.cmeprofilecategories?.rows;
  });
  if(!res)
  res = [];
  return res;
};

const fetchCertificationBoard = async () => {
  let res: never[] = [];
  apiUsers.url = "cmevalidcertificationboard";
  await apiUsers.getAll().then((resp: any) =>
  {
    res = resp.certificationboard?.rows;
  });
  if(!res)
  res = [];
  return res;
};

const fetchActivityTypes = async () => {
  let res: never[] = [];
  apiUsers.url = "CMEActivityTypes";
  await apiUsers.getAll().then((resp: any) =>
  {
    res = resp.cmeactivitytypes?.rows;
  });
  if(!res)
  res = [];
  return res;
};

export const GetUnclaimedFilteredData = (contactId:string | undefined) => {
  const { data, isLoading, error } = useQuery(
    ['unclaimedCMEFilterData',contactId],
    () => fetchUnclaimedCMETopSix(contactId),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetProfileData = (contactId:string | undefined, limit:any=50) => {
  const { data, isLoading, error } = useQuery(
    ['profileData',contactId,limit],
    () => fetchProfile(contactId,limit),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetRequirementData = (contactId:string | undefined) => {
  const { data, isLoading, error } = useQuery(
    ['requirementData',contactId],
    () => fetchRequirement(contactId),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetGeneralRequirementData = (contactId:string | undefined) => {
  const { data, isLoading, error } = useQuery(
    ['generalRequirementData',contactId],
    () => fetchGeneralRequirement(contactId),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetClaimedFilteredData = (contactId:string | undefined) => {
  const { data, isLoading, error } = useQuery(
    ['claimedCMEFilterData',contactId],
    () => fetchClaimedCMETopTen(contactId),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetActivitiesDeletedByAdminData = (contactId:string | undefined) => {
  const { data, isLoading, error } = useQuery(
    ['activitiesDeletedByAdmin',contactId],
    () => fetchActivitiesDeletedByAdmin(contactId),
    
  );
 
  return { data, isLoading, error };
};

export const GetClaimedData = (contactId:string | undefined) => {
  const { data, isLoading, error } = useQuery(
    ['claimedCMEData',contactId],
    () => fetchClaimedCME(contactId),
    {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetUnclaimedData = (contactId:string | undefined) => {
  const { data, isLoading, error } = useQuery(
    ['unclaimedCMEData',contactId],
    () => fetchUnclaimedCME(contactId),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetTopicData = () => {
  const { data, isLoading, error } = useQuery(
    ['topicData'],
    () => fetchTopics(),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetActivityTypeData = () => {
  const { data, isLoading, error } = useQuery(
    ['activityTypeData'],
    () => fetchActivityTypes(),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetProfileCategoryData = () => {
  const { data, isLoading, error } = useQuery(
    ['profileCategoryData'],
    () => fetchProfileCategory(),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export const GetCertificationBoardData = () => {
  const { data, isLoading, error } = useQuery(
    ['certificationBoardData'],
    () => fetchCertificationBoard(),
   {staleTime:Infinity}
  );
 
  return { data, isLoading, error };
};

export async function UpdateUserContextOutside (acepid:string | undefined)  {
  //import updateUserContext from contact context 

  try {
    const response = await axios.get(CMEGetContactDetailsAPIURL + acepid, {
      headers: {
        'Authorization': AuthorizationCredentials
      }
    });

    // Extract the necessary data from the response 
    const apiData = response.data;

    return apiData;
  } catch (error) {
    // Handle errors if needed
    console.error("Error updating user context:", error);
  }
};
  
