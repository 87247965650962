import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserListFilter from "./CMEUserFilter";
import apiContacts from "../../api-operation";
import '../../styles/user.css';
import NavBar  from "../common/Navbar";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const userListColumns: GridColDef[] = [
  { field: 'FullName', headerName: 'Name', width: 600 },
  { field: 'Profiles', headerName: 'Number Of Profiles', width: 300 ,
    valueGetter: (params) =>{
    return  4;
  } },
  { field: 'Topics', headerName: 'Number Of All Topics', width:300,
    valueGetter: (params) =>{
    return  8} },
  { field: 'Status', headerName: 'Status', width: 500 ,
    valueGetter: (params) =>{
    return  ''}},  
];

const UserList  = (props:any): JSX.Element => {
  const [data, setData] = useState([]);
  const [gridValue, setGrid] = useState('none');
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [page, setPage] = useState(0);

  const [inputJSON, setInputJSON] = useState({
    Name: "",
    Acep_Id: "",
    Email: "",
    RowsPerPage: 20,
    CurrentPage: 0,
  });

  useEffect(() => {
    setData([]);
    setIsDataLoading(true);
  }, [inputJSON]);

  useEffect(() => {
    apiContacts.url = "cmecontacts";
    //----Post Input filter values and return result.
    apiContacts.post(inputJSON).then((resp: any) => {
      setData(resp.contacts);
      setIsDataLoading(false);
    });
  }, [inputJSON, isDataLoading]);


  return (
    <>
    <NavBar /> 
      <React.Fragment>
      <div className='CMERequirer'>
      <Typography  variant="h4"  className="Naming">
                        CME Users
        </Typography>
        
          <div className="CMEUserSearch">         
                <UserListFilter
                  inputJSON={inputJSON}
                  setInputJSON={setInputJSON}
                  setPage={setPage}
                  setGrid={setGrid}
                />          
          </div>

        <div style={{ height: 600, width: '90%', top:'270px', bottom:'50px', backgroundColor:'white', paddingLeft:'30px', paddingTop:'55px', display:gridValue}}>
        <DataGrid
          rows={data}
          columns={userListColumns}
          getRowId= {(row) => row.Id}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection ={false}
          onCellClick={props.onCellStateMedBoard}
          //onSelectionModelChange={currentlySelected}   
        
        />
        
      </div>              
        </div>
      </React.Fragment>
    </>
  );
};
export default UserList;


