import '../../styles/Navigation-sidebar.css' 
import { useState, useEffect, useContext } from 'react'
import { SidebarData } from './SidebarData';
import {useHistory} from 'react-router-dom'
import {userContext} from "../../context/userContext";
import { contactContext } from '../../context/contactContext';
import React from 'react';
import { Container } from '../CMEProfileManagement/ModalContainer';
export default function Navbar(props:any){
 
  let userObject = useContext(userContext);
  let userData = userObject.User;

  let contactObject  = useContext(contactContext);
  let contact = contactObject.contactObject.Contact;
  
  //check user is admin or not
let isAdmin:boolean = userData.acepMemberStatus === 2 ? true : false;

//This variable will be used to show the Sidebar to expand or shrink
//on click on the Hamberger menu click it will toggle for Desktop view  
const [sidebar, setSideBar] = useState(false); 
const iMaxHeightNav = "2877px"; 
const iMinHeightNav = "41px";
const  [maxHeightNavDiv, setMaxHeightNavDiv] = useState(iMaxHeightNav);
 
const history = useHistory();
const inputElement = React.useRef<any>()
const cancelBtnRef =React.useRef<any>();
const showSiteBar = () => {  
  //on Mobile Layout i.e screen resolution <= 768
  //this variable will set to true
  //if true, 
  if(showMobileLayoutNavMenu){
        
    setSideBar(false)
    //props.onCollapse(!showNavMenu);
    setMaxHeightNavDiv(maxHeightNavDiv == iMaxHeightNav ? iMinHeightNav : iMaxHeightNav);
  }else{     
    setSideBar(!sidebar)
    
   // props.onCollapse(!sidebar);
  }

 
}

const HideSiteBarForExpandedDiv = () =>{   
  if((!showMobileLayoutNavMenu && sidebar) || (showMobileLayoutNavMenu ))
      showSiteBar();
   
}

//To show Active Icon
//This funcion will execute on click of sidebar navigation menu
const [showActiveIcon, setShowActiveIcon] = useState(true);
const showIcons = () => {
  //toggle the variable value so that it will activate the icon based on variable
  setShowActiveIcon(!showActiveIcon)
} 

//For Mobile laout view navigation menu
//need to hide and show the navigation side bar menu on click of hamberger menu
//toggle the variable value to it will show or hide the menu
const [showMobileLayoutNavMenu, setShowMobileLayoutNavMenu] = useState(false);
 

//This function will helpful to get the current dimension/resolutio of the width
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

function getOpacity(item:any){
  
    if(props.member=== 1 && (item.title === 'Home' || item.title === 'Merge CME')){
     return 1;
    }
    else
    {
      if(props.member ===1 && (userData.acepid === contact?.acepid ) )
        return 0.5;
      else
        return 1;
    } 

  }

  function getPointer(item:any){
    if(props.member=== 1  && (item.title === 'Home' || item.title === 'Merge CME')){
      return "auto"
    }  
    else
    {
      if(props.member ===1 &&  (userData.acepid === contact?.acepid ))
      return "none";
      else
      return "auto";
    } 
  }
  //Variable to catch the current screen resolution
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

   //Hooks will execute when screen size changes
  useEffect(() => {    
     
    handleResize();

    handleNavigation();

    function handleNavigation(){
      SidebarData.forEach((item) => {
        if(window.location.pathname.toLowerCase() === item.path.toLowerCase()){
          item.active = 1
        }
        else
          item.active = 0;
      }); 
    }

    function handleResize() {
      
      //call the function to get screen resolution
      setWindowDimensions(getWindowDimensions());
      
      //Considered mobile layout is for 600 
      //Show the Mobile layout menu by executing setShowMobileLayoutNavMenu()
      //side bar set to false so that only hide or show will work in mobile layout
      if(window.innerWidth < 768){
          setShowMobileLayoutNavMenu(true)        
          setSideBar(false)
          setMaxHeightNavDiv(iMinHeightNav);
          //Here will open the pop up for mobile user
          var mobilePopupActivated = localStorage.getItem("mobilePopupActivated") || "0"
          if(mobilePopupActivated && mobilePopupActivated !== "2"){
            inputElement  && inputElement.current.click();
            localStorage.setItem("mobilePopupActivated","1")
          } 
          
      }else{
        //set mobile layout to false
        //set show navigation menu to true
        setShowMobileLayoutNavMenu(false)         
        setMaxHeightNavDiv("2258px")

        var mobilePopupActivated = localStorage.getItem("mobilePopupActivated") || "0"

        if(mobilePopupActivated && mobilePopupActivated !== "2" && cancelBtnRef.current)   { 
          localStorage.setItem("mobileSource","resize")                
          cancelBtnRef.current.click();
        }
        
      }
      localStorage.removeItem("mobileSource")
      
    }

    //resize event
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

 
    
    //props.onCollapse(sidebar);
  }, [sidebar]);


  function redirect(path:any){
    //This will check user session is active or not
    localStorage.setItem("loadSSOData","1")
    
    if(isAdmin && path !== '/'){
      path = path + '?acepid='+contact?.acepid;
    }
    history.push(path)
    showSiteBar()
  }
  

  const closeModalCustomClick = (event?: any) => {
    if (event) {
      event.preventDefault();
    }
  
    // Simulate a click on the "Cancel" button
    if (cancelBtnRef.current) {   
      localStorage.setItem("mobileSource","closeButtonClick")    
      cancelBtnRef.current.click();
    }
  };

    return(
        <>
          <div style={{display:'none'}}>
             <Container 
                            modalFor={'MobileUser'}
                            triggerText={'MobileUser'} 
                            modalHeaderText = 'Welcome to CME Tracker' 
                            customBtnRef={inputElement} 
                             closeModalCustomClick = {closeModalCustomClick}  
                             cancelBtnRef={cancelBtnRef}                       
                            />
             </div>
        <div onMouseLeave={HideSiteBarForExpandedDiv}  
           style={{ height:Number(props.heightDashboardPage)+ 'px' }} 
           className={ isAdmin? 'nav-bar-div' : 'user-nav-bar-div'} id='left-nav-bar'> 
             
          {  
              <nav style={{marginTop:'-15px'}}>
                <ul  className='nav-menu-items' style={{paddingRight: '1px', paddingLeft:'0px',listStyleType:'none'}}>  
                {/* <ul  className='nav-menu-items' style={{paddingRight: '1px'}}>  */}
                    {SidebarData.filter
                    (data => 
                      (
                      props.nonmember === 1 
                      ? data.nonmember === 1 
                      : (data.allowAccessToShow &&(data.nonmember === 0||data.nonmember === 1) && (isAdmin ? (data.onlyforadmin === 0 || data.onlyforadmin === 1): data.onlyforadmin === 0))
                      )
                      
                    )
                    .map((item, keyval) => {  
                        return (                            
                                <li 
                                    //To do - for admin
                                     style={{display: 'list-item', justifyContent: 'start', height:'61px',pointerEvents:getPointer(item), opacity: getOpacity(item),cursor:props.member ===1  && (item.title === 'Home' || item.title === 'Merge CME')? 'pointer' : ''}}                                   
                                    //style={{display: 'list-item', justifyContent: 'start', height:'61px'}}                                                                     
                                    onClick={() => {                                        
                                           
                                        SidebarData.map(data => {data.active = 0;data.hover = 0;}) 
                                         
                                        if(item.active !== 1) {
                                            item.active = 1;
                                        }
                                        else{
                                            item.active = 0;
                                        }

                                        
                                        redirect(item.path)
                                       //showIcons()
                                     }}

                                     onMouseOver={() => { 
                                        if(item.hover !== 1) {
                                            item.hover = 1;
                                        }
                                        else{
                                        item.hover = 0;
                                        }

                                        showIcons()
                                        
                                     }}
                                     onMouseOut={() => { 
                                        SidebarData.map(data => {data.hover = 0;}) 
                                        showIcons()
                                     }}
                                    className={
                                        item.active === 1 ? "nav-bar-list-item active" 
                                        :item.hover === 1 ? "active-nav-bar-list-item":"nav-bar-list-item"
                                       
                                        
                                    }
                                    key={keyval} >
                                    <span >

                                     { item.active === 1 ? item.icon_active : item.hover === 1 ? item.icon_primary : item.icon}
                                   {/* coding-1118 */}
                                    <span style={{verticalAlign:'super'}} 
                                   
                                    >   </span>
                                    </span>
                                    <span className='nav-header-title' style={{color: item.active === 1 ? '#1976D2':'black' }}>
                                    {item.title}
                                    </span>
                                </li>                             
                        )
                    })}
                </ul>
              </nav>
          }
        </div>
        </>
    )
}

