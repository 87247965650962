import React, { Component } from 'react';
import { Modal } from './ModalPopup';
import TriggerButton from '../CMEProfileManagement/ModalTriggerButtons';
export class Container extends Component {
 
  state = { isShown: false };
  showModal = (ev) => { 
    ev.preventDefault();
    //This will check user session is active or not
    localStorage.setItem("loadSSOData","1")
    
    //document.getElementsByClassName("HomePagebackground")[0].style.opacity = 0;
    this.setState({ isShown: true }, () => {
      this.closeButton.focus();
    });
    this.toggleScrollLock();
  };
  closeModal = (ev) => {
    ev.preventDefault();
    //This is for close the pop up for 
    //This will consider as Close Button Click from Pop up
    //This also calls from the ref button using the NavBar for Close Button
    //Here, we need to show or hide the mobile pop up based on conditin
    var sourceMobileCloseEvent = localStorage.getItem("mobileSource") || "closeButtonClick";
    if(this.props.modalFor === 'MobileUser' && sourceMobileCloseEvent === "closeButtonClick"){
      localStorage.setItem("mobilePopupActivated","2")
      localStorage.removeItem("mobileSource")
    }

    //document.getElementsByClassName("HomePagebackground")[0].style.opacity = 1;
    this.setState({ isShown: false });

    if(this.TriggerButton)
      this.TriggerButton.focus();
      
    this.toggleScrollLock();
  };
  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      //this.closeModal();
    }
  };
  onClickOutside = (event) => {
    if (this.modal && this.modal.contains(event.target)) return;
   // this.closeModal();
  };

  toggleScrollLock = () => {

     // Scroll the popup content to the top every 100 milliseconds
    const scrollInterval = setInterval(function () {
      if(document && document.querySelector('[modalfor="Requirements"]')){
       

      if(document.querySelector('.modal-area') )
          {
            document.querySelector('.modal-area').scrollTop = 0;
            clearInterval(scrollInterval)
          }

        }else
          clearInterval(scrollInterval)
          
  }, 10);

   
  };
  render() {  
    return (
      <React.Fragment>
        <TriggerButton
         
          showModal={this.showModal}
          buttonRef={(n) => (this.TriggerButton = n)}
          triggerText={this.props.triggerText}
          customBtnRef={this.props.customBtnRef} 
          disableReportButtons={this.props.disableReportButtons}
          statuteLoaded={this.props.statuteLoaded}
          disableRequirement = {this.props.disableRequirement}
        />
        {this.state.isShown ? (
          <Modal
            onSubmit={this.props.onSubmit}
            onProfileCategoryChanges = {this.props.onProfileCategoryChanges}
            onRequirerBoardChanges={this.props.onRequirerBoardChanges}
            modalRef={(n) => (this.modal = n)}
            buttonRef={(n) => (this.closeButton = n)}
            closeModal={this.closeModal}
            onKeyDown={this.onKeyDown}
            onClickOutside={this.onClickOutside}
            profileCategory = {this.props.profileCategory}
            filterProps = {this.props.filterProps}
            stateOrSpecialtyBoard = {this.props.stateOrSpecialtyBoard}
            modalHeaderText = {this.props.modalHeaderText}
            modalGeneralRequirementText = {this.props.modalGeneralRequirementText}
            modalTopicRequirementText = {this.props.modalTopicRequirementText}
            modalFor={this.props.modalFor}
            onKeyPress = {this.props.onKeyPress}
            handleChangeLicRenDate={this.props.handleChangeLicRenDate}
            inputProperties = {this.props.inputProperties}
            topicList = {this.props.topicList}
            topicLoaded = {this.props.topicLoaded}
            statuteLoaded = {this.props.statuteLoaded}
            disableRequirement = {this.props.disableRequirement}
            closeModalCustomClick = {this.props.closeModalCustomClick}
            cancelBtnRef={this.props.cancelBtnRef}
            onChangeDate = {this.props.onChangeDate}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Container;
