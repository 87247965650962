import React,{useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import dayjs from 'dayjs';
import ErrorIcon from '@mui/icons-material/Error';
import {Button, TextField} from '@mui/material'; 
import { BrowserRouter, useHistory } from "react-router-dom";
import {AcepHeaderLogo_RedirectURL} from '../../config/config'
import MyDatePicker from '../common/MyDatePicker';
export const Form = ({ onSubmit,onProfileCategoryChanges,onRequirerBoardChanges,handleChangeLicRenDate,inputProperties,onKeyPress,profileCategory,stateOrSpecialtyBoard,filterProps,modalFor,closeModal,topicLoaded,closeModalCustomClick,cancelBtnRef }) => {
  const history = useHistory();

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const handleDateChange = (date) => {
    const currentDate = dayjs(new Date());
    if (isNaN(date)) {     
      setSelectedDate(null);  return;
    }  
    
  
    if(document.getElementsByClassName('date-picker-modal')[0]){
      document.getElementsByClassName('date-picker-modal')[0].style.display = 'none';
  }
   setSelectedDate(date);
    // other logic if needed
  };

  const handleEndDateChange = (date) => {
    const currentDate = dayjs(new Date());
    if (isNaN(date)) {     
      setSelectedEndDate(null);  return;
    }  

    if(document.getElementsByClassName('end-date-picker-modal')[0]){
        document.getElementsByClassName('end-date-picker-modal')[0].style.display = 'none';
    }

    setSelectedEndDate(date);
    // other logic if needed
  };

  const handleStartDateChange = (date) => {
    const currentDate = dayjs(new Date());
    if (isNaN(date)) {     
      setSelectedStartDate(null);  return;
    }  

    if(document.getElementsByClassName('date-picker-modal')[0]){
      document.getElementsByClassName('date-picker-modal')[0].style.display = 'none';
    }

    setSelectedStartDate(date);
    // other logic if needed
  };

  return ( 
    <form onSubmit={onSubmit} modalfor={modalFor} className={modalFor=== "Requirements"? "form-container-requirement" : "form-container"}>
        <label style={{color:'red'}}></label>
        {modalFor == "MobileUser" ?
        <div >
          <span style={{whiteSpace: "pre-line"}}>
          For the best experience, we advise using a desktop or tablet to access your CME information.
          <br></br>
          <br></br>
         <input className='no-border-input' style={{border:'none !important'}}></input>
         
          </span>   

          

           <div  onClick={(ev) => {
            ev.preventDefault(); 
            window.location.href = AcepHeaderLogo_RedirectURL
          }} style={{color:'#1976D2', cursor:'pointer'}} className='popup-button-text'> Back to acep.org</div> 

        </div>
      
        :
        modalFor == "Add" ?
        <div >
          <div className="form-group">
            <label  className='model-input-label'  htmlFor="name">Requirer Type</label>
            <select required  
            onChange={onProfileCategoryChanges}
            className="form-control model-input">
              <option  value={-1}></option>
                {profileCategory.map((option) => (
                  <option  value={option.Id}>{option.Name}</option>
                ))}
              </select> 
          </div>
          <div className="form-group">
            <label className='model-input-label' htmlFor="email ">Requirer Name</label>
            <select  required   
             onChange={onRequirerBoardChanges}        
            className="form-control model-input">
                {stateOrSpecialtyBoard.map((option) => (
                  <option value={option.Id}>{option.Name}</option>
                ))}
              </select>      
          </div>
          <div className="form-group">
            <label className='model-input-label' htmlFor="LicRendate">License Expiration Date</label>
            <div className="date-picker-container">
          <MyDatePicker
            defaultValue={selectedDate ? selectedDate : null}
            selected={selectedDate}
            onChange={handleDateChange}
            disablePastDates
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            className=" form-control model-input "
            id="date"
             
          />
           <span style={{display:"none"}} className='date-picker-modal error-message-cme-hours'>
                                <span className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required and date must be in the future.'}
                                </span>
            </span> 
        </div>
            
          </div>
      </div>
        :  modalFor == "Requirements" ? 
         
        <div style={{paddingTop:"30px"}}>   
        {topicLoaded ? 
        <span style={{whiteSpace: "pre-line"}}> {(inputProperties)}</span>
        : 
        <span style={{whiteSpace: "pre-line"}}> {(inputProperties.modalData)}</span>
        }
          <a href='' style={{transform:'none',border:'none'}} ></a>
        </div>          
        : modalFor == "Edit Profile" ?
        
        <div className="form-group">
          <label className='model-input-label' htmlFor="LicRendate">License Expiration Date</label>
          <TextField required
            type="date"
            onChange={handleChangeLicRenDate}
            defaultValue= {inputProperties.RecertificationDate}
            InputProps={{
              inputProps: {
                min:new Date().toISOString().split('T')[0]
              },
            }}  
            
            className="form-control model-input"
          />
          
        </div>
         :  modalFor == "Add Requirer" ? 
         <>
             <div className="form-group">
             <label  className='model-input-label'  htmlFor="name">Requirer Type</label>
             <select required  
             
             className="form-control model-input">
                 { profileCategory.map((option) => (
                   <option  value={option.Id}>{option.Name}</option>
                 ))} 
               </select> 
           </div>
           <div className="form-group">
             <label className='model-input-label' htmlFor="email ">Requirer Name</label>
             <input  required   type="text"    
             className="form-control model-input">
                 
               </input>      
             </div>
           <div className="form-group">
             <label className='model-input-label' htmlFor="LicRendate">Website</label>
             <input required
               type="text"
               className="form-control model-input"
             />
             
           </div>
         </>
        : modalFor == "Filters" ?
        <div >
          <div className='form-group'>
              <label className='model-input-label' htmlFor="date">Date</label>
              <select required id='date'
              onChange={filterProps[2].onchangeDateRange}
                className="form-control model-input filters-date">
                   <option  value="All Time">All Time</option> 
                   <option  value="Last 30 Days">Last 30 Days</option>
                   <option  value="Last 6 Months">Last 6 Months</option>
                   <option  value="Year To Date (YTD)">Year To Date (YTD)</option>           
                   <option  value="Date Range">Date Range</option>  
              </select>
          </div>

         <div className={filterProps[1].toShowSelectFiltersDateRange ? 'form-group ' :'form-group display-none' }>
            <div className='width_45_percent float_left' > 
            <label className='model-input-label' htmlFor="startdate">Start Date</label>
             { filterProps[1].toShowSelectFiltersDateRange ?
                  <div className="date-picker-container">
                  <MyDatePicker
                  required
                    selected={selectedStartDate}
                    onChange={handleStartDateChange}                    
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    defaultValue={selectedStartDate ? selectedStartDate : null}
                    className="form-control model-input filters-start-date"
                    id="date"
                  />
                   <span style={{display:"none", maxWidth:'135px'}} className='date-picker-modal error-message-cme-hours'>
                                <span style={{width:'135px'}} className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required.'}
                                </span>
            </span> 
                </div>
              // <TextField required 
              // className="form-control model-input"    
              // data-date-format="DD MMMM YYYY" 
              // type="date" id='startdate' ></TextField>
              :
            <div className="date-picker-container">  <MyDatePicker
                  required
                    selected={selectedStartDate}
                    onChange={handleStartDateChange}                    
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    defaultValue={selectedStartDate ? selectedStartDate : null}
                    className="form-control model-input filters-start-date"
                    id="date"
                  />
                   <span style={{display:"none", maxWidth:'135px'}} className='date-picker-modal error-message-cme-hours'>
                                <span style={{width:'135px'}} className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required.'}
                                </span>
            </span> 
                  </div>
              // <TextField 
              // className="form-control model-input"  
              // data-date-format="DD MMMM YYYY" 
              // type="date" id='startdate' >

              // </TextField>
            }</div>
            <div className='width_45_percent float_right' >
            <label className='model-input-label' htmlFor="enddate">End Date</label> 
            { filterProps[1].toShowSelectFiltersDateRange ?
              // <TextField required className="form-control model-input"   type="date" id='enddate' ></TextField>
              <div className="date-picker-container"><MyDatePicker  
              required             
                selected={selectedEndDate}
                onChange={handleEndDateChange}                    
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                className="form-control model-input filters-end-date"
                defaultValue={selectedEndDate ? selectedEndDate : null}
                id="date"
              />
              <span style={{display:"none", maxWidth:'135px'}} className='end-date-picker-modal error-message-cme-hours'>
                                <span style={{width:'135px'}} className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required.'}
                                </span>
            </span> 
              </div>
              :
              // <TextField  className="form-control model-input"    type="date" id='enddate' ></TextField>
             <> <MyDatePicker
              required
                 selected={selectedEndDate}
                onChange={handleEndDateChange}                    
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                defaultValue={selectedEndDate ? selectedEndDate : null}
                className="form-control model-input filters-end-date"
                id="date"
              />
 <span style={{display:"none",maxWidth:'135px'}} className='end-date-picker-modal error-message-cme-hours'>
                                <span style={{width:'135px'}} className='padding_top_5_px'>
                                <ErrorIcon /> {'This field is required.'}
                                </span>
            </span> 
              </>
            }       </div>
          </div>
         

          <div className='form-group'>
              <label className='model-input-label' htmlFor="activitytype">Activity Type</label>
              <select required  id='activitytype'             
                className="form-control model-input filters-activity-type">
                 { <option  value="All Activity Types">All Activity Types</option>}
                  { filterProps[3].cmeActivityTypeList.map((option) => (                  
                    <option  value={option.Id}>{option.Name}</option>
              ))}
                </select>
          </div>
          <div className='form-group'>
              <label className='model-input-label' htmlFor="topic">Topic</label>
              <select required id='topic'          
                className="form-control model-input filters-topic" >
                  {
                    <option  value='All Topics'>All Topics</option>
                  }
                 { filterProps[0].cmeTopicList.map((option) => (                  
                    <option  value={option.Id}>{option.Name}</option>
              ))}
                 
                </select>
          </div>

          <div className='form-group'>
              <label className='model-input-label' htmlFor="topic">Accreditation Source</label>          
                <select required id='accreditationSource'    
                className="form-control model-input filters-accreditation-source">
                   <option value='All Accreditation Sources'>All Accreditation Sources</option>
                    <option value='ACEP Accredited'>ACEP Accredited</option>
                    <option value='Self-Reported'>Self-Reported</option>  
              </select>
          </div>
          
        </div>
        : modalFor == "Certificates" ?
        <div style={{paddingTop:"30px", paddingLeft:'20px'}}>          
        <span style={{whiteSpace: "pre-line"}}> 
        <ul style={{listStyle:"disc"}}>{inputProperties.modalData.split(',').map(data => {
          return <li style={{listStyle:"disc"}}>{data}</li>
           
        })}</ul>
        </span>
        <input className='no-border-input' style={{border:'none !important'}}></input>
        </div> 
        : modalFor === 'MergeCME' ?
        <div>Are you sure you want to merge all CME activities? This action cannot be undone.</div>
       : <div className='delete-operation-message'>
          Are you sure you want to delete this?
          <input className='no-border-input' style={{border:'none !important'}}></input>
        </div>
        }
    
       {modalFor !== 'Requirements' && 
       <div className="form-group" style={{ paddingTop:modalFor === 'Delete' ?'64px':'40px',paddingBottom:modalFor === 'Delete' ?'64px':'40px',float:"right"}}>
       {
        modalFor == "Certificates" ?
        <Button variant="outlined" 
            onMouseDown={onKeyPress}
            style={{width:inputProperties && (inputProperties.showExportButton) ? '50%':'100%', textTransform: 'none',background: 'red',transition:'none !important'}}
            onClick={closeModal} className='model-cancel-button' type='closeModal'>
               <div style={{color:'#1976D2'}} className='popup-button-text'>Cancel</div> 
            </Button>
            :
            modalFor == "MobileUser" ?
            <>
            <Button variant="outlined" 
                onMouseDown={onKeyPress}
                style={{ textTransform: 'none',background: 'red',transition:'none !important'}}
                onClick={closeModalCustomClick} className='model-cancel-button ' type='closeModal'>
                   <div style={{color:'#1976D2'}} className='popup-button-text'>Close</div> 
                </Button>
              <Button variant="outlined" 
                 ref={cancelBtnRef}
                 onMouseDown={onKeyPress}
                 style={{display:'none'}}
                 onClick={closeModal} className='model-cancel-button close-button' type='closeModal'>
                    <div style={{color:'#1976D2'}} className='popup-button-text'>
                    Cancel
                     </div> 
                 </Button>
                 </>
                :
            <Button variant="outlined" 
            onMouseDown={onKeyPress}
            style={{textTransform: 'none',background: 'red',transition:'none !important'}}
            onClick={closeModal} className='model-cancel-button' type='closeModal'>
               <div style={{color:'#1976D2'}} className='popup-button-text'>
               Cancel
                </div> 
            </Button>
       }
           
            {modalFor == "Add" ?  
            <Button variant="contained" className='model-submit-button modal-submit-background-color text_transform_none model-submit-button-border'    type="submit">
                Save
              </Button> :
              modalFor == "Edit Profile" ?  
              <Button variant="contained" className='model-submit-button modal-submit-background-color model-submit-button-border'    type="submit">
                  Save
                </Button>
                :
                modalFor == "Add Requirer" ?  
                <Button variant="contained" className='model-submit-button modal-submit-background-color model-submit-button-border'    type="submit">
                    Save
                </Button> 
                :modalFor == "Filters" ?  
              <Button variant="contained" className='model-submit-button modal-submit-background-color text_transform_none model-submit-button-border' 
                  type="submit">
                  Apply
                </Button>
                :modalFor == "Certificates" && inputProperties.showExportButton ?  
                
                <Button variant="contained"
                
                className='model-submit-button modal-submit-background-color text_transform_none model-submit-button-border' 
                    type="submit">
                    Continue to Export Certificate
                  </Button> :
                  modalFor == "MergeCME" ?  
                  <Button variant="contained" className='model-submit-button modal-submit-background-color text_transform_none model-submit-button-border'    type="submit">                        
                        Yes, merge
                    </Button> :
                  modalFor !== "Certificates" && modalFor !== "MobileUser" &&
              <Button variant="contained"  
              style={{textTransform: 'none',background: '#FFFFFF',transition:'none !important'}}
              className='model-submit-button color-red model-delete-button-border'  type="submit">
              <div style={{color:'#FFFFFF'}} className='popup-button-text'>Delete</div>
              </Button>
      }
      </div>}
    </form>
  );
};
export default Form;

