import axios, { AxiosRequestConfig } from 'axios'; 
import { handleError } from './response'; 
import API_URL,{Token_Header_Key} from './config/config';

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = API_URL; 

// Add a request interceptor
export const validateToken =() => {
  let token = localStorage.getItem(Token_Header_Key)?.toString();  
  if(token !== undefined){
    
      //config.headers["Authorization"] = token;
      axios.defaults.headers.common[Token_Header_Key] = token        
  }
}
/** @param {string} resource */ 
const getAll = (resource: string) => { 
  return axios 
    (`${BASE_URL}/${resource}`) 
    .then(resp => resp.data) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {string} id */ 
const getSingle = (resource: string, id: string) => { 
  return axios 
    .get(`${BASE_URL}/${resource}/${id}`) 
    .then(resp => resp.data) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
const post = (resource: string, model: object) => { 
  return axios 
    .post(`${BASE_URL}/${resource}`, model) 
    .then(resp => resp.data) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
const put = (resource: string, model: object) => { 
  return axios 
    .put(`${BASE_URL}/${resource}`, model) 
    .then(resp => resp.data) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
const patch = (resource: string, model: object) => { 
  return axios 
    .patch(`${BASE_URL}/${resource}`, model) 
    .then(resp => resp.data) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {string} id */ 
const remove = (resource: string, id: AxiosRequestConfig<any> | undefined) => { 
  return axios 
    .delete(`${BASE_URL}/${resource}/${id}`, id) 
    .then(resp => resp.data) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {string} id */ 
const multiRemove = (resource: string, model: object) => { 
  return axios 
    .put(`${BASE_URL}/${resource}`, model) 
    .then(resp => resp.data) 
    .catch(handleError); 
}; 

export const apiProvider = { 
  getAll, 
  getSingle, 
  post, 
  put, 
  patch, 
  remove, 
  multiRemove
};