import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../styles/Most-Recent-Activities.css' 
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { contactContext } from '../context/contactContext';
import { userContext } from '../context/userContext';

export default function SelfReportedSubmit(props:any){
//Self reported submit 
const history = useHistory();

let userObject = useContext(userContext);
let userData = userObject.User;
let isAdmin:boolean = userData.acepMemberStatus === 2 ? true : false;

let contactObject  = useContext(contactContext);   
let contactData = contactObject.contactObject.Contact;

const handleRedirection = (path: string) => {
    // Replace with the path of your target page
    //let targetPath = '/dashboard/MostRecentActivities';

    if(isAdmin && path !== '/'){
        path = path + '?acepid='+contactData?.acepid;
    }

    // Use the history object to navigate to the target page with state
    // history.push({
    //   pathname: targetPath,
    //   state: contactData,
    // });

    history.push(path, {contact: contactData, from: "AdminDashboard"});
    history.go(0);     
};

return(
  
    <div className='Self-Reported-Submit Self-Reported-Info'>  
        <div className="SelfReported-box">
            
            <CheckCircleIcon fontSize='large' className='checkCircle'/>    
            
            <Typography className='submitMsg'> Thank you!</Typography>     

            <Typography className='submitSubMsg'>Your Self-Reported CME activity was successfully added.</Typography>      
        </div>

        <div className='divAddTopicButtonsSubmit divAddTopicButtonsSelfActivitySubmit'>
            <Button variant="outlined"    
            onClick={()=>{ handleRedirection('/dashboard/SelfReported')}}
                // history.push('/dashboard/SelfReported');
                // history.go(0);        
            //}}                                                                     
                className='cancel-transcript-button'>
                <b color='#1976D2'> Add More</b>
            </Button>

            <Button  
                  onClick={()=>{
                    handleRedirection('/dashboard/MostRecentActivities');
                    //history.push('/dashboard/MostRecentActivities')
                }}                                                                                 
                 variant="contained" 
                className='add-transcript-button text_transform_none model-submit-button-border'>
                <b style={{color:'white'}}> 
                    View Transcript
                </b>
            </Button> 
        </div>            
    </div>
  
    )
}