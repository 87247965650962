import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import LeftNavMenu from "../common/Navbar";
import "../../styles/Unclaimed-CME-Dashboard.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import API_URL, {
  OpportunitiesLink,
  ReportURL,
  AuthorizationCredentials,
  MaxLength_FileName,
} from "../../config/config";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Typography,
  Snackbar,
  Link,
  Stack,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import apiProfiles from "../../api-operation";
import DeleteTopic from "../DeleteTopic";
import Alert from "../common/Alert";
import { v4 as uuid } from "uuid";
import EmptyUnclaimedImg from "../../images/UnclaimedEmptyList.png";
import EmptyActivityImg from "../../images/ActivityEmpty.png";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { contactContext } from "../../context/contactContext";
import { userContext } from "../../context/userContext";
import { BrowserRouter, useHistory } from "react-router-dom";
import Container from "../CMEProfileManagement/ModalContainer";
import { PDFDocument } from "pdf-lib";
import apiUsers from "../../api-operation";
import DeleteSelfReportedActivity from "../DeleteSelfReportedActivity";
import { promiseContext } from "../../context/promiseContext";
import { FileUploadOutlined } from "@mui/icons-material";
import axios from "axios";
import DeleteCMEActivity from "../DeleteCMEActivity";
import DeleteSelfReportedBlob from "../DeleteSelfReportedBlob";
import { GetRoundUpValue } from "../../utils/utils";
import { useQueryClient } from "@tanstack/react-query";
import SearchIcon from "@mui/icons-material/Search";
import {
  GetUnclaimedData,
  GetClaimedData,
  GetTopicData,
  GetActivityTypeData,
} from "../../utils/cache";
import JSZip from "jszip";
import download from "downloadjs";
import { useLocation } from "react-router-dom";
import { ContactInfo } from "../ContactInfo";
import shortid from "shortid";
import MyDatePicker from "./MyDatePicker";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
var moment = require("moment");

let msg = "",
  claimCMEHours = "0",
  emptyBlobDetails = "";
let innerHeight = 350;
let selectedABEMTransRecordList: any[] = [];
export function ClaimUnclaimCMEDashboard(claimeStyles: any, propsSource: any) {
  const [blobURL, setblobURL] = useState("");
  const [isIFrameVisible, setIsIFrameVisible] = useState(true);
  //List of Topic and Hours details will be added in CME Tracking Details
  const [topicInfo, setTopicInfo] = React.useState<iTopicInfo[]>([]);

  //Existing Topic List which will be showin in the dropdown
  const [cmeTopicList, setCmeTopicList] = React.useState<any[] | []>([]);
  const [innerHeightAddTopicDiv, setInnerHeightAddTopicDiv] =
    useState(innerHeight);

  const [disableAddTranscriptButton, setDisableAddTranscriptButton] =
    useState(true);
  // const [claimCMEHours, setClaimCMEHours] = useState<Number>(0);
  const [remainingHoursToClaim, setRemainingHoursToClaim] = useState<Number>(0);
  const [claimedExistingCMEHours, setClaimedExistingCMEHours] =
    useState<Number>(0);
  const [claimCMETrackingId, setClaimCMETrackingId] = useState<string>("");
  const [approvedByAcep, setApprovedByAcep] = useState<boolean>(false);
  const [approvedByAMA, setApprovedByAMA] = useState<boolean>(false);
  const [showLeftHandTopicHoursValid, setShowLeftHandTopicHoursValid] =
    useState<boolean>(false);
  const [claimAddTopicHours, setclaimAddTopicHours] = useState<Number>(0);
  const [current, setCurrent] = useState("");
  const [showData, setShowData] = useState(false);
  const [sortResult, setSortResult] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = React.useState(false);
  const [openAlertMsg, setOpenAlertMsg] = React.useState(false);
  const [cmeTrackingDetails, setCMETrackingDetails] = useState<any[] | []>([]);
  const [cpyCMETrackingDetails, setCpyCMETrackingDetails] = useState<
    any[] | []
  >([]);
  const [searchListData, setSearchListData] = useState<any[] | []>([]);

  //For checkbox
  const [enableTranscript, setEnableTranscript] = React.useState("#1976D2");
  const [deleteTranscriptRecords, setDeleteTranscriptRecords] = useState("");
  const [headerCheckUncheckBox, setHeaderCheckUnchekBox] = useState(false);

  //This is for when uncheck header checkbox when one of child checkbox unchecked
  //so only header checkbox should unchecked
  const [handleHeaderCheckEvent, setHandleHeaderCheckEvent] = useState(true);
  //For Self Reported Activity
  const [showSelfReportedActivity, setShowSelfReportedActivity] =
    useState(false);
  const [disableReportButtons, setDisableReportButtons] = useState(true);
  const [searchString, setSearchString] = useState("");

  //For Filters
  const [toShowSelectFiltersDiv, setToShowSelectFiltersDiv] = useState(false);
  const [toShowSelectFiltersDateRange, setToShowSelectFiltersDateRange] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState([{ label: "" }]);
  const [cmeActivityTypeList, setcmeActivityTypeList] = React.useState<
    any[] | []
  >([{ Id: -1, Name: "" }]);
  const [filterdateInput, setfilterdateInput] = useState("");
  const [filterdateRangeStartDate, setfilterdateRangeStartDate] = useState("");
  const [filterdateRangeEndDate, setfilterdateRangeEndDate] = useState("");
  const [filteractivityTypeInput, setfilteractivityTypeInput] = useState("");
  const [filtertopicInput, setfiltertopicInput] = useState("");
  const [filteraccrediationsourceInput, setfilteraccrediationsourceInput] =
    useState("");

  //Show Hide Wait Indicators using below state
  const [pageWaitIndicator, setPageWaitIndicator] = useState(false);

  //For Certificate Upload
  const [selectedfile, setSelectedFile] = React.useState("");
  const [UploadedCertificateUrl, setUploadedCertificateUrl] =
    React.useState("");

  const [
    lstSelfReportedNotAvailableCertificates,
    setLstSelfReportedNotAvailableCertificates,
  ] = useState("");
  const [bDisableExportCertButton, setDisableExportCertButton] = useState(true);
  const [bToShowExportCertButton, setToShowExportCertButton] = useState(false);
  const inputElement = React.useRef<any>();
  const [invalidFileMessage, setInvalidFileMessage] = useState(false);
  // Use an object to maintain the column sort order
  const [order, setOrder] = useState({ Date: "asc", Name: "asc" });
  const [updateAdminContact, setupdateAdminContact] = useState(false);
  const [updateContact, setupdateContact] = useState(false);

  // Create a reference to the hidden file input element
  const history = useHistory();
  const location = useLocation();
  const { contact, from } =
    (location.state as { contact: any; from: string }) || {};

  let buffer1Data = "";
  let buffer2Data: any[] = [];
  let buffer2DataFileNames: string[] = [];
  let contactData: any;
  //This is for self reported files other than pdf which returns base64 string for doc or image files
  //with names
  let buffer2DataURLToBlobFiles: any[] = [];
  let buffer2DataURLToBlobFileNames: string[] = [];

  const { contactObject, updateContactInfo } = useContext(contactContext);
  const queryClient = useQueryClient();

  //For Self Reported Edit Activity
  const [currentActivity, setCurrentActivity] = useState<any>("");
  const handleActivityNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentActivity(event.target.value);
  };

  const [currentDate, setCurrentDate] = useState("");
  const handleNewDateChange = (date: any) => {
    setCurrentDate(date.toString());
  };

  function convertToMaterialUIDateFormat(inputDate: any) {
    const parts = inputDate.split("/");
    if (parts.length === 3) {
      return `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
        2,
        "0"
      )}`;
    }
    return "";
  }

  const [currentActivitytype, setCurrentActivityType] = useState<any>("");
  const handleActivityType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentActivityType(event.target.value);
  };

  const [inputModified, setInputModified] = useState("");
  // Event handler for Activity Type
  const [activityDetails, setActivityDetails] =
    React.useState<tActivityDetails>({
      Id: uuid(),
      Name: "",
      Description: "",
      CompletionDate: "",
      MaxHours: "0",
      ClaimedHours: "0",
      CertificateUrl: "",
      CME_Activity_Id: null,
      CME_ActivityType_Id: "",
      CME_Contact_Id: "-1",
      CME_Tracking_Status_Id: 5,
    });

  //End Self Reported Edit Activity
  let promiseValue = React.useContext(promiseContext) as PromiseType;

  let userObject = useContext(userContext);

  let userData = userObject.User;
  //check user is admin or not
  let isAdmin: boolean = userData.acepMemberStatus === 2 ? true : false;
  //let isAdmin:boolean = userData.acepMemberStatus === 2 ? false : false;

  if (from === "AdminDashboard" && !updateAdminContact) {
    contactData = updateContactInfo(contact);

    removeQueriesForAdmin();

    if (contact.acepid === contactObject?.Contact?.acepid) {
      setupdateContact(true);
      setupdateAdminContact(true);
    }
  } else {
    if (userData.acepMemberStatus === 2) {
      contactData = contactObject.Contact;
      if (!updateContact) setupdateContact(true);
    } else {
      contactData = contactObject.Contact;
    }
  }

  //For Unclaimed, set true
  //For Transcript, set false
  const props_Input =
    claimeStyles.propsSource === "Unclaimed"
      ? true
      : claimeStyles.propsSource === "Transcript"
      ? false
      : "";

  //For Left Nav Height
  const ref = useRef<HTMLInputElement>(null);
  const [height, setHeight] = useState(0);
  const [loadNavHeight, setLoadNavHeight] = useState(false);

  const { data: trackingAPIData, isLoading: trackingAPICalled } =
    claimeStyles.propsSource === "Unclaimed"
      ? GetUnclaimedData(contactData?.contactid)
      : GetClaimedData(contactData?.contactid);
  const { data: topicAPIData, isLoading: topicAPICalled } = GetTopicData();
  const { data: activityTypeAPIData, isLoading: activityTypeAPICalled } =
    GetActivityTypeData();

  //This is for zip file creation
  const [zipFilesToGenerate, setZipFilesToGenerate] = useState(new JSZip());

  let commaSeperatedURLToGenerateZIP: FileData[] = [];
  interface FileData {
    fileUrl: string;
    fileName: string;
  }

  useLayoutEffect(() => {
    var HomePagebackgroundHeight =
      document.body.getElementsByClassName("HomePagebackground")[0]; // Assuming there's only one element with class 'HomePagebackground'
    var currentHeight = HomePagebackgroundHeight.clientHeight; // Get the current height of the element
    var newHeight = currentHeight + 120;
    setHeight(newHeight);
    setLoadNavHeight(false);
  }, [loadNavHeight]);

  useEffect(() => {
    getCMETopicList();
    getActivityTypeList();
    getCMETrackingUnclaimedRecords();
  }, [trackingAPIData, topicAPIData, activityTypeAPIData]);

  const getCMETrackingUnclaimedRecords = () => {
    if (
      contactData?.contactid !== "" &&
      trackingAPIData &&
      !trackingAPICalled &&
      topicAPIData &&
      !topicAPICalled &&
      activityTypeAPIData &&
      !activityTypeAPICalled
    ) {
      var rows = trackingAPIData || [];
      if (rows.length <= 0) setEnableTranscript("#DDDDDD");

      setCMETrackingDetails(rows);
      setCpyCMETrackingDetails(rows);
      setSearchListData(rows);
      setShowData(true);

      setLoadNavHeight(true);

      if (totalAPICalls === 0) {
        setCurrent("");
        //To hide the wait indicator
        claimeStyles.showSpinner(false);
      }

      if (promiseValue.promiseObject.length !== 2) {
        promiseValue.setPromiseObject((prev: any) => [
          ...prev,
          "cmetrackingunclaimed",
        ]);
        promiseValue.setPromiseObject((prev: any) => [
          ...prev,
          "cmetrackingclaimed",
        ]);
      }
    }
  };

  const getCMETopicList = () => {
    if (trackingAPIData && topicAPIData && !topicAPICalled) {
      let listcmetopic: any[] = topicAPIData.filter(
        (item: any) => item.Id !== -1
      );
      if (!listcmetopic.some((item) => item.Id === -1)) {
        listcmetopic.unshift({ Id: -1, Name: "" });
      }
      setCmeTopicList(listcmetopic);
    }
  };

  const getActivityTypeList = () => {
    if (trackingAPIData && activityTypeAPIData && !activityTypeAPICalled) {
      let listcmeactivitytypes: any[] = activityTypeAPIData.filter(
        (item) => item !== ""
      );
      setcmeActivityTypeList(listcmeactivitytypes);
    }
  };

  function redirectEducation() {
    window.open(OpportunitiesLink, "_blank");
  }

  const dvShowHideAccordianDetails = (event: any, item: IClaimdDetails) => {
    {
      let updatedData: any;

      let proceedClick = false;
      for (const [index, element] of event.target.classList.entries()) {
        if (
          element === "unclaim-claimRow-delete" ||
          element === "unclaim-dateRow" ||
          element === "unclaim-eventRow" ||
          element === "unclaim-maxHoursRow" ||
          element === "transcript-maxHoursRow" ||
          element === "unclaim-claimRow" ||
          (element === "MuiSvgIcon-root" &&
            event.target.attributes &&
            event.target.attributes["data-testid"].value !==
              "DeleteOutlineIcon" &&
            event.target.attributes["data-testid"].value !==
              "FileUploadOutlinedIcon" &&
            event.target.attributes["data-testid"].value !== "CloseIcon" &&
            event.target.attributes["data-testid"].value !== "CalendarIcon" &&
            event.target.attributes["data-testid"].value !==
              "ArrowDropDownIcon" &&
            event.target.attributes["data-testid"].value !== "ArrowRightIcon" &&
            event.target.attributes["data-testid"].value !== "ArrowLeftIcon")
        ) {
          proceedClick = true;
          break;
        }
      }

      if (proceedClick) {
        if (current !== item.Id) {
          //for each time, clear the CertificateUrl variable
          //this variable will set value on click of file upload control
          //Based on this value, will decide to call postFile function to upload file or not on SAVE button click
          setUploadedCertificateUrl("");
          //end
          setInvalidFileMessage(false);
          setCurrent(item.Id);
          claimCMEHours = props_Input
            ? "0"
            : item.RemainingHoursToClaim.toString(); //ToDo
          setclaimAddTopicHours(0);
          item.IsExpand = true;
        } else {
          item.IsExpand = false;
          setCurrent("");
        }

        //YT   set current value for edit
        setCurrentActivity(item.Name);
        //Yt set current value for completion Date
        setCurrentDate(
          convertToMaterialUIDateFormat(item.FormatCompletionDate)
        );
        // Yt set current value for activity type
        setCurrentActivityType(item.CME_ActivityType_Id);

        updatedData = cmeTrackingDetails.map((obj) => {
          if (item.Id == obj.Id && item.IsExpand) {
            return { ...obj, IsExpand: true };
          } else {
            return { ...obj, IsExpand: false };
          }
        });

        setCMETrackingDetails(updatedData);

        if (item.ApprovedByACEP) setApprovedByAcep(true);
        else setApprovedByAcep(false);

        if (item.ApprovedByAMA) setApprovedByAMA(true);
        else setApprovedByAMA(false);

        if (props_Input) {
          //fill empty topic record each time when expanded/selected activity record Unclaimed CME dashboard
          if (item.ApprovedByAMA) {
            setTopicInfo([
              {
                NewUID: uuid(),
                TopicId: 23,
                TopicHours: claimCMEHours,
                IsValidTopicName: true,
                IsValidTopicHours: true,
                IsValidAMAPRATopic: true,
                RecordID: "-1",
                IsUpdated: true,
              },
            ]);
            setInnerHeightAddTopicDiv(350);
          } else {
            setTopicInfo([]);
            setInnerHeightAddTopicDiv(350);
          }
        } else {
          //reset the delete record id when expanded the activity
          setDeleteTranscriptRecords("");

          //fill the topic infor for selected/expanded activity record on Most Recent Activites dashboard
          //loop for all saved Topic records
          setTopicInfo(
            item.CME_Tracking_Details.map((ele: any) => ({
              NewUID: ele.Id,
              TopicId: ele.CME_Topic_Id,
              TopicHours: ele.Hours,
              IsValidTopicName: true,
              IsValidTopicHours: true,
              IsValidAMAPRATopic: ele.CME_Topic_Id === 23 ? true : false,
              RecordID: item.Id,
              IsUpdated: false,
            }))
          );

          var calculateHeight =
            100 *
            (item.CME_Tracking_Details.filter(
              (ele: { CME_Topic_Id: number }) => ele.CME_Topic_Id !== 1
            ).length -
              2);

          const innerHeight =
            (item.CME_Tracking_Status_Id === 5 ? 610 : 400) +
            (calculateHeight > 0 ? calculateHeight : 0);

          setInnerHeightAddTopicDiv(
            item.CME_Tracking_Details.length > 0
              ? innerHeight < 350
                ? 350
                : innerHeight
              : item.CME_Tracking_Status_Id === 5
              ? 610
              : 350
          );
        }

        event.preventDefault();
        setClaimCMETrackingId(item.Id);
        setClaimedExistingCMEHours(Number(item.ClaimedHours));
        setRemainingHoursToClaim(
          props_Input
            ? Number(item.RemainingHoursToClaim)
            : Number(item.MaxHours)
        );
      }
    }
    //this will adjust the heigh on dashboard
    setLoadNavHeight(true);
  };

  const GetSetTopicInfo = (Id: Number, Hours: string) => {
    setTopicInfo((prevTopic) => [
      ...prevTopic,
      {
        NewUID: uuid(),
        TopicId: Id,
        TopicHours: Hours,
        IsValidTopicName: true,
        IsValidTopicHours: true,
        IsValidAMAPRATopic: false,
        RecordID: "-1",
        IsUpdated: false,
      },
    ]);
  };

  const onAddTopicInfo = (event: any, item: any) => {
    event.preventDefault();
    GetSetTopicInfo(-1, "0");
    setDisableAddTranscriptButton(true);

    const innerHeight =
      (item.CME_Tracking_Status_Id === 5 ? 610 : 400) +
      100 *
        (item.CME_Tracking_Status_Id === 5
          ? topicInfo.filter((data) => data.TopicId !== 1).length - 1
          : approvedByAcep
          ? topicInfo.filter((data) => data.TopicId !== 1).length - 1
          : topicInfo.filter((data) => data.TopicId !== 1).length - 1);

    setInnerHeightAddTopicDiv(
      topicInfo.length > 0
        ? innerHeight < 350
          ? 350
          : innerHeight
        : item.CME_Tracking_Status_Id === 5
        ? 610
        : 350
    );
  };

  const roundUpClaimedHours = (event: any) => {
    let roundUpValue = GetRoundUpValue(event);

    let newTopicInfo = topicInfo.map((el) =>
      parseFloat(el.TopicHours) > parseFloat(roundUpValue)
        ? {
            ...el,
            IsValidTopicHours:
              el.TopicId === 23 || el.TopicId === 1 ? true : false,
            TopicHours:
              el.TopicId === 23 || el.TopicId === 1
                ? roundUpValue
                : el.TopicHours,
            IsUpdated: el.TopicId === 23 || el.TopicId === 1 ? true : false,
          }
        : {
            ...el,
            IsValidTopicHours: true,
            TopicHours:
              el.TopicId === 23 || el.TopicId === 1
                ? roundUpValue
                : el.TopicHours,
            IsUpdated: el.TopicId === 23 || el.TopicId === 1 ? true : false,
          }
    );
    setTopicInfo(newTopicInfo);
    claimCMEHours = roundUpValue;
  };

  const roundUpTopicHours = (
    event: any,
    iIndex: number,
    key: "TopicId" | "TopicHours"
  ) => {
    let roundUpValue = GetRoundUpValue(event);

    let selectedVal = roundUpValue;
    const topicByIndex = topicInfo[iIndex];

    validateTopicInfoDetails(selectedVal, iIndex, topicByIndex, key, true);
  };

  const onChangeCMEHoursText = (event: any) => {
    event.preventDefault();
    setShowLeftHandTopicHoursValid(true);

    //trim the input to remove blank spaces enterd
    const iClaimCMEHours = event.target.value.trim();

    //Check if user entered invalid input other than Numberic/Decimal values
    if (!isNaN(iClaimCMEHours)) {
      //Claimed Hours are changed
      //so lets check If Aprooved by ACEP
      //then accordignly set the updated hours
      let newTopicInfo = topicInfo.map((el) =>
        parseFloat(el.TopicHours) > parseFloat(iClaimCMEHours)
          ? {
              ...el,
              IsValidTopicHours:
                el.TopicId === 23 || el.TopicId === 1 ? true : false,
              TopicHours:
                el.TopicId === 23 || el.TopicId === 1
                  ? iClaimCMEHours
                  : el.TopicHours,
              IsUpdated: el.TopicId === 23 || el.TopicId === 1 ? true : false,
            }
          : {
              ...el,
              IsValidTopicHours: true,
              TopicHours:
                el.TopicId === 23 || el.TopicId === 1
                  ? iClaimCMEHours
                  : el.TopicHours,
              IsUpdated: el.TopicId === 23 || el.TopicId === 1 ? true : false,
            }
      );

      setTopicInfo(newTopicInfo);

      // Here, we need to check the all the Hours Values added by user in right section
      //and need to show the error message
      // setTopicInfo(newTopicInfo)

      claimCMEHours = iClaimCMEHours;
      setAddTrnascriptButtonState();
    }
  };

  const selectedTopicIdExists = (Id: number, Index: number): boolean => {
    return topicInfo
      .filter((el, i) => i !== Index)
      .some((el) => el.TopicId === Id && el.IsValidTopicName === true);
  };

  //This function will call when Added Topic Details gets changed by User. It could be Hours or Topic Name
  const onChangeAddedTopicDetails = (
    event: any,
    iIndex: number,
    key: "TopicId" | "TopicHours"
  ) => {
    let selectedVal = event.target.value.trim();
    const topicByIndex = topicInfo[iIndex];

    //If Topic Hours are not a number then ignore the further process
    if ((key === "TopicHours" && !isNaN(selectedVal)) || key === "TopicId") {
      validateTopicInfoDetails(selectedVal, iIndex, topicByIndex, key, true);
    }
  };

  const validateTopicInfoDetails = (
    selectedVal: string,
    iIndex: number,
    topicByIndex: iTopicInfo,
    key: "TopicId" | "TopicHours",
    IsUpdated?: false | true
  ) => {
    if (key === "TopicHours") {
      const iAddTopicHours = selectedVal;

      if (
        parseFloat(iAddTopicHours) > parseFloat(claimCMEHours) ||
        parseFloat(iAddTopicHours) <= 0
      ) {
        topicByIndex["IsValidTopicHours"] = false;
      } else {
        topicByIndex["IsValidTopicHours"] = true;
      }
      topicByIndex[key] = selectedVal;
    }

    if (key === "TopicId") {
      if (
        selectedTopicIdExists(Number(selectedVal), iIndex) ||
        selectedVal == "-1"
      ) {
        topicByIndex["IsValidTopicName"] = false;
      } else {
        topicByIndex["IsValidTopicName"] = true;
      }

      topicByIndex[key] = Number(selectedVal);
    }

    //This will update the changed value in the topicinfo array
    if (topicByIndex.RecordID === "-1" || IsUpdated)
      topicByIndex["IsUpdated"] = true;

    setTopicInfo([
      ...topicInfo.slice(0, iIndex),
      topicByIndex,
      ...topicInfo.slice(iIndex + 1, topicInfo.length),
    ]);

    setAddTrnascriptButtonState();
  };

  const onSubmit =
    (
      NewUID: string,
      iIndex: Number,
      RecordID: string,
      selectedItemStatusID: number
    ) =>
    (event: any) => {
      event.preventDefault();

      setTopicInfo((prevAddedTopicInfo) => {
        return prevAddedTopicInfo.filter((value, i) => value.NewUID !== NewUID);
      });

      setAddTrnascriptButtonState();
      setInnerHeightAddTopicDiv((innerHeight) =>
        innerHeight - 100 < (selectedItemStatusID === 5 ? 610 : 350)
          ? selectedItemStatusID === 5
            ? 610
            : 350
          : innerHeight - 100
      );

      //in case of Transcript, add deleted id in the array to actulal delete records on save button click
      if (!props_Input && RecordID !== "-1") {
        //To show the wait indicator
        claimeStyles.showSpinner(true);

        apiProfiles.url = "/cmetrackingdetail";
        apiProfiles.remove(NewUID).then((data: any) => {
          removeQueries(["claimedCMEFilterData", "claimedCMEData"]);
          event.target[0].click();
          msg = "Transcript Activity Processed Successfully!";
          setOpenAlertMsg(true);
        });
      } else {
        //To delete the records who has not submitted yet
        //To hide the wait indicator if api call not made
        claimeStyles.showSpinner(false);
      }
    };

  const setAddTrnascriptButtonState = (): boolean => {
    if (
      topicInfo.some(
        (details: iTopicInfo) =>
          parseFloat(claimCMEHours) < parseFloat(details.TopicHours) ||
          details.TopicId == -1 ||
          details.TopicHours == "" ||
          Number(details.TopicHours) == 0 ||
          claimCMEHours == "0" ||
          parseFloat(claimCMEHours) > remainingHoursToClaim ||
          !details.IsValidTopicHours ||
          !details.IsValidTopicName ||
          dayjs(currentDate).utc() > dayjs(new Date()).utc()
      )
    ) {
      setDisableAddTranscriptButton(true);
      return true;
    } else {
      setDisableAddTranscriptButton(false);
      return false;
    }
  };

  let totalAPICalls = 0;
  const onAddTranscriptDetails = (event: any) => {
    //To show the wait indicator
    claimeStyles.showSpinner(true);
    let isAPICalled = false;
    totalAPICalls = 0;
    if (setAddTrnascriptButtonState()) {
      //To hide the wait indicator
      claimeStyles.showSpinner(false);

      topicInfo.map((element, index) => {
        setShowLeftHandTopicHoursValid(true);

        if (
          !(
            element.TopicId === 23 &&
            approvedByAMA &&
            element.IsValidAMAPRATopic
          )
        ) {
          validateTopicInfoDetails(
            element.TopicId.toString(),
            index,
            topicInfo[index],
            "TopicId",
            false
          );
          validateTopicInfoDetails(
            element.TopicHours.toString(),
            index,
            topicInfo[index],
            "TopicHours",
            false
          );
        }
      });
    } else {
      setShowLeftHandTopicHoursValid(false);
      //This will check user session is active or not
      localStorage.setItem("loadSSOData", "1");
      event.preventDefault(event);
      let requestOptions: any = {};

      let savedActivityName; // YT
      let savedActivityType; //YT

      if (!props_Input) {
        //To check that CME Tracking Hours updated or not
        if (
          Number(claimCMEHours) !== claimedExistingCMEHours ||
          UploadedCertificateUrl.length > 0 ||
          (currentActivity && currentActivity?.length > 0) ||
          (currentActivitytype && currentActivitytype?.length > 0) ||
          (currentDate && currentDate.length > 0)
        ) {
          isAPICalled = true;
          totalAPICalls += 1;
          let method = "PUT";

          if (UploadedCertificateUrl.length > 0) {
            postFile();
            requestOptions = {
              Id: claimCMETrackingId,
              ClaimedHours: Number(claimCMEHours),
              Certificate_url: UploadedCertificateUrl,
              Name: currentActivity, //YT
              CompletionDate: currentDate, //YT
              CME_ActivityType_Id: currentActivitytype, //YT
            };
          } else {
            requestOptions = {
              Id: claimCMETrackingId,
              ClaimedHours: Number(claimCMEHours),
              Name: currentActivity, //YT
              CompletionDate: currentDate, //YT
              CME_ActivityType_Id: currentActivitytype, //YT
            };
          }

          executeAPI(
            requestOptions,
            "/cmetracking/" + claimCMETrackingId,
            method
          );
        }

        //To Add the Transcript Topic Details
        if (
          topicInfo &&
          topicInfo.length > 0 &&
          topicInfo.filter((res) => res.RecordID == "-1").length > 0
        ) {
          isAPICalled = true;
          totalAPICalls += 1;
          let method = "POST";
          requestOptions = {
            CME_Tracking_Id: claimCMETrackingId,
            CME_Claim_Hours: 0,
            CME_Tracking_Status: 0,
            CME_Existing_Claimed_Hours: claimedExistingCMEHours,
            CME_topic_info: topicInfo
              .filter((res) => res.RecordID == "-1" && res.TopicHours !== "0")
              .map(
                ({ IsValidTopicHours, IsValidTopicName, RecordID, ...item }) =>
                  item
              ),
          };
          executeAPI(requestOptions, "/cmetrackingdetail", method);
        }

        //To Update the Transcript Topic Details
        if (
          topicInfo &&
          topicInfo.length > 0 &&
          topicInfo.filter((res) => res.RecordID !== "-1" && res.IsUpdated)
            .length > 0
        ) {
          isAPICalled = true;
          totalAPICalls += 1;
          let method = "PUT";
          requestOptions = {
            CME_Tracking_Details: topicInfo
              .filter((res) => res.RecordID !== "-1" && res.IsUpdated)
              .map(
                ({ IsValidTopicHours, IsValidTopicName, RecordID, ...item }) =>
                  item
              ),
          };

          executeAPI(requestOptions, "/cmetrackingdetail", method);
        }
      } else if (props_Input) {
        let newTopicInfo = topicInfo.slice();

        if (approvedByAcep) {
          let acepObject = {
            NewUID: uuid(),
            TopicId: 1,
            TopicHours: claimCMEHours,
            IsValidTopicName: true,
            IsValidTopicHours: true,
            IsValidAMAPRATopic: false,
            RecordID: "-1",
            IsUpdated: true,
          };
          newTopicInfo.push(acepObject);
        }

        isAPICalled = true;
        totalAPICalls += 1;
        let method = "POST";
        requestOptions = {
          CME_Tracking_Id: claimCMETrackingId,
          CME_Claim_Hours: claimCMEHours,
          //StatusEnum = {"Active": 1,"Confirmed":3, "Pending":4, "Manual":5,"Finalized" : 6};
          CME_Tracking_Status: 3,
          CME_Existing_Claimed_Hours: claimedExistingCMEHours,
          CME_topic_info: newTopicInfo
            .filter((res) => res.TopicHours !== "0")
            .map(({ IsValidTopicHours, IsValidTopicName, ...item }) => item),
        };

        executeAPI(requestOptions, "/cmetrackingdetail", method);
      }
    }

    if (!isAPICalled) {
      //To hide the wait indicator if api call not made
      claimeStyles.showSpinner(false);
    }
  };

  const executeAPI = (requestOptions: any, apiPath: string, method: string) => {
    apiProfiles.url = apiPath;
    if (method === "PUT") {
      apiProfiles.put(requestOptions).then(() => {
        msg = "Transcript Activity Processed Successfully!";
        setOpenAlertMsg(true);

        totalAPICalls -= 1;

        if (totalAPICalls === 0)
          removeQueries(
            props_Input
              ? [
                  "unclaimedCMEFilterData",
                  "unclaimedCMEData",
                  "claimedCMEFilterData",
                  "claimedCMEData",
                ]
              : ["claimedCMEFilterData", "claimedCMEData"]
          );
      });
    } else {
      if (method === "POST") {
        apiProfiles.post(requestOptions).then(() => {
          msg = "Transcript Activity Processed Successfully!";
          setOpenAlertMsg(true);

          totalAPICalls -= 1;

          if (totalAPICalls === 0) {
            removeQueries(
              props_Input
                ? [
                    "unclaimedCMEFilterData",
                    "unclaimedCMEData",
                    "claimedCMEFilterData",
                    "claimedCMEData",
                  ]
                : ["claimedCMEFilterData", "claimedCMEData"]
            );
          }
        });
      }
    }
  };

  function removeQueries(removeQuerriesList: string[] | string[][]) {
    removeQuerriesList.map((item) => {
      queryClient.removeQueries({ queryKey: [item] });
    });
    promiseValue.setPromiseObject([]);

    getCMETrackingUnclaimedRecords();
  }

  function removeQueriesForAdmin() {
    queryClient.removeQueries({ queryKey: ["unclaimedCMEFilterData"] });
    queryClient.removeQueries({ queryKey: ["claimedCMEFilterData"] });
    queryClient.removeQueries({ queryKey: ["claimedCMEData"] });
    queryClient.removeQueries({ queryKey: ["unclaimedCMEData"] });
    queryClient.removeQueries({ queryKey: ["generalRequirementData"] });
    queryClient.removeQueries({ queryKey: ["requirementData"] });
    queryClient.removeQueries({ queryKey: ["profileData"] });
    promiseValue.setPromiseObject([]);
  }

  const onchangeDateRange = (ev: any) => {
    ev.preventDefault();

    if (ev.target.value == "Date Range") setToShowSelectFiltersDateRange(true);
    else setToShowSelectFiltersDateRange(false);
  };

  const onSubmitFiltersOption = (ev: any) => {
    //Filter For date Input
    const dateInput =
      ev.currentTarget.getElementsByClassName("filters-date")[0].value; //ev.target[0].value;
    setfilterdateInput(dateInput);

    const dateRangeStartDate: string = ev.currentTarget
      .getElementsByClassName("filters-start-date")[0]
      .getElementsByTagName("input")[0].value; // moment(ev.target[1].value).format('MM/DD/YYYY')||'';
    setfilterdateRangeStartDate(dateRangeStartDate);

    const dateRangeEndDate: string = ev.currentTarget
      .getElementsByClassName("filters-end-date")[0]
      .getElementsByTagName("input")[0].value; //moment(ev.target[4].value).format('MM/DD/YYYY')||'';
    setfilterdateRangeEndDate(dateRangeEndDate);

    if (
      dateInput == "Date Range" &&
      (dateRangeStartDate === "Invalid date" ||
        dateRangeStartDate === "MM/DD/YYYY" ||
        dateRangeEndDate === "Invalid date" ||
        dateRangeEndDate === "MM/DD/YYYY")
    ) {
      ev.preventDefault(ev);

      if (
        dateRangeStartDate === "Invalid date" ||
        dateRangeStartDate === "MM/DD/YYYY"
      ) {
        ev.currentTarget.getElementsByClassName(
          "date-picker-modal"
        )[0].style.display = "block";
      }

      if (
        dateRangeEndDate === "Invalid date" ||
        dateRangeEndDate === "MM/DD/YYYY"
      ) {
        ev.currentTarget.getElementsByClassName(
          "end-date-picker-modal"
        )[0].style.display = "block";
      }
    } else {
      //For each time, apply the filters on actual list
      // setCpyCMETrackingDetails(cmeTrackingDetails)
      //here will handle to show the filtes div
      setToShowSelectFiltersDiv(true);

      //hide date range div
      setToShowSelectFiltersDateRange(false);

      ev.preventDefault(ev);

      //Filter for Activity Type
      const activityTypeInput = ev.currentTarget.getElementsByClassName(
        "filters-activity-type"
      )[0].value; //ev.target[7].value;
      setfilteractivityTypeInput(activityTypeInput);

      //Filter for Topic Type
      const topicInput =
        ev.currentTarget.getElementsByClassName("filters-topic")[0].value; //ev.target[8].value;
      setfiltertopicInput(topicInput);

      //Filter for accreditation source
      const accreditationSourceInput = ev.currentTarget.getElementsByClassName(
        "filters-accreditation-source"
      )[0].value; //ev.target[9].value;
      setfilteraccrediationsourceInput(accreditationSourceInput);

      filterAllActivities(
        dateInput,
        dateRangeStartDate,
        dateRangeEndDate,
        activityTypeInput,
        topicInput,
        accreditationSourceInput
      );
      //This will trigger the cancel click on the modal pop up and will close the pop up
      // ev.target[10].click();
      ev.currentTarget.getElementsByClassName("model-cancel-button")[0].click();
      setLoadNavHeight(true);
    }
  };

  //Apply All Filters based on user selection
  const filterAllActivities = (
    dateInput: string,
    dateRangeStartDate: string,
    dateRangeEndDate: string,
    activityTypeInput: string,
    topicInput: string,
    accreditationSourceInput: string
  ) => {
    var filtered: any;
    var objFilterName = { label: "" };
    var arrFilterToAPply = [];

    //apply date filters
    //push the selected date specific text to the filters text div
    //push the selected date specific itm only iff it is selected from Filters Modal Pop up
    //do not push the selected item if it is removed from the list by end user
    if (dateInput == "Date Range") {
      objFilterName.label =
        "Date: " + dateRangeStartDate + "-" + dateRangeEndDate + "";
      arrFilterToAPply.push(objFilterName);

      //filter result based on date range
      filtered = cpyCMETrackingDetails.filter(
        (data) =>
          new Date(moment(data.CompletionDate).format("MM/DD/YYYY")) >=
            new Date(dateRangeStartDate) &&
          new Date(moment(data.CompletionDate).format("MM/DD/YYYY")) <=
            new Date(dateRangeEndDate)
      );
      //update record list
      setCMETrackingDetails(filtered);
    } else if (dateInput == "Last 30 Days") {
      objFilterName.label = "Date: " + dateInput + "";
      arrFilterToAPply.push(objFilterName);

      var today = new Date();
      setfilterdateRangeEndDate(moment(today).format("MM/DD/YYYY"));

      var priorDate = new Date(new Date().setDate(today.getDate() - 30));
      setfilterdateRangeStartDate(moment(priorDate).format("MM/DD/YYYY"));

      //filter result based on date range
      filtered = cpyCMETrackingDetails.filter(
        (data) =>
          new Date(moment(data.CompletionDate).format("MM/DD/YYYY")) >=
            priorDate &&
          new Date(moment(data.CompletionDate).format("MM/DD/YYYY")) <= today
      );
      //update record list
      setCMETrackingDetails(filtered);
    } else if (dateInput == "Last 6 Months") {
      objFilterName.label = "Date: " + dateInput + "";
      arrFilterToAPply.push(objFilterName);

      var today = new Date();
      setfilterdateRangeEndDate(moment(today).format("MM/DD/YYYY"));

      var priorDate = new Date(new Date().setDate(today.getDate() - 183));
      setfilterdateRangeStartDate(moment(priorDate).format("MM/DD/YYYY"));

      //filter result based on date range
      filtered = cpyCMETrackingDetails.filter(
        (data) =>
          new Date(moment(data.CompletionDate).format("MM/DD/YYYY")) >=
            priorDate &&
          new Date(moment(data.CompletionDate).format("MM/DD/YYYY")) <= today
      );
      //update record list
      setCMETrackingDetails(filtered);
    } else if (dateInput == "Year To Date (YTD)") {
      objFilterName.label = "Date: " + dateInput + "";
      arrFilterToAPply.push(objFilterName);

      var today = new Date();
      setfilterdateRangeEndDate(moment(today).format("MM/DD/YYYY"));

      var priorDate = new Date(new Date(today.getFullYear(), 0, 1));
      setfilterdateRangeStartDate(moment(priorDate).format("MM/DD/YYYY"));

      //filter result based on date range
      filtered = cpyCMETrackingDetails.filter(
        (data) =>
          new Date(moment(data.CompletionDate).format("MM/DD/YYYY")) >=
            priorDate &&
          new Date(moment(data.CompletionDate).format("MM/DD/YYYY")) <= today
      );
      //update record list
      setCMETrackingDetails(filtered);
    } else if (dateInput == "All Time") {
      setfilterdateRangeEndDate("");
      setfilterdateRangeStartDate("");

      //  objFilterName.label = 'Date: ' +dateInput+''
      //arrFilterToAPply.push(objFilterName)
      filtered = cpyCMETrackingDetails;
      setCMETrackingDetails(cpyCMETrackingDetails);
    } else if (dateInput == "Date Filter Deleted") {
      setfilterdateRangeEndDate("");
      setfilterdateRangeStartDate("");
      filtered = cpyCMETrackingDetails;
      setCMETrackingDetails(cpyCMETrackingDetails);
    }
    objFilterName = { label: "" };

    //apply activity type filters
    //push the selected activity type text in the list of filters text div
    //push the selected activity type specific item only iff it is selected from Filters Modal Pop up
    //do not push the selected item if it is removed from the list by end user
    if (activityTypeInput === "Activity Types Filter Deleted") {
    } else if (activityTypeInput === "All Activity Types") {
      // objFilterName.label = 'Activity Type: '+ activityTypeInput;
      //arrFilterToAPply.push(objFilterName)
    } //this is the condition where user selected the activity type from the list of dropdown
    else if (activityTypeInput !== "All Activity Types") {
      //filter result based on selected activity type
      filtered = filtered.filter(
        (data: any) => data.CME_ActivityType_Id === Number(activityTypeInput)
      );
      //update record list
      setCMETrackingDetails(filtered);

      //this will update the selected activity in the filters div
      var objCMEActityType = cmeActivityTypeList.filter(
        (data) => data.Id === Number(activityTypeInput)
      );
      objFilterName.label = "Activity Type: " + objCMEActityType[0].Name;
      arrFilterToAPply.push(objFilterName);
    }
    objFilterName = { label: "" };

    //apply selected topic filters
    //Here will apply the selected filtes text
    //push the selected topic specific item only iff it is selected from Filters Modal Pop up
    //do not push the selected item if it is removed from the list by end user
    if (topicInput === "Topics Filter Deleted") {
    } else if (topicInput === "All Topics") {
      // objFilterName.label = 'Topic: '+topicInput;
      // arrFilterToAPply.push(objFilterName)
    } //This is the case when user selected the topic list from dropdown
    else if (topicInput !== "All Topics") {
      //filter result based on selected topic
      filtered = filtered.filter((data: any) => {
        return data.CME_Tracking_Details.some(function (element: any) {
          return element.CME_Topic_Id === Number(topicInput);
        });
      });
      //update record list
      setCMETrackingDetails(filtered);

      //This will update the selected topic in the Filters div
      objCMEActityType = cmeTopicList
        .filter((item: any) => item.Id !== -1)
        .filter((data) => data.Id === Number(topicInput));

      objFilterName.label = "Topic: " + objCMEActityType[0].Name;
      arrFilterToAPply.push(objFilterName);
    }

    objFilterName = { label: "" };

    if (accreditationSourceInput === "Accreditation Source Filter Deleted") {
    } else if (accreditationSourceInput === "All Accreditation Sources") {
      // objFilterName.label = 'Accreditation Source: '+ accreditationSourceInput;
      // arrFilterToAPply.push(objFilterName)
    } else if (accreditationSourceInput === "ACEP Accredited") {
      //filter result based on acep Accredited
      filtered = filtered.filter(
        (data: any) => data.AccreditedProvider === "ACEP"
      );
      //update record list
      setCMETrackingDetails(filtered);

      //this will update the selected activity in the filters div
      //var objCMEActityType = (cmeActivityTypeList.filter(data => data.Id === Number(activityTypeInput)))
      objFilterName.label = "Accreditation Source: " + accreditationSourceInput;
      arrFilterToAPply.push(objFilterName);
    } else if (accreditationSourceInput === "Self-Reported") {
      //filter result based on acep Accredited
      filtered = filtered.filter(
        (data: any) =>
          data.AccreditedProvider === null && data.CME_Tracking_Status_Id === 5
      );
      //update record list
      setCMETrackingDetails(filtered);

      //this will update the selected activity in the filters div
      //var objCMEActityType = (cmeActivityTypeList.filter(data => data.Id === Number(activityTypeInput)))
      objFilterName.label = "Accreditation Source: " + accreditationSourceInput;
      arrFilterToAPply.push(objFilterName);
    }

    objFilterName = { label: "" };

    setSearchListData(filtered);
    //setUpdateData(false)
    setSelectedOption(arrFilterToAPply);

    setLoadNavHeight(true);
    setHeaderCheckUnchekBox(false);
    setDisableReportButtons(true);

    if (searchString !== "" && searchString !== undefined) {
      searchCMETrackingDetails(filtered, searchString);
    }
  };

  //On Remove the Selected Filters
  const handleFiltersRemove = (ev: any, selectedItemToRemove: any) => {
    // setUpdateData(false)
    //ev.preventDefault();
    selectedOption.splice(
      selectedOption.findIndex((object) => {
        return object.label === selectedItemToRemove;
      }),
      1
    );

    setSelectedOption(selectedOption);

    //call filterAllActivities() to apply filetrs
    let itemToRemoveFilter: string =
      selectedItemToRemove.split(":")[0].trim() || "";

    if (itemToRemoveFilter === "Date") {
      setfilterdateInput("Date Filter Deleted");
      itemToRemoveFilter = "Date Filter Deleted";
      filterAllActivities(
        itemToRemoveFilter,
        filterdateRangeStartDate,
        filterdateRangeEndDate,
        filteractivityTypeInput,
        filtertopicInput,
        filteraccrediationsourceInput
      );
    }

    if (itemToRemoveFilter === "Activity Type") {
      setfilteractivityTypeInput("Activity Types Filter Deleted");
      itemToRemoveFilter = "Activity Types Filter Deleted";
      filterAllActivities(
        filterdateInput,
        filterdateRangeStartDate,
        filterdateRangeEndDate,
        itemToRemoveFilter,
        filtertopicInput,
        filteraccrediationsourceInput
      );
    }

    if (itemToRemoveFilter === "Topic") {
      setfiltertopicInput("Topics Filter Deleted");
      itemToRemoveFilter = "Topics Filter Deleted";
      filterAllActivities(
        filterdateInput,
        filterdateRangeStartDate,
        filterdateRangeEndDate,
        filteractivityTypeInput,
        itemToRemoveFilter,
        filteraccrediationsourceInput
      );
    }

    if (itemToRemoveFilter === "Accreditation Source") {
      setfilteraccrediationsourceInput("Accreditation Source Filter Deleted");
      itemToRemoveFilter = "Accreditation Source Filter Deleted";
      filterAllActivities(
        filterdateInput,
        filterdateRangeStartDate,
        filterdateRangeEndDate,
        filteractivityTypeInput,
        filtertopicInput,
        itemToRemoveFilter
      );
    }

    setLoadNavHeight(true);
  };

  //Export Transcript Button Click
  const exportTranscriptButtonClickEvent = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    ev.preventDefault();

    if (claimeStyles.claimeStyles.pointer === "auto") {
      //To show the wait indicator
      claimeStyles.showSpinner(true);

      const selectedTransRecords = cmeTrackingDetails
        .filter((data) => {
          return data.IsSelected === true;
        })
        .map(({ Id }) => Id.toUpperCase())
        .join(",");

      const selectedTopic = cmeTopicList
        .filter((item: any) => item.Id !== -1)
        .filter((data) => data.Id === Number(filtertopicInput));
      const topicFilter =
        selectedTopic.length > 0 && filtertopicInput !== "All Topics"
          ? selectedTopic[0].Name
          : "All";
      const promise1 = callReportForBufer1("cmetracker_transcript", [
        { name: "ContactId", value: contactData?.contactid },
        {
          name: "CMETrackingId",
          value: selectedTransRecords.length > 0 ? selectedTransRecords : "All",
        },
        { name: "Topic", value: topicFilter },
        {
          name: "StartDate",
          value:
            filterdateRangeStartDate.length > 0
              ? filterdateRangeStartDate
              : "1/1/1900",
        },
        {
          name: "EndDate",
          value:
            filterdateRangeEndDate.length > 0
              ? filterdateRangeEndDate
              : "1/1/2900",
        },
      ]);
      const promieArray = [promise1];
      fetchAsyncData(promieArray, [], "Transcript");
    }
  };

  //On Delete Self Reported Activity Button CLick
  const onDeleteSelfActivityClick =
    (selectedTranscriptRecordID: string, certificateURL: string) =>
    (event: any) => {
      totalAPICalls = 0;
      //To show the wait indicator
      claimeStyles.showSpinner(true);

      //This will check user session is active or not
      localStorage.setItem("loadSSOData", "1");
      event.preventDefault();

      //Close the pop up window for admin and normal user
      event.target[1].click();

      //this will delete the self reported activity
      if (certificateURL && !certificateURL?.toString()?.startsWith("http")) {
        //This will update the CME Tracking record with empty value as CertificateURL
        // let method = 'PUT';
        // let requestOptions = {
        //                         Id: claimCMETrackingId,
        //                         Certificate_url: ""
        // };

        // executeAPI(requestOptions,"cmetracking/"+claimCMETrackingId, method);

        //This will delete the blob
        var acepid =
          userData.acepMemberStatus === 2
            ? contactData.acepid
            : userData.acepid;
        const url =
          API_URL +
          "/deleteBlob/" +
          acepid.toLocaleLowerCase() +
          "?filepath=" +
          certificateURL;
        axios.delete(url);
      }
      apiProfiles.url = "CMETracking";

      var queryStringpara =
        userData.acepMemberStatus === 2
          ? selectedTranscriptRecordID + "/" + userData.formalName
          : selectedTranscriptRecordID;

      apiProfiles.remove(queryStringpara).then(() => {
        removeQueries(["claimedCMEFilterData", "claimedCMEData"]);

        msg = "Activity Record Deleted Successfully!";
        setOpenAlertMsg(true);
        //setCurrent("")

        const newCME: any = cmeTrackingDetails.filter(
          (ele) => ele.Id !== selectedTranscriptRecordID
        );
        setCMETrackingDetails(newCME);
      });
    };

  //Export Certificate Button Click
  async function exportCertButtonClickEvent(
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    ev.preventDefault();

    if (claimeStyles.claimeStyles.pointer === "auto") {
      setUploadedCertificateUrl("");

      setLstSelfReportedNotAvailableCertificates("");
      setDisableExportCertButton(true);
      setToShowExportCertButton(false);
      try {
        //To show the wait indicator
        claimeStyles.showSpinner(true);

        const selectedTransRecords = cmeTrackingDetails
          .filter((data) => {
            return (
              data.IsSelected === true &&
              ((data.CME_Tracking_Status_Id !== 5 &&
                data.CertificateUrl === "") ||
                data.CertificateUrl === null) &&
              data.Host !== "ABEM"
            );
          })
          .sort((a, b) => a.Id.localeCompare(b.Id)) //This will sort the records based on Id similar in SQL
          .map(({ Id }) => Id.toUpperCase())
          .join(",");

        //This will include the Self Reported selected activites + ABEM Selected Activiteis
        //As for ABEM Activities, there is not ACEP Generatd Certificat
        //So it will also consider similar to the Self Reported Activity
        const selectedSelfReportedTransRecordList = cmeTrackingDetails.filter(
          (data) => {
            return (
              (data.IsSelected === true && data.CME_Tracking_Status_Id === 5) ||
              (data.IsSelected === true &&
                data.CertificateUrl &&
                data.CertificateUrl !== "") ||
              (data.Host === "ABEM" && data.IsSelected === true)
            );
          }
        );

        //This will fetch the record list for selected ABEM Activities who do not have any Certificate URL
        selectedABEMTransRecordList = cmeTrackingDetails.filter(
          (data) =>
            data.Host === "ABEM" &&
            data.IsSelected === true &&
            (data.CertificateUrl === null || data.CertificateUrl === "")
        );

        var pattern = /^((http|https|ftp):\/\/)/;

        //This will give the list of records whose Certificate URL is having pdf
        const selectedSelfReportedTransRecords =
          selectedSelfReportedTransRecordList
            .filter(
              (data) => (data.CertificateUrl || "").length > 0 //&& ((data.CertificateUrl||'').toLocaleLowerCase().indexOf(".pdf") !== -1 )
            )
            .map(({ CertificateUrl }) => CertificateUrl)
            .join(",");

        //This will get the base64 string for the selected transcript records other than self reported

        let promise1;

        //This is for if user selected non self reported records
        if (selectedTransRecords.length > 0)
          promise1 = callReportForBufer1("cmetracker_cert", [
            { name: "CMETrackingId", value: selectedTransRecords },
          ]).catch((error) => {
            console.log(error);
            //To hide the wait indicator
            claimeStyles.showSpinner(false);
          });
        else promise1 = null;

        //This is for if user selected at least one self reported record
        const promise2 =
          selectedSelfReportedTransRecords.replace(",", "").length > 0
            ? callReportForBufer2(
                selectedSelfReportedTransRecords,
                selectedSelfReportedTransRecordList,
                promise1 === null ? null : []
              ).catch((error) => {
                console.log(error);

                //To hide the wait indicator
                claimeStyles.showSpinner(false);
              })
            : null;

        const promieArray = [
          promise1 !== null ? promise1 : "",
          promise2 !== null ? promise2 : "",
        ];

        if (promise1 !== null || promise2 !== null) {
          setToShowExportCertButton(true);
          fetchAsyncData(
            promieArray,
            selectedSelfReportedTransRecordList,
            "Certficate"
          );
        } else {
          //here both the selected activities are eigher not valid or they dont have the self reported certificate for self reported case
          setToShowExportCertButton(false);

          //this will give list of record whose certificateUrl is null or empty
          var ListForNullCert = selectedSelfReportedTransRecordList.filter(
            (data) =>
              data.CertificateUrl === null ||
              (data.CertificateUrl || "").length === 0
          );

          //This will give list of record whose CertificateUrl is not null and non pdf file
          var ListForNotNullCert = selectedSelfReportedTransRecordList.filter(
            (data) =>
              data.CertificateUrl !== null &&
              (data.CertificateUrl || "").length > 0 &&
              (data.CertificateUrl || "")
                .toLocaleLowerCase()
                .indexOf(".pdf") === -1
          );

          //This will show pop up with export button as there is one non pdf url which we need to show in the tab
          if (ListForNullCert.length > 0 && ListForNotNullCert.length > 0) {
            //To hide the wait indicator
            claimeStyles.showSpinner(false);

            setToShowExportCertButton(true);
            showMissingCertificateForSelfReportedActivity(
              selectedSelfReportedTransRecordList,
              "",
              true
            );
          } else {
            if (ListForNullCert.length > 0) {
              //To hide the wait indicator
              claimeStyles.showSpinner(false);

              setToShowExportCertButton(false);
              showMissingCertificateForSelfReportedActivity(
                selectedSelfReportedTransRecordList,
                "",
                true
              );
            } else {
              //this will be the case when only other than pdf file - self reported selected for certificate generatins
              selectedSelfReportedTransRecordList
                .filter(
                  (data) =>
                    pattern.test(data.CertificateUrl || "") &&
                    (data.CertificateUrl || "")
                      .toLocaleLowerCase()
                      .indexOf(".pdf") === -1
                )
                .map((data) => {
                  if (data.CertificateUrl && data.CertificateUrl.length > 0)
                    commaSeperatedURLToGenerateZIP.push({
                      fileUrl: data.CertificateUrl,
                      fileName:
                        data.Name +
                        data.CertificateUrl.slice(
                          data.CertificateUrl.lastIndexOf(".")
                        ),
                    });
                });

              if (commaSeperatedURLToGenerateZIP.length > 0) {
                const zip = new JSZip();
                handleConversionToZip(commaSeperatedURLToGenerateZIP, zip);
              }
            }
          }

          // setPageWaitIndicator(false)
        }
      } catch (error) {
        console.log(error);
        //To hide the wait indicator
        claimeStyles.showSpinner(false);
      }
    }
  }

  const handleConversionToZip = async (fileURLs: FileData[], zip: JSZip) => {
    try {
      const zipContent = await zip.generateAsync({ type: "blob" });

      //reset the array to empty
      commaSeperatedURLToGenerateZIP = [];

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(zipContent);
      downloadLink.download = "ACEP_Certificates.zip";
      downloadLink.click();
      //To hide the wait indicator
      claimeStyles.showSpinner(false);
    } catch (error) {
      //To hide the wait indicator
      claimeStyles.showSpinner(false);
      console.error(error);
    }
  };
  //This will show the pop up based on the self reported record who dont have the certifiate url
  const showMissingCertificateForSelfReportedActivity = (
    selectedSelfReportedTransRecordList: IClaimdDetails[],
    certificateURLNames: string,
    bToShowCertPopup: boolean
  ) => {
    var pattern = /^((http|https|ftp):\/\/)/;
    emptyBlobDetails = "";
    setLstSelfReportedNotAvailableCertificates("");

    //First case -> Selected Certificates doesnt have any Certificate URL
    //certificateURLNames = certificateURLNames.length > 0 ? certificateURLNames:''

    var certNames = selectedSelfReportedTransRecordList
      .filter(
        (data) =>
          data.CertificateUrl === null || data.CertificateUrl?.length == 0
      )
      .map(({ Name }) => Name)
      .join(",");

    if (certNames.length > 0 && certificateURLNames.length > 0)
      certificateURLNames = certNames + "," + certificateURLNames;
    else if (certNames.length > 0) certificateURLNames = certNames;

    setLstSelfReportedNotAvailableCertificates(certificateURLNames);

    if (certificateURLNames.length > 0 || bToShowCertPopup) {
      if (
        selectedSelfReportedTransRecordList.filter(
          (data) =>
            pattern.test(data.CertificateUrl || "") &&
            (data.CertificateUrl || "").toLocaleLowerCase().indexOf(".pdf") ===
              -1
        ).length > 0
      )
        setToShowExportCertButton(true);

      inputElement && inputElement.current.click();
    }

    //Second case -> Selected Self Reported Certificate has one blank Certificate URL and one Certificat Not Available
  };

  //This function will execute the API call with promise
  //once all promise is done then it will merge the data and will print the report
  const fetchAsyncData = async (
    promieArray: any,
    selectedSelfReportedTransRecordList: IClaimdDetails[],
    exportType: string
  ) => {
    const res = await Promise.all(promieArray);

    let bufferArray = [];

    const raw1 = window.atob(buffer1Data);
    const buffer1 = new Uint8Array(new ArrayBuffer(raw1.length));
    for (let i = 0; i < raw1.length; i++) {
      buffer1[i] = raw1.charCodeAt(i);
    }

    if (buffer1.length > 0) bufferArray.push(buffer1);

    if (
      bufferArray.length > 0 ||
      buffer2Data.length > 0 ||
      buffer2DataURLToBlobFiles.length > 0
    )
      if (bufferArray && bufferArray.length > 0)
        createAndDownloadBlobFile(
          bufferArray,
          selectedSelfReportedTransRecordList,
          exportType
        );
      else {
        const doc = await PDFDocument.create();
        printPdfToBrowser(doc, selectedSelfReportedTransRecordList, exportType);
      }
  };

  //This function created for to get the list of Self Reported Blob base64 byte stream for the selected records
  const callReportForBufer2 = (
    selectedSelfReportedTransRecords: string,
    selectedSelfReportedTransRecordList: IClaimdDetails[],
    promise1: [] | null
  ) => {
    return new Promise((resolve) => {
      apiUsers.url = "getStreamBlob/";
      var acepid =
        userData.acepMemberStatus === 2 ? contactData.acepid : userData.acepid;
      apiUsers
        .post({
          acepid: acepid.toLocaleLowerCase(),
          filepath: selectedSelfReportedTransRecords,
        })
        .then((resp: any) => {
          if (resp.blobDetails[0] && resp.blobDetails[0].length > 0) {
            var eleArr = resp.blobDetails[0];
            eleArr.map((ele: string) => {
              var stringEle = ele.toLocaleLowerCase();

              if (
                selectedSelfReportedTransRecordList.filter(
                  (data) =>
                    data.CertificateUrl?.toLocaleLowerCase() === stringEle
                )
              )
                emptyBlobDetails +=
                  selectedSelfReportedTransRecordList.filter(
                    (data) =>
                      data.CertificateUrl?.toLocaleLowerCase() === stringEle
                  )[0].Name + ",";
            });

            emptyBlobDetails = emptyBlobDetails.substring(
              0,
              emptyBlobDetails.length - 1
            );
            if (promise1 === null && resp.blobDetails[1].length == 0) {
              setToShowExportCertButton(false);

              //To hide the wait indicator
              claimeStyles.showSpinner(false);

              showMissingCertificateForSelfReportedActivity(
                selectedSelfReportedTransRecordList,
                emptyBlobDetails,
                true
              );
            }
          } else {
            // showMissingCertificateForSelfReportedActivity(selectedSelfReportedTransRecordList,'',false)
          }

          buffer2Data = resp.blobDetails[1];
          buffer2DataFileNames = resp.blobDetails[2];

          //This will get the file names which non pdf certificates base 64 returned
          buffer2DataURLToBlobFiles = resp.blobDetails[3];
          buffer2DataURLToBlobFileNames = resp.blobDetails[4];

          resolve(buffer2Data); //.slice(1, -1) ||""
        });
    });
  };

  //This function will execute the API created by ACEP and will get the Blob stream byte of base64 string
  const callReportForBufer1 = async (
    reportName: string,
    inputParams: { name: string; value: string | undefined }[]
  ) => {
    return new Promise((resolve) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthorizationCredentials,
        },
        body: JSON.stringify({
          name: reportName,
          format: "pdf",
          params: inputParams,
        }),
        responseType: "arraybuffer",
        async: false,
      };

      fetch(ReportURL, requestOptions)
        .then((data) => data.text())
        .then(async (data) => {
          buffer1Data = data.slice(1, -1) || "";
          resolve(buffer1Data);
        });
    });
  };

  //This will return the string based on the current date time
  const getFormattedTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}${hour}${minute}${second}`;
  };

  //This func+tion will print the report based on the data
  const printPdfToBrowser = async (
    doc: PDFDocument,
    selectedSelfReportedTransRecordList: IClaimdDetails[],
    exportType: string
  ) => {
    //To hide the wait indicator
    claimeStyles.showSpinner(false);

    if (exportType === "Transcript") {
      const timestamp = getFormattedTimestamp();
      const fileName = `Transcript_${timestamp}.pdf`;
      var bBody = await doc.save();
      const blob = new Blob([bBody], { type: "application/pdf" });

      // //test
      // const zip = new JSZip();

      // zip.file('document.pdf', blob);
      // const zipContent = await zip.generateAsync({ type: 'blob' });
      // download(zipContent, 'my-files.zip');
      //end test

      // Create a File object with additional properties
      const pdfFile = new File([blob], fileName, {
        lastModified: Date.now(),
      });

      saveAs(pdfFile);

      const link = document.createElement("a");
      // Browsers that support HTML5 download attribute
      if (false && link.download !== undefined) {
        if (/iPad|iPhone|iPod|Macintosh|Mac/.test(navigator.userAgent)) {
          // Check if the browser supports sharing files
          if (navigator.share && navigator.canShare({ files: [pdfFile] })) {
            try {
              // Share the PDF file
              await navigator.share({
                files: [pdfFile],
                title: "Share PDF",
                text: "Acep Transcript Report File!",
              });
              console.log("File shared successfully");
            } catch (error) {
              console.error("Error sharing file:", error);
            }
          } else {
            alert("File sharing not supported on this browser");
          }
        } else {
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.target = "_blank";
          link.download = fileName;

          const clickEvent = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          });

          link.dispatchEvent(clickEvent);
        }
      }
    } else {
      // Create a new instance of JSZip
      const zip = new JSZip();
      // // Convert the PDF document to a base64 string
      // const pdfBytes = await doc.saveAsBase64();

      // Add the PDF file to the zip file
      if (doc && buffer1Data.length > 0) {
        const selectedTransRecords = cmeTrackingDetails
          .filter((data) => {
            return (
              data.IsSelected === true &&
              data.CME_Tracking_Status_Id !== 5 &&
              data.Host !== "ABEM"
            );
          })
          .sort((a, b) => a.Id.localeCompare(b.Id));

        //This is for Acep Generated Zip files
        for (let i = 0; i < doc.getPageCount(); i++) {
          const newPdfDoc = await PDFDocument.create();
          const [copiedPage] = await newPdfDoc.copyPages(doc, [i]);
          newPdfDoc.addPage(copiedPage);

          //To remove the invalid characters from the file name
          const invalidCharsRegex = /[/\\?%*:|"<>]/g;

          // Add the PDF file to the zip file
          var optionFileNameDetails = selectedTransRecords[i]?.Details || "";

          //This is for getting filename for self reported
          var fileName =
            selectedTransRecords[i]?.Name +
              (optionFileNameDetails && optionFileNameDetails.length > 0
                ? "-" + optionFileNameDetails
                : "") || "Acep_Generated_File" + i;

          const pdfBytes = await newPdfDoc.save();

          zip.file(
            fileName
              .trim()
              .replace(invalidCharsRegex, "")
              .substring(0, MaxLength_FileName) +
              "_" +
              i +
              ".pdf",
            pdfBytes,
            { base64: true }
          );
        }
      }

      // Add the PDF file to the zip file
      // This is for Self Reported zip files
      for (let i = 0; i < buffer2Data.length; i++) {
        let sFilename = buffer2DataFileNames[i] || "New_SelfReported_File" + i;
        zip.file(sFilename + "_" + i + ".pdf", buffer2Data[i], {
          base64: true,
        });
      }

      //This will get the length of non pdf files base64 string returned
      //those might be doc or png
      //This is for Self Reported zip files
      //and will assign the names from the respective order
      for (let i = 0; i < buffer2DataURLToBlobFiles.length; i++) {
        const raw1 = window.atob(buffer2DataURLToBlobFiles[i]);
        const byteNumbers = new Uint8Array(new ArrayBuffer(raw1.length));
        for (let i = 0; i < raw1.length; i++) {
          byteNumbers[i] = raw1.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        let sFilename = buffer2DataURLToBlobFileNames[i];
        zip.file(sFilename, new Blob([byteArray]), { base64: true });
      }

      if (
        selectedSelfReportedTransRecordList.filter(
          (data) => data.CertificateUrl === "" || data.CertificateUrl === null
        ).length <= 0 &&
        emptyBlobDetails.length == 0
      ) {
        //  let tab = window.open();

        if (true) {
          // tab.location.href = url;
          var pattern = /^((http|https|ftp):\/\/)/;

          selectedSelfReportedTransRecordList
            .filter(
              (data) =>
                (data.CertificateUrl || "").length > 0 &&
                pattern.test(data.CertificateUrl || "") &&
                (data.CertificateUrl || "")
                  .toLocaleLowerCase()
                  .indexOf(".pdf") === -1
            )
            .map((data) => {
              if (data.CertificateUrl && data.CertificateUrl.length > 0)
                commaSeperatedURLToGenerateZIP.push({
                  fileUrl: data.CertificateUrl,
                  fileName: data.CertificateUrl?.substring(
                    data.CertificateUrl.lastIndexOf("/") + 1
                  ),
                });
            });

          if (commaSeperatedURLToGenerateZIP.length > 0) {
            handleConversionToZip(commaSeperatedURLToGenerateZIP, zip);
          } else {
            // Generate the zip file
            //This is the case if there is only PDF files then generate zip here
            //if not then it will aldready handled in the above
            zip.generateAsync({ type: "blob" }).then((content) => {
              // Save the generated zip file
              download(content, "ACEP_Certificates.zip");
            });
          }
        }
      } else {
        if (emptyBlobDetails.length == 0) {
          //This means, there is not invalid certificate and also there is no self reported certificate
          // Generate the zip file
          //This is the case if there is only PDF files then generate zip here
          //if not then it will aldready handled in the above
          setZipFilesToGenerate(zip);
          zip.generateAsync({ type: "blob" }).then((content) => {
            // Save the generated zip file
            download(content, "ACEP_Certificates.zip");
          });
        } else {
          //here there is self reported certificate but there is invalid certificate
          setZipFilesToGenerate(zip);
        }
        setDisableExportCertButton(false);
        showMissingCertificateForSelfReportedActivity(
          selectedSelfReportedTransRecordList,
          emptyBlobDetails,
          true
        );

        //To hide the wait indicator
        claimeStyles.showSpinner(false);
      }
    }
    //To clear all the variables used for certificate zip generation
    buffer1Data = "";
    buffer2Data = [];
    buffer2DataFileNames = [];
    buffer2DataURLToBlobFiles = [];
    buffer2DataURLToBlobFileNames = [];
  };

  //This function will generate the PDF document from the base64 stream byte data
  async function createAndDownloadBlobFile(
    buffer: Uint8Array[],
    selectedSelfReportedTransRecordList: IClaimdDetails[],
    exportType: string,
    extension = "pdf"
  ) {
    const doc = await PDFDocument.create();

    try {
      //  buffer.sort((a, b) => a.length > b.length ? 1 : -1)
      //To hide the wait indicator
      claimeStyles.showSpinner(false);

      const firstBuffer = buffer[0];
      const cover = await PDFDocument.load(firstBuffer, {
        ignoreEncryption: true,
      }).catch();
      if (cover) {
        const PageCover =
          doc && (await doc.copyPages(cover, cover.getPageIndices()).catch());

        if (PageCover && PageCover.length > 0) {
          for (const page of PageCover) {
            doc.addPage(page);
          }
          let remainingBuffer = buffer.splice(1, buffer.length);
          if (remainingBuffer && remainingBuffer.length <= 0) {
            printPdfToBrowser(
              doc,
              selectedSelfReportedTransRecordList,
              exportType
            );
          } else {
            try {
              remainingBuffer &&
                remainingBuffer.forEach(async (element) => {
                  const content = await PDFDocument.load(element, {
                    ignoreEncryption: true,
                  });

                  const PageContent = await doc
                    .copyPages(content, content.getPageIndices())
                    .catch();
                  for (const page of PageContent) {
                    doc.addPage(page);
                  }

                  remainingBuffer = remainingBuffer.splice(
                    1,
                    remainingBuffer.length
                  );

                  if (remainingBuffer.length <= 0) {
                    printPdfToBrowser(
                      doc,
                      selectedSelfReportedTransRecordList,
                      exportType
                    );
                  }
                });
            } catch (error) {
              printPdfToBrowser(
                doc,
                selectedSelfReportedTransRecordList,
                exportType
              );
            }
          }
        }
      } else if (buffer2Data.length > 0) {
        printPdfToBrowser(doc, selectedSelfReportedTransRecordList, exportType);
      }
    } catch (error) {
      printPdfToBrowser(doc, selectedSelfReportedTransRecordList, exportType);
    } finally {
    }
  }

  //For Certificate Upload
  //On File Selectes
  const fileBrowseHandler = (event: any) => {
    const file = event.target.files[0];
    const fileName =
      file.name.split(".")[0] + "." + file.name.split(".")[1] || "";

    if (file.size <= 5000000) {
      setInvalidFileMessage(false);
      setUploadedCertificateUrl(fileName); //This will update the CertificateUrl in CME Tracking on click of SAVE button
      setSelectedFile(file);
    } else {
      setInvalidFileMessage(true);
      setUploadedCertificateUrl(""); //This will update the CertificateUrl in CME Tracking on click of SAVE button
      setSelectedFile("");
    }
  };

  const postFile = async () => {
    const file: any = selectedfile;

    //on selection, this field alredy available
    const fileName = UploadedCertificateUrl;
    const acepid =
      userData.acepMemberStatus === 2
        ? contactData.acepid.toLocaleLowerCase()
        : userData.acepid.toLocaleLowerCase();

    if (file && fileName && fileName.length > 0) {
      const formdata = new FormData();
      formdata.append("file", file);
      // formdata.append("acepid",acepid)

      let requestOptions = {
        method: "POST",
        type: "formData",
        body: formdata,
      };

      axios.post(API_URL + "/uploadBlob/" + acepid, formdata).then((res) => {
        console.log(res);
      });
    } else {
      console.log("Please select file");
    }
  };

  //On click of Blob name, this function will get call
  //This will get the BLOB SRS URL and will load the blob file in the browser in the new tab
  const getBlobAndDisplay = (ev: any, blobName: any) => {
    ev.preventDefault();

    if (blobName.toString().startsWith("http")) {
      window.open(blobName, "_blank", "noreferrer");
    } else {
      var acepid =
        userData.acepMemberStatus === 2 ? contactData.acepid : userData.acepid;
      apiUsers.url =
        "getUploadedBlobURL/" +
        acepid.toLocaleLowerCase() +
        "?filepath=" +
        blobName;
      apiUsers.getAll().then((resp: any) => {
        resp &&
          resp.BlboURL &&
          window.open(resp.BlboURL, "_blank", "noreferrer");
      });
    }
  };

  //On click of Blob Close, Delete Pop up will show and on submit button of Pop up, this function will get call
  //This will delete the self reported blob from azure container
  const deleteSelfReportedBlob = (blobName: any) => (event: any) => {
    //To show the wait indicator
    claimeStyles.showSpinner(true);

    event.preventDefault();
    setUploadedCertificateUrl("");

    //When user uploaded certificate bur cancel
    if (blobName.length <= 0) {
      event.target[1].click();
    } else {
      event.target[1].click();
      //This will update the CME Tracking record with empty value as CertificateURL
      let method = "PUT";
      let requestOptions = {
        Id: claimCMETrackingId,
        Certificate_url: "",
      };

      totalAPICalls = 1;
      executeAPI(requestOptions, "cmetracking/" + claimCMETrackingId, method);

      //This will delete the blob
      var acepid =
        userData.acepMemberStatus === 2 ? contactData.acepid : userData.acepid;
      const url =
        API_URL +
        "/deleteBlob/" +
        acepid.toLocaleLowerCase() +
        "?filepath=" +
        blobName;
      axios.delete(url).then((res) => {
        console.log("blob deleted successfully");

        //To hide the wait indicator
        // claimeStyles.showSpinner(false)
      });
    }
  };

  const onSubmitCertificates = (event: any) => {
    event.preventDefault();
    event.target[1].click();

    var pattern = /^((http|https|ftp):\/\/)/;

    const selectedSelfReportedTransRecordList = cmeTrackingDetails.filter(
      (data) => {
        return data.IsSelected === true && data.CME_Tracking_Status_Id === 5;
      }
    );
    selectedSelfReportedTransRecordList
      .filter(
        (data) =>
          (data.CertificateUrl || "").length > 0 &&
          pattern.test(data.CertificateUrl || "") &&
          (data.CertificateUrl || "").toLocaleLowerCase().indexOf(".pdf") === -1
      )
      .map((data) => {
        if (data.CertificateUrl && data.CertificateUrl.length > 0)
          commaSeperatedURLToGenerateZIP.push({
            fileUrl: data.CertificateUrl,
            fileName: data.CertificateUrl?.substring(
              data.CertificateUrl.lastIndexOf("/") + 1
            ),
          });
      });

    if (commaSeperatedURLToGenerateZIP.length > 0) {
      //This will call the function to generate the zip file

      handleConversionToZip(commaSeperatedURLToGenerateZIP, zipFilesToGenerate);
    } else {
      // Generate the zip file
      //This is the case if there is only PDF files then generate zip here
      //if not then it will aldready handled in the above
      zipFilesToGenerate?.generateAsync({ type: "blob" }).then((content) => {
        // Save the generated zip file
        download(content, "ACEP_Certificates.zip");
      });

      setZipFilesToGenerate(new JSZip());
    }
  };

  const handleRedirection = (targetPath: string) => {
    // Replace with the path of your target page
    //let targetPath = '/dashboard/MostRecentActivities';

    if (isAdmin && targetPath !== "/") {
      targetPath = targetPath + "?acepid=" + contactData.acepid;
    }

    // Use the history object to navigate to the target page with state
    // history.push({
    //   pathname: targetPath,
    //   state: contactData,
    // });

    history.push(targetPath, { contact: contactData, from: "AdminDashboard" });
  };

  function handleClick(target: any) {
    if (target === "Date") {
      if (order.Date === "asc") {
        setOrder({
          Date: "desc",
          Name: order.Name,
        });

        const sortedAscending = cmeTrackingDetails.sort(
          (a, b) =>
            new Date(a.CompletionDate).valueOf() -
            new Date(b.CompletionDate).valueOf()
        );

        setCMETrackingDetails(sortedAscending);
      } else {
        setOrder({
          Date: "asc",
          Name: order.Name,
        });

        const sortedDescending = cmeTrackingDetails.sort(
          (a, b) =>
            new Date(b.CompletionDate).valueOf() -
            new Date(a.CompletionDate).valueOf()
        );

        setCMETrackingDetails(sortedDescending);
      }
    } else if (target === "Name") {
      if (order.Name === "asc") {
        setOrder({
          Date: order.Date,
          Name: "desc",
        });

        const sortedAscending = cmeTrackingDetails.sort((a, b) =>
          a.Name + a.Details > b.Name + b.Details ? 1 : -1
        );

        setCMETrackingDetails(sortedAscending);
      } else {
        setOrder({
          Date: order.Date,
          Name: "asc",
        });

        const sortedDescending = cmeTrackingDetails.sort((a, b) =>
          a.Name + a.Details < b.Name + b.Details ? 1 : -1
        );

        setCMETrackingDetails(sortedDescending);
      }
    }
  }

  //This function will get call on click of Search button
  //This will filter the CME Tracking details based on the search criteria
  function searchCMETrackingDetails(list: any, value: any) {
    const searchValue = value;
    setSearchString(searchValue);
    const filteredCMETrackingDetails = list.filter(
      (data: { Name: string; Details: string }) => {
        return (
          data.Name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          data.Details?.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    );
    setCMETrackingDetails(filteredCMETrackingDetails);
    setLoadNavHeight(true);
  }

  function getArrow(order: string) {
    console.log("showSelfReportedActivity", showSelfReportedActivity);
    if (order === "asc")
      return (
        <div className="tracking-sort-arrow">
          <ArrowDownwardIcon fontSize="small" htmlColor="#8B8B8B" />{" "}
        </div>
      );
    return (
      <div className="tracking-sort-arrow">
        {" "}
        <ArrowUpwardIcon fontSize="small" htmlColor="#8B8B8B" />{" "}
      </div>
    );
  }

  return (
    <>
      {pageWaitIndicator && <div className="spinner"> </div>}
      <div className={pageWaitIndicator ? "pageWaitIndicator" : ""}>
        <div
          className={
            isAdmin
              ? "UnclaimedDashboard HomePagebackground adminView"
              : "UnclaimedDashboard HomePagebackground"
          }
          style={{
            pointerEvents: claimeStyles.claimeStyles.pointer,
            opacity: claimeStyles.claimeStyles.opacity,
            paddingBottom: "15px",
          }}
        >
          <div className="HomePageWrapper ">
            <div className="flaot_left_with_width">
              <LeftNavMenu
                nonmember={
                  (userData.acepMemberStatus === 0 &&
                    userData.sempaMemberStatus !== 15) ||
                  userData.acepMemberStatus === 3 ||
                  userData.acepMemberStatus === 4 ||
                  userData.acepMemberStatus === 7
                    ? 1
                    : 0
                }
                heightDashboardPage={height}
                onCollapse={(isNavExpanded: any) => {
                  setIsNavExpanded(isNavExpanded);
                }}
              />
            </div>

            <div
              ref={ref}
              className={
                isNavExpanded
                  ? "Right-Div-Dashboard-expanded overlay"
                  : "Right-Div-individual-Dashboard"
              }
            >
              {!showSelfReportedActivity && (
                <>
                  {updateContact ? (
                    <div className="column-full">
                      <div className="ContactInfo">
                        <ContactInfo />
                      </div>
                    </div>
                  ) : null}

                  {props_Input ? (
                    <div className="UncalimedDashboard-Heading unclaimed_header_height">
                      <div className="div-left">Unclaimed CME Hours</div>
                      <div className="div-right-Unclaimed-Dashboard">
                        <div
                          className="Unclaimed-CME_Dashboard-right-button"
                          style={{
                            color: enableTranscript,
                            display: "table-cell",
                          }}
                        >
                          {cmeTrackingDetails.length !== 0 ? (
                            <BrowserRouter>
                              <Link
                                component="a"
                                color={enableTranscript}
                                underline="hover"
                                onClick={() =>
                                  handleRedirection(
                                    "/dashboard/MostRecentActivities"
                                  )
                                }
                                sx={{ cursor: "pointer" }}
                              >
                                Get Transcript{" "}
                              </Link>
                            </BrowserRouter>
                          ) : (
                            <BrowserRouter>
                              <Link color={enableTranscript} underline="hover">
                                Get Transcript{" "}
                              </Link>
                            </BrowserRouter>
                          )}
                          <ArrowForwardIcon style={{ verticalAlign: "top" }} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="UncalimedDashboard-Heading transcript_header_height">
                      <div>Most Recent CME Activities</div>
                      <div className="SearchBar">
                        <TextField
                          size="small"
                          className="search-box"
                          helperText={false}
                          label="Search by keyword"
                          InputProps={{
                            classes: {
                              focused: "search-inside-div",
                              // because i used variant="outlined" i can pass any classes here that the OutlinedInput uses
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon color="disabled" />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            searchCMETrackingDetails(
                              searchListData,
                              e.target.value
                            );
                          }}
                          //fullWidth= {true}
                          sx={{ width: "100%", borderRadius: "50px" }}
                        />
                      </div>
                      <div className="paddingTop_20_px">
                        {cmeTrackingDetails.length !== 0 ? (
                          <>
                            <div className="float-left padding-left-10-px">
                              <Button
                                variant="outlined"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setShowSelfReportedActivity(true);
                                  // history.push({
                                  //     pathname: '/dashboard/SelfReported',
                                  //     state: contactData,
                                  //   });

                                  handleRedirection("/dashboard/SelfReported");

                                  //this will adjust the height of left nav on dashboard
                                  // setLoadNavHeight(true)
                                }}
                                className="self-transcript-button add_self_reported_activity_button_div"
                              >
                                <b className="header-buttons-nowrap add-self-reported-cme">
                                  Add
                                </b>
                                <b className="header-buttons-nowrap">
                                  Self-Reported CME
                                </b>
                              </Button>
                            </div>

                            <div className="float-right padding-left-10-px">
                              <Button
                                variant="outlined"
                                onClick={(ev) => exportCertButtonClickEvent(ev)}
                                style={{
                                  opacity: disableReportButtons ? "0.4" : "1",
                                  pointerEvents: disableReportButtons
                                    ? "none"
                                    : "auto",
                                }}
                                className={
                                  "export-transcript-button export_certificate_button_div model-submit-button-border"
                                }
                              >
                                <b className="color_white header-buttons-nowrap add-self-reported-cme">
                                  Export
                                </b>
                                <b
                                  className={
                                    "color_white header-buttons-nowrap"
                                  }
                                >
                                  Certificate
                                </b>
                              </Button>
                            </div>

                            <div className="float-right padding-left-10-px">
                              <Button
                                variant="outlined"
                                onClick={(ev) => {
                                  exportTranscriptButtonClickEvent(ev);
                                }}
                                style={{
                                  opacity: disableReportButtons ? "0.4" : "1",
                                  pointerEvents: disableReportButtons
                                    ? "none"
                                    : "auto",
                                }}
                                className={
                                  "export-transcript-button export_certificate_button_div model-submit-button-border"
                                }
                              >
                                <b className="color_white header-buttons-nowrap add-self-reported-cme">
                                  Export
                                </b>
                                <b
                                  className={
                                    "color_white header-buttons-nowrap"
                                  }
                                >
                                  Transcript
                                </b>
                              </Button>
                            </div>

                            <div className="float-right padding-left-10-px">
                              {
                                //Find index of specific object using findIndex method.

                                <Container
                                  modalFor="Filters"
                                  triggerText="Filters"
                                  modalHeaderText="Filters"
                                  filterProps={[
                                    {
                                      cmeTopicList: cmeTopicList.filter(
                                        (item: any) => item.Id !== -1
                                      ),
                                    },
                                    {
                                      toShowSelectFiltersDateRange:
                                        toShowSelectFiltersDateRange,
                                    },
                                    { onchangeDateRange: onchangeDateRange },
                                    {
                                      cmeActivityTypeList: cmeActivityTypeList,
                                    },
                                    {
                                      disableReportButtons:
                                        disableReportButtons,
                                    },
                                  ]}
                                  onSubmit={onSubmitFiltersOption}
                                  // closeModal={props.closeModal}
                                />
                              }
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="float-left padding-left-10-px">
                              <Button
                                variant="outlined"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setShowSelfReportedActivity(true);
                                  handleRedirection("/dashboard/SelfReported");

                                  //this will adjust the height of left nav on dashboard
                                  // setLoadNavHeight(true)
                                }}
                                className="self-transcript-button add_self_reported_activity_button_div"
                              >
                                <b className="header-buttons-nowrap add-self-reported-cme">
                                  Add
                                </b>
                                <b className="header-buttons-nowrap">
                                  Self-Reported CME Activity
                                </b>
                              </Button>
                            </div>

                            <div className="float-right padding-left-10-px">
                              <Button
                                variant="outlined"
                                onClick={(ev) => exportCertButtonClickEvent(ev)}
                                style={{
                                  opacity: disableReportButtons ? "0.4" : "1",
                                  pointerEvents: disableReportButtons
                                    ? "none"
                                    : "auto",
                                }}
                                className={
                                  "export-transcript-button export_certificate_button_div model-submit-button-border"
                                }
                              >
                                <b className="color_white header-buttons-nowrap add-self-reported-cme">
                                  Export
                                </b>
                                <b
                                  className={
                                    "color_white header-buttons-nowrap"
                                  }
                                >
                                  Certificate
                                </b>
                              </Button>
                            </div>

                            <div className="float-right padding-left-10-px">
                              <Button
                                variant="outlined"
                                onClick={(ev) => {
                                  exportTranscriptButtonClickEvent(ev);
                                }}
                                style={{
                                  opacity: disableReportButtons ? "0.4" : "1",
                                  pointerEvents: disableReportButtons
                                    ? "none"
                                    : "auto",
                                }}
                                className={
                                  "export-transcript-button export_certificate_button_div model-submit-button-border"
                                }
                              >
                                <b className="color_white header-buttons-nowrap add-self-reported-cme">
                                  Export
                                </b>
                                <b
                                  className={
                                    "color_white header-buttons-nowrap"
                                  }
                                >
                                  Transcript
                                </b>
                              </Button>
                            </div>

                            <div className="float-right padding-left-10-px">
                              <Button
                                variant="outlined"
                                style={{
                                  width: "92px",
                                  opacity: disableReportButtons ? "0.4" : "1",
                                  pointerEvents: disableReportButtons
                                    ? "none"
                                    : "auto",
                                }}
                                className="cancel-transcript-button"
                              >
                                <b> Filters</b>
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="mergeActivitiesNotice" style={{display:'none'}}>
                    If your ACEP account has been merged, you may notice duplicate CME activities. Please be sure to review for accuracy.
                  </div>
                  
                </>
              )}
              {!showSelfReportedActivity &&
                toShowSelectFiltersDiv &&
                selectedOption &&
                selectedOption.length > 0 && (
                  <div
                    className="filters_header_height"
                    style={{ border: "none" }}
                  >
                    {selectedOption.map((filterOptions, index) => {
                      return (
                        <div
                          key={index}
                          className="filters-button"
                          style={{
                            float: "left",
                            width: "auto",
                            marginRight: "15px",
                          }}
                        >
                          <Button
                            className="model-submit-button"
                            style={{
                              pointerEvents: "none",
                              height: "32px",
                              width: "auto",
                              textTransform: "none",
                              transition: "none !important",
                              color: "black",
                            }}
                          >
                            <span className="filtersText">
                              {" "}
                              {filterOptions.label}{" "}
                            </span>
                          </Button>
                          <span
                            onClick={(ev) =>
                              handleFiltersRemove(ev, filterOptions.label)
                            }
                          >
                            <CloseIcon
                              style={{ color: "black", display: "grid" }}
                            />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}

              {showSelfReportedActivity ? ( //true  : records will display correctly if they have any
                <div className="EmptyList">
                  {!claimeStyles.spinner &&
                    cmeTrackingDetails.length === 0 &&
                    cmeTrackingDetails && (
                      <>
                        <div className="empty-text">
                          You do not have
                          {claimeStyles.propsSource === "Unclaimed"
                            ? " any unclaimed CME hours."
                            : " any CME activities."}
                        </div>
                        <div>
                          <img
                            src={
                              claimeStyles.propsSource === "Unclaimed"
                                ? EmptyUnclaimedImg
                                : EmptyActivityImg
                            }
                          ></img>
                        </div>
                        <div>
                          <Button
                            style={{ textTransform: "none" }}
                            variant="contained"
                            className="explore-more-opp-div"
                            onClick={redirectEducation}
                          >
                            <b> Explore Opportunities</b>
                          </Button>
                        </div>
                      </>
                    )}
                </div>
              ) : (
                <></>
              )}
              {/*  YT : condition added  : For Claime CME and Transcript UI side   */}
              {cmeTrackingDetails.length === 0 && !showSelfReportedActivity ? (
                <div className="EmptyList">
                  {!claimeStyles.spinner &&
                    cmeTrackingDetails.length === 0 &&
                    cmeTrackingDetails && (
                      <>
                        <div className="empty-text">
                          You do not have
                          {claimeStyles.propsSource === "Unclaimed"
                            ? " any unclaimed CME hours."
                            : " any CME activities."}
                        </div>
                        <div>
                          <img
                            src={
                              claimeStyles.propsSource === "Unclaimed"
                                ? EmptyUnclaimedImg
                                : EmptyActivityImg
                            }
                          ></img>
                        </div>
                        <div>
                          <Button
                            style={{ textTransform: "none" }}
                            variant="contained"
                            className="explore-more-opp-div"
                            onClick={redirectEducation}
                          >
                            <b> Explore Opportunities</b>
                          </Button>
                        </div>
                      </>
                    )}
                </div>
              ) : (
                <></>
              )}

              {!showSelfReportedActivity && showData && (
                <>
                  {cmeTrackingDetails && cmeTrackingDetails.length > 0 ? (
                    <div
                      className={
                        props_Input
                          ? "Unclaimed-dashboard-secondColumn box-style unclaimed_top_margin"
                          : "Unclaimed-dashboard-secondColumn box-style transcript_top_margin"
                      }
                    >
                      <div className="Unclaimed-Dashboard-header-line">
                        {!props_Input && (
                          <div className="paddingTop_25_px flaot_left transcript_header_checkboxColumn_div">
                            <input
                              onChange={(ev) => {
                                setHeaderCheckUnchekBox(ev.target.checked);

                                //this will execute when user checked/unchecked header checkbox only so other child checkbox should also checked/unchecied
                                if (handleHeaderCheckEvent) {
                                  const newCME: any = cmeTrackingDetails.map(
                                    (resData) => {
                                      return {
                                        ...resData,
                                        IsSelected: ev.target.checked,
                                      };
                                    }
                                  );

                                  setCMETrackingDetails(newCME);

                                  setDisableReportButtons(!ev.target.checked);
                                }
                              }}
                              type="checkbox"
                              checked={headerCheckUncheckBox}
                              className="transcript_header_checkxox_control"
                            ></input>
                          </div>
                        )}
                        <div
                          className="box-inner-header unclaim-dateColumn"
                          onClick={() => handleClick("Date")}
                        >
                          Date {getArrow(order.Date)}
                        </div>

                        <div
                          className={
                            props_Input
                              ? "box-inner-header unclaim-eventColumn"
                              : "box-inner-header transcript-eventColumn"
                          }
                          onClick={() => handleClick("Name")}
                        >
                          Activity Name
                          {getArrow(order.Name)}
                        </div>
                        <div
                          className={
                            isAdmin
                              ? "box-inner-header delete-activity"
                              : "box-inner-header unclaim-maxHoursColumn"
                          }
                        >
                          {props_Input
                            ? isAdmin
                              ? "Delete"
                              : "Max Hours To Claim"
                            : isAdmin
                            ? "Delete"
                            : "CME Hours Earned"}
                        </div>
                        {isAdmin && (
                          <div
                            className={
                              props_Input
                                ? "box-inner-header unclaim-maxHoursColumn-admin"
                                : "box-inner-header  claim-earnedHoursColumn-admin"
                            }
                          >
                            <span className={props_Input + ""}>
                              {props_Input ? (
                                <>
                                  Max Hours{" "}
                                  <span className="max-hours-title-admin">
                                    To Claim
                                  </span>{" "}
                                </>
                              ) : (
                                <>
                                  CME Hours{" "}
                                  <span className="earned-hours-title-admin">
                                    Earned
                                  </span>{" "}
                                </>
                              )}{" "}
                            </span>
                          </div>
                        )}
                      </div>
                      <List sx={{ bgcolor: "background.paper" }}>
                        {cmeTrackingDetails.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                item.IsExpand
                                  ? "dvMostRecentList nohover list_main_div"
                                  : "dvMostRecentList list_main_div"
                              }
                              onClick={(event) =>
                                dvShowHideAccordianDetails(event, item)
                              }
                            >
                              <ListItem
                                key={index}
                                className="lstUnclaimed"
                                alignItems="flex-start"
                                sx={{ width: "100%" }}
                              >
                                <ListItemText
                                  key={index}
                                  className="padding_0_percent"
                                  primary={
                                    <React.Fragment>
                                      {!props_Input && (
                                        <Typography
                                          component="div"
                                          className="transcript_input_checkbox_div_row"
                                        >
                                          <input
                                            onChange={(ev) => {
                                              let bFlagForHeaderCheckBox = true;

                                              setHeaderCheckUnchekBox(false);
                                              const newCME: any =
                                                cmeTrackingDetails.map(
                                                  (resData) => {
                                                    if (
                                                      resData.Id.toLocaleLowerCase() ==
                                                      ev.target.id.toLocaleLowerCase()
                                                    ) {
                                                      return {
                                                        ...resData,
                                                        IsSelected:
                                                          ev.target.checked,
                                                      };
                                                    } else {
                                                      if (
                                                        typeof resData.IsSelected ==
                                                          "undefined" ||
                                                        resData.IsSelected ===
                                                          false
                                                      ) {
                                                        bFlagForHeaderCheckBox =
                                                          false;
                                                      }
                                                      return {
                                                        ...resData,
                                                        IsSelected:
                                                          resData.IsSelected,
                                                      };
                                                    }
                                                  }
                                                );
                                              setCMETrackingDetails(newCME);

                                              if (
                                                bFlagForHeaderCheckBox &&
                                                ev.target.checked
                                              ) {
                                                setHeaderCheckUnchekBox(true);
                                              }

                                              //This will return count of selected check boxes
                                              //based on we can enable/disable the checkbox buttons
                                              const count = newCME.filter(
                                                (obj: { IsSelected: any }) => {
                                                  if (obj.IsSelected) {
                                                    return true;
                                                  }
                                                  return false;
                                                }
                                              ).length;

                                              setDisableReportButtons(
                                                count > 0 ? false : true
                                              );
                                            }}
                                            checked={
                                              item.IsSelected
                                                ? item.IsSelected
                                                : false
                                            }
                                            className="transcript_input_checkbox_control"
                                            type="checkbox"
                                            id={item.Id}
                                          ></input>
                                        </Typography>
                                      )}

                                      <Typography
                                        component="div"
                                        className="unclaim-dateRow"
                                      >
                                        {item.FormatCompletionDate}
                                      </Typography>

                                      <Typography
                                        component="div"
                                        className="unclaim-eventRow"
                                      >
                                        {item.Name}{" "}
                                        {item.Details &&
                                        item.CME_Tracking_Status_Id !== 5
                                          ? "-" + item.Details
                                          : ""}
                                        {item.CME_Tracking_Status_Id == 5 && (
                                          <span className="text-color-red">
                                            *
                                          </span>
                                        )}
                                      </Typography>
                                      <Typography
                                        component="div"
                                        className={
                                          props_Input
                                            ? isAdmin
                                              ? "unclaim-maxHoursRow-admin"
                                              : "unclaim-maxHoursRow"
                                            : isAdmin
                                            ? "transcript-maxHoursRow-admin"
                                            : "transcript-maxHoursRow"
                                        }
                                      >
                                        {item.RemainingHoursToClaim}
                                      </Typography>

                                      {isAdmin && (
                                        <Typography
                                          component="div"
                                          className="unclaim-claimRow-delete"
                                        >
                                          <span className="span-margin-left-delete">
                                            {
                                              <DeleteCMEActivity
                                                onSubmit={onDeleteSelfActivityClick(
                                                  item.Id,
                                                  item.CertificateUrl
                                                )}
                                              />
                                            }
                                          </span>
                                        </Typography>
                                      )}

                                      <Typography
                                        component="div"
                                        className={
                                          isAdmin
                                            ? "unclaim-claimRow-admin"
                                            : "unclaim-claimRow"
                                        }
                                      >
                                        <span className="span-margin-left">
                                          {item.IsExpand ? (
                                            <ExpandLess />
                                          ) : (
                                            <ExpandMore />
                                          )}
                                        </span>
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>

                              {current === item.Id ? (
                                <div
                                  className="Unclaimed-Dashboard-inner-details"
                                  style={{
                                    height: innerHeightAddTopicDiv + "px",
                                    marginLeft: true ? "" : "56px",
                                  }}
                                >
                                  <div
                                    style={{ padding: "10px 0px 0px 20px" }}
                                    className="claim-cme-heading"
                                  >
                                    {props_Input
                                      ? "Claim CME Hours"
                                      : "Edit CME Activity"}
                                  </div>

                                  {item.CME_Tracking_Status_Id == 5 && ( //YT  condition for selfreported activity
                                    <>
                                      <div className="SelfReported-box ">
                                        <div
                                          style={{
                                            paddingRight: "20px",
                                            width: "100%",
                                          }}
                                          className="most-recent-activity-type-box-left-div"
                                        >
                                          {item.CME_Tracking_Status_Id == 5 && (
                                            <span className="cme-hours-label">
                                              Activity Name
                                            </span>
                                          )}

                                          <input
                                            required
                                            value={currentActivity}
                                            onChange={(event) =>
                                              handleActivityNameChange(event)
                                            }
                                            type="text"
                                            className={
                                              currentActivity === null ||
                                              currentActivity.trim() === ""
                                                ? "cme-activity-input-box float_left error-cme-topic-hours-box "
                                                : "cme-activity-input-box float_left "
                                            }
                                          />

                                          {currentActivity === null ||
                                            (currentActivity.trim() === "" && (
                                              <div className="error-message-cme-hours">
                                                <span className="padding_top_5_px">
                                                  <ErrorIcon />{" "}
                                                  {"This field is required"}
                                                </span>
                                              </div>
                                            ))}
                                        </div>
                                      </div>

                                      <div className="SelfReported-box ">
                                        <div className="most-recent-activity-type-box-left-div">
                                          <span className="cme-hours-label">
                                            Activity Type
                                          </span>

                                          <select
                                            className={
                                              currentActivitytype?.length === 0
                                                ? "input-box error-cme-topic-hours-box   "
                                                : "input-box"
                                            }
                                            value={currentActivitytype}
                                            onChange={(event) =>
                                              handleActivityType(event)
                                            }
                                          >
                                            {cmeActivityTypeList &&
                                              cmeActivityTypeList.map(
                                                (data: any) => (
                                                  <>
                                                    {/* <option
                                                    key={shortid.generate()}
                                                    value=""
                                                    defaultValue=""
                                                    disabled
                                                    hidden
                                                  ></option> */}
                                                    <option
                                                      key={shortid.generate()}
                                                      value={data.Id}
                                                    >
                                                      {data.Name}
                                                    </option>
                                                  </>
                                                )
                                              )}
                                          </select>
                                          {currentActivitytype?.length ===
                                            0 && (
                                            <div className="error-message-cme-hours">
                                              <span className="padding_top_5_px">
                                                <ErrorIcon />{" "}
                                                {"This field is required"}
                                              </span>
                                            </div>
                                          )}
                                        </div>

                                        <div className="mostRecent-box-left-div">
                                          <span className="cme-hours-label">
                                            Completion Date
                                          </span>
                                          <MyDatePicker
                                            onChange={handleNewDateChange}
                                            defaultValue={new Date(currentDate)}
                                            disableFutureDates
                                            required
                                            className={
                                              dayjs(currentDate).utc() ===
                                                null ||
                                              dayjs(currentDate).utc() >
                                                dayjs(new Date()).utc()
                                                ? "most-rec-activity self-reported-date  SelfReported-date-control error-cme-topic-hours-box-date width-100-perc"
                                                : " most-rec-activity self-reported-date SelfReported-date-control  width-100-perc"
                                            }
                                          />
                                          {/* <TextField
                                         variant="outlined"
                                          value={currentDate}
                                          onChange={(event:React.ChangeEvent<HTMLInputElement>) =>
                                            handleNewDateChange(event)
                                          }
                                          type="date"
                                           InputLabelProps={{
                                                shrink: true,
                                            }}
                                          InputProps={{
                                            inputProps: {
                                              max:new Date().toISOString().split('T')[0],
                                            },
                                          }}
                                          style={{width:'100%'}}
                                          className={
                                            currentDate.trim().length == 0 ||
                                            currentDate >
                                              new Date()
                                                .toISOString()
                                                .split("T")[0]
                                              ? "self-reported-date error-cme-topic-hours-box-date "
                                              : "  self-reported-date "
                                          }
                                        ></TextField> */}

                                          {dayjs(currentDate).utc() >
                                            dayjs(new Date()).utc() && (
                                            <div className="error-message-cme-hours">
                                              <span className="padding_top_5_px">
                                                <ErrorIcon />{" "}
                                                {"Date must be in the past"}
                                              </span>
                                            </div>
                                          )}
                                          {currentDate.trim().length == 0 && (
                                            <div className="error-message-cme-hours">
                                              <span className="padding_top_5_px">
                                                <ErrorIcon />{" "}
                                                {"This field is required"}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {
                                    <div className="unclaim-columnFirst">
                                      <div className="claim-cme-box">
                                        <div className="cme-hours-box">
                                          <div className="cme-hours-label">
                                            CME Hours
                                          </div>

                                          <input
                                            value={claimCMEHours.toString()}
                                            onChange={(event) =>
                                              onChangeCMEHoursText(event)
                                            }
                                            type="text"
                                            onBlur={(event) =>
                                              roundUpClaimedHours(event)
                                            }
                                            className={
                                              item.CME_Tracking_Status_Id === 6
                                                ? "cme-hours-input-box float_left input-disable"
                                                : showLeftHandTopicHoursValid &&
                                                  (parseFloat(claimCMEHours) >
                                                    remainingHoursToClaim ||
                                                    claimCMEHours === "0" ||
                                                    claimCMEHours === "")
                                                ? "cme-hours-input-box error-cme-topic-hours-box float_left"
                                                : "cme-hours-input-box float_left"
                                            }
                                          />
                                          <span className="cme-out-of-max-hours-span padding_left_5_px">
                                            out of{" "}
                                            <label className="unclaim-bold-max-hours">
                                              {" "}
                                              {remainingHoursToClaim}{" "}
                                            </label>
                                          </span>

                                          {showLeftHandTopicHoursValid &&
                                          parseFloat(claimCMEHours) >
                                            remainingHoursToClaim ? (
                                            <div className="error-message-cme-hours display_table_row">
                                              <span>
                                                <ErrorIcon /> Cannot exceed Max
                                                CME Hours
                                              </span>
                                            </div>
                                          ) : showLeftHandTopicHoursValid &&
                                            (claimCMEHours === "0" ||
                                              claimCMEHours === "") ? (
                                            <div className="error-message-cme-hours display_table_row">
                                              <span>
                                                <ErrorIcon />
                                                This field is required
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      {item.CME_Tracking_Status_Id === 5 && (
                                        <>
                                          <div
                                            className="paddingTop_50_px"
                                            style={{
                                              minHeight: "45px",
                                              float: "left",
                                            }}
                                          >
                                            {(item.CertificateUrl &&
                                              item.CertificateUrl.lastIndexOf(
                                                "."
                                              ) &&
                                              item.CertificateUrl.lastIndexOf(
                                                "."
                                              ).toString().length > 0) ||
                                            UploadedCertificateUrl.length >
                                              0 ? (
                                              <div className="selfReported-box-left-fileupload-div">
                                                <DeleteSelfReportedBlob
                                                  onSubmit={deleteSelfReportedBlob(
                                                    item.CertificateUrl
                                                  )}
                                                />

                                                <span
                                                  onClick={(ev) =>
                                                    getBlobAndDisplay(
                                                      ev,
                                                      item.CertificateUrl
                                                    )
                                                  }
                                                  className="claim-cme-certificate-url"
                                                >
                                                  {UploadedCertificateUrl.length >
                                                  0
                                                    ? UploadedCertificateUrl
                                                    : item.CertificateUrl?.substring(
                                                        item.CertificateUrl.lastIndexOf(
                                                          "/"
                                                        ) + 1
                                                      )}
                                                </span>
                                              </div>
                                            ) : (
                                              <form action="#">
                                                <>
                                                  <label htmlFor="file">
                                                    <label
                                                      htmlFor="file"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="transcript-cme-heading-right-text"
                                                    >
                                                      <FileUploadOutlined
                                                        style={{
                                                          verticalAlign:
                                                            "bottom",
                                                        }}
                                                      />{" "}
                                                      Upload Certification{" "}
                                                    </label>
                                                    <span className="selfReported-box-left-fileupload-filename">
                                                      {" "}
                                                      {
                                                        UploadedCertificateUrl
                                                      }{" "}
                                                    </span>
                                                  </label>

                                                  <input
                                                    id="file"
                                                    onChange={(event) =>
                                                      fileBrowseHandler(event)
                                                    }
                                                    type="file"
                                                    className="display-none"
                                                  />

                                                  <div
                                                    className="padding_left_25_px"
                                                    style={{
                                                      color: invalidFileMessage
                                                        ? "red"
                                                        : "",
                                                    }}
                                                  >
                                                    (Max file size 5MB)
                                                  </div>
                                                </>
                                              </form>
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              paddingTop:
                                                innerHeightAddTopicDiv -
                                                (item.CME_Tracking_Status_Id ===
                                                5
                                                  ? 490
                                                  : 280) +
                                                "px",
                                              marginLeft: true ? "" : "56px",
                                              float: "left",
                                            }}
                                          >
                                            <DeleteSelfReportedActivity
                                              onSubmit={onDeleteSelfActivityClick(
                                                item.Id,
                                                item.CertificateUrl
                                              )}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  }
                                  <div
                                    style={{
                                      paddingTop:
                                        innerHeightAddTopicDiv -
                                        (item.CME_Tracking_Status_Id === 5
                                          ? 450
                                          : 240) +
                                        "px",
                                      marginLeft: true ? "" : "56px",
                                    }}
                                    className="unclaim-columnfirst-border-div"
                                  ></div>
                                  <div
                                    className={
                                      true
                                        ? "unclaim-columnSecond width_67_percent"
                                        : "unclaim-columnSecond width_97_percent"
                                    }
                                  >
                                    <div className="claim-cme-box">
                                      {/*will loop from here to showw multiple rows on click of Add Topic link */}
                                      {topicInfo
                                        .sort(function (x, y) {
                                          return x.IsValidAMAPRATopic == true
                                            ? -1
                                            : y.IsValidAMAPRATopic
                                            ? 1
                                            : 0;
                                        })
                                        .map(
                                          (
                                            topicInfoDetails: iTopicInfo,
                                            iIndex: number
                                          ) =>
                                            topicInfoDetails.TopicId !== 1 && (
                                              <div
                                                key={topicInfoDetails.NewUID}
                                                className="cme-add-topics-box"
                                              >
                                                <div
                                                  className={
                                                    true
                                                      ? "cme-dv-topic width_39_percent"
                                                      : "cme-dv-topic width_45_percent"
                                                  }
                                                >
                                                  <div className="cme-hours-label">
                                                    Topic
                                                  </div>
                                                  <div className="width_90_percent">
                                                    <select
                                                      required
                                                      onChange={(event) =>
                                                        onChangeAddedTopicDetails(
                                                          event,
                                                          iIndex,
                                                          "TopicId"
                                                        )
                                                      }
                                                      value={topicInfoDetails.TopicId.toString()}
                                                      className={
                                                        topicInfoDetails.IsValidAMAPRATopic &&
                                                        item.CME_Tracking_Status_Id !==
                                                          5
                                                          ? "input-disable cme-topic-input-box"
                                                          : !topicInfoDetails.IsValidTopicName
                                                          ? "cme-topic-input-box error-cme-topic-hours-box"
                                                          : "cme-topic-input-box "
                                                      }
                                                    >
                                                      {cmeTopicList
                                                        .filter(
                                                          (ele) => ele.Id !== 1
                                                        )
                                                        .map((data: any) => (
                                                          <option
                                                            value={data.Id}
                                                          >
                                                            {data.Name}
                                                          </option>
                                                        ))}
                                                    </select>
                                                  </div>
                                                  {!topicInfoDetails.IsValidTopicName && (
                                                    <div className="error-message-cme-hours">
                                                      <span className="padding_top_5_px">
                                                        <ErrorIcon />
                                                        {topicInfoDetails.TopicId !==
                                                        -1
                                                          ? "Please choose a different topic"
                                                          : "This field is required"}
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                                <div
                                                  className={
                                                    true
                                                      ? "cme-dv-hours width_30_percent"
                                                      : "cme-dv-hours width_39_percent"
                                                  }
                                                >
                                                  <div className="cme-hours-label">
                                                    Hours
                                                  </div>
                                                  <div className="width_99_percent">
                                                    <input
                                                      required
                                                      value={
                                                        topicInfoDetails.TopicHours
                                                      }
                                                      onChange={(event) =>
                                                        onChangeAddedTopicDetails(
                                                          event,
                                                          iIndex,
                                                          "TopicHours"
                                                        )
                                                      }
                                                      onBlur={(event) =>
                                                        roundUpTopicHours(
                                                          event,
                                                          iIndex,
                                                          "TopicHours"
                                                        )
                                                      }
                                                      className={
                                                        topicInfoDetails.IsValidAMAPRATopic &&
                                                        item.CME_Tracking_Status_Id !==
                                                          5
                                                          ? "input-disable cme-topic-hours-box"
                                                          : topicInfoDetails.IsValidTopicHours
                                                          ? "cme-topic-hours-box"
                                                          : "cme-topic-hours-box error-cme-topic-hours-box"
                                                      }
                                                    />
                                                    {!topicInfoDetails.IsValidTopicHours && (
                                                      <div className="error-message-cme-hours">
                                                        <span className="padding_top_5_px">
                                                          <ErrorIcon />
                                                          {/* YT: Condition added for CME Hours and Hours */}
                                                          {topicInfoDetails.TopicHours ===
                                                            "" ||
                                                          parseFloat(
                                                            topicInfoDetails.TopicHours
                                                          ) === 0
                                                            ? "This field is required"
                                                            : parseFloat(
                                                                topicInfoDetails.TopicHours
                                                              ) >
                                                              parseFloat(
                                                                claimCMEHours
                                                              )
                                                            ? "Cannot exceed Claim Hours"
                                                            : ""}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                                <span
                                                  className={
                                                    true
                                                      ? "cme-right-layout width_16_percent"
                                                      : "cme-right-layout width_11_percent"
                                                  }
                                                >
                                                  {true &&
                                                    parseFloat(claimCMEHours) >
                                                      0 && (
                                                      <span className="cme-out-of-max-hours-span">
                                                        out of{" "}
                                                        <label className="unclaim-bold-max-hours">
                                                          {" "}
                                                          {claimCMEHours}{" "}
                                                        </label>
                                                      </span>
                                                    )}
                                                  {false && (
                                                    <span className="padding_left_50_px cme-out-of-max-hours-span ">
                                                      out of{" "}
                                                      <label className="unclaim-bold-max-hours">
                                                        {" "}
                                                        {claimCMEHours}{" "}
                                                      </label>
                                                    </span>
                                                  )}
                                                </span>
                                                {!(
                                                  topicInfoDetails.IsValidAMAPRATopic &&
                                                  item.CME_Tracking_Status_Id !==
                                                    5
                                                ) && (
                                                  <div
                                                    className={
                                                      true
                                                        ? "cme-right-layout-delete-icon display_flex"
                                                        : "cme-right-layout-delete-icon display_initial"
                                                    }
                                                  >
                                                    <div className="CMETopicDelelteButton">
                                                      <DeleteTopic
                                                        onBlur={
                                                          setAddTrnascriptButtonState
                                                        }
                                                        onSubmit={onSubmit(
                                                          topicInfoDetails.NewUID,
                                                          iIndex,
                                                          topicInfoDetails.RecordID,
                                                          item.CME_Tracking_Status_Id
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            )
                                        )}

                                      <div className="claim-cme-heading claim-cme-heading-right-text">
                                        <div className="transcript_add_topic_div">
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={(event) =>
                                              onAddTopicInfo(event, item)
                                            }
                                          >
                                            {" "}
                                            Add Topic
                                          </span>
                                        </div>
                                      </div>

                                      {
                                        // topicInfo && topicInfo.length > 0 &&
                                        <div
                                          className="divAddTopicButtons"
                                          style={{
                                            paddingTop:
                                              topicInfo.filter(
                                                (data) => data.TopicId !== 1
                                              ).length === 0
                                                ? "150px"
                                                : "0",
                                          }}
                                        >
                                          <Button
                                            variant="outlined"
                                            onClick={() => {
                                              setCurrent("");
                                              item.IsExpand = false;
                                            }}
                                            className="cancel-transcript-button cancel-transcript-button-width color_white text_transform_none"
                                          >
                                            <b color="#1976D2"> Cancel</b>
                                          </Button>

                                          <Button
                                            onClick={(event) =>
                                              onAddTranscriptDetails(event)
                                            }
                                            variant="contained"
                                            className={
                                              props_Input
                                                ? "add-transcript-button text_transform_none model-submit-button-border"
                                                : "add-transcript-button text_transform_none save-transcript-button-width model-submit-button-border"
                                            }
                                          >
                                            <b className="color_white">
                                              {props_Input
                                                ? "Add To Transcript"
                                                : "Save"}
                                            </b>
                                          </Button>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </List>
                      {!props_Input && (
                        <div className="note">
                          <span className="text-color-red">*</span>These
                          activities are self-reported and not verified by ACEP.
                          Certificates are available for ACEP-verified
                          activities only.
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </>
              )}
            </div>
            <div style={{ display: "none" }}>
              <Container
                modalFor={"Certificates"}
                triggerText={"Certificates"}
                modalHeaderText={
                  selectedABEMTransRecordList.length > 0
                    ? "Certificates not found for following activities"
                    : "Certificates not found for following self-reported activities"
                }
                customBtnRef={inputElement}
                onSubmit={onSubmitCertificates}
                inputProperties={{
                  modalData: lstSelfReportedNotAvailableCertificates,
                  bDisableExportCertButton: bDisableExportCertButton,
                  showExportButton: bToShowExportCertButton,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Stack className="stackMsg" spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openAlertMsg}
          autoHideDuration={5000}
          onClose={() => setOpenAlertMsg(false)}
        >
          <Alert severity="info" sx={{ width: "100%" }}>
            {msg}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default function ClaimUnclaimCME(source: any) {
  let enableObject = { opacity: 0.4, pointer: "none" };

  const [claimeStyles, setclaimeStyles] = useState(enableObject);
  const [spinner, setSpinner] = useState(true);
  const [propsSource, setPropsValue] = useState({ previous: "", current: "" });

  let propSource = source.source;

  React.useEffect(() => {
    if (source.source !== propsSource.previous) {
      setPropsValue({ previous: source.source, current: source.source });
    }
    if (!spinner) {
      enableObject = {
        opacity: 1,
        pointer: "auto",
      };
    }

    setclaimeStyles(enableObject);
  }, [spinner]);

  return (
    <>
      {spinner ? <div className="spinner"></div> : null}
      <ClaimUnclaimCMEDashboard
        claimeStyles={claimeStyles}
        spinner={spinner}
        showSpinner={(toShow: boolean) => {
          setSpinner(toShow);
        }}
        propsSource={propSource}
      />
    </>
  );
}
