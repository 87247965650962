import { Box } from "@mui/material";
import FilterLiveSearch from "../common/FilterLiveSearch";
import { ChangeEvent, useEffect, useState } from "react";

const UserListFilter: React.FC<UserListFilterProps> = ({
  setInputJSON,
  inputJSON,
  setPage,
  setGrid
}) => {
  //----States for Search and Filter inputs
  const [searchField, setSearchField] = useState("");
  
  //----UseEffect to get updated values of States
  useEffect(() => {
    setPage(0);
    createJSONInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  /**
   * This is an event used by Search filter
   */
  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      if(event.target.value.length >= 4){
        setSearchField(event.target.value);
        setGrid('block');
      }
    } else {
      setSearchField("");
    }
  };

  /**
   * Create JSON object based on inputs and call API.
   */
  const createJSONInput = () => { 
      // Call API and pass input in JSON format
      if(searchField.length === 7 )
        setInputJSON({
        ...inputJSON,
        Acep_Id: searchField,
        CurrentPage: 0,
      });
      else {
        if(searchField.length > 7){
        setInputJSON({
          ...inputJSON,
          Email: searchField,
          CurrentPage: 0,
        });
      }
      else{
        setInputJSON({
          ...inputJSON,
          Name: searchField.toLowerCase(),
          CurrentPage: 0,
        });
      }
    }
      
    
  };


  return (
    <Box width="28.8em" marginRight="1em" paddingLeft="2em">
      <FilterLiveSearch onSearchChange={onSearchChange} />
            
    </Box>
  );
};

export default UserListFilter;
